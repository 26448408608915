import React, { useEffect, useState } from 'react';
import { useCollectionData, useDocument } from 'react-firebase-hooks/firestore';
import { FieldValue, firestore, storage } from '../../../components/FirebaseProvider';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import Paper from '@material-ui/core/Paper';
import PageLoading from '../../../components/loading/pageLoading';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import Container from '@material-ui/core/Container';
import { useData } from '../../../components/DataProvider';
import { useSnackbar } from 'notistack';


function Banner() {

    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const { treeDataKategori, brandList, peoductList } = useData();

    const { company_id } = useData();

    const bannerDoc = firestore.doc(`banner/banner-satu`);

    const [bannerSnapshot, bannerLoading] = useDocument(bannerDoc);

    const [form, setForm] = useState({
        target_type: '',
        url: '',
        posisi: '',
        target_nama: ''
    });

    const [error, setError] = useState({
        target_type: '',
        url: '',
        posisi: '',
        target_nama: ''
    });
    const [isSubmitting, setSubmitting] = useState(false);



    useEffect(() => {
        if (bannerSnapshot) {

            const data = bannerSnapshot.data()
            setForm({
                ...data,
            });
        }

    }, [bannerSnapshot]);

    const handleChange = e => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }
    const validate = () => {

        const newError = {};

        if (!form.target_type) {
            newError.target_type = 'Target Klik banner wajib diisi';
        }
        if (!form.url) {
            newError.url = 'url banner wajib diisi';
        }
        if (!form.posisi) {
            newError.posisi = 'posisi banner wajib diisi';
        }

        return newError;

    }
    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {

                const updBanner = {
                    ...form,
                    target_id: form.target_id.id || form.target_id,
                    target_nama: form.target_id.nama || form.target_nama || '',
                    updated_at: FieldValue.serverTimestamp(),
                };

                await bannerDoc.set(updBanner, { merge: true });

                enqueueSnackbar("Banner Berhasil Disimpan", { variant: "success" });

            } catch (e) {
                const newError = {};

                newError.title = e.message;

                setError(newError);
            }

            setSubmitting(false)
        }
    }
    const handleUploadFile = async (e) => {
        const file = e.target.files[0];

        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {

            setError(error => ({
                ...error,
                url: `Tipe file tidak didukung: ${file.type}`
            }))
        }
        else if (file.size >= 512000) {
            setError(error => ({
                ...error,
                url: `Ukuran file terlalu besar > 500KB`
            }))
        } else {

            const reader = new FileReader();

            reader.onabort = () => {

                setError(error => ({
                    ...error,
                    url: `Proses pembacaan file dibatalkan`
                }))
            }

            reader.onerror = () => {

                setError(error => ({
                    ...error,
                    url: 'File tidak bisa dibaca'
                }))
            }

            reader.onload = async () => {
                setError(error => ({
                    ...error,
                    url: ''
                }))
                setSubmitting(true);
                try {
                    const bannerStorageRef = storage.ref(`banner`);

                    const urlExt = file.name.substring(file.name.lastIndexOf('.'));

                    const urlRef = bannerStorageRef.child(`banner-satu${urlExt}`);

                    const urlSnapshot = await urlRef.putString(reader.result, 'data_url');

                    const urlUrl = await urlSnapshot.ref.getDownloadURL();

                    setForm(currentForm => ({
                        ...currentForm,
                        url: urlUrl
                    }));

                    // setSomethingChange(true);
                } catch (e) {
                    setError(error => ({
                        ...error,
                        url: e.message
                    }))


                }

                setSubmitting(false);

            }

            reader.readAsDataURL(file);

        }
    }

    const bannerDuaDoc = firestore.doc(`banner/banner-dua`);

    const [bannerDuaSnapshot, bannerDuaLoading] = useDocument(bannerDuaDoc);

    const [formDua, setFormDua] = useState({
        target_type: '',
        url: '',
        posisi: '',
        target_nama: ''
    });

    const [errorDua, setErrorDua] = useState({
        target_type: '',
        url: '',
        posisi: '',
        target_nama: ''

    });



    useEffect(() => {
        if (bannerDuaSnapshot) {

            const data = bannerDuaSnapshot.data()
            setFormDua({
                ...data,
            });
        }

    }, [bannerDuaSnapshot]);

    const handleChangeDua = e => {

        setFormDua({
            ...formDua,
            [e.target.name]: e.target.value
        })

        setErrorDua({
            ...errorDua,
            [e.target.name]: ''
        })
    }
    const validateDua = () => {

        const newError = {};

        if (!formDua.target_type) {
            newError.target_type = 'Target Klik banner wajib diisi';
        }
        if (!formDua.url) {
            newError.url = 'url banner wajib diisi';
        }
        if (!formDua.posisi) {
            newError.posisi = 'posisi banner wajib diisi';
        }

        return newError;

    }
    const handleSubmitDua = async e => {
        e.preventDefault();
        const findError = await validateDua();

        if (Object.values(findError).some(m => m !== "")) {
            setErrorDua(findError)
        } else {
            setSubmitting(true)
            try {

                const updBannerDua = {
                    ...formDua,
                    target_id: formDua.target_id.id || formDua.target_id,
                    target_nama: formDua.target_id.nama || formDua.target_nama || '',
                    updated_at: FieldValue.serverTimestamp(),
                };

                await bannerDuaDoc.set(updBannerDua, { merge: true });

                enqueueSnackbar("Banner Berhasil Disimpan", { variant: "success" });

            } catch (e) {
                const newError = {};

                newError.title = e.message;

                setErrorDua(newError);
            }

            setSubmitting(false)
        }
    }
    const handleUploadFileDua = async (e) => {
        const file = e.target.files[0];

        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {

            setErrorDua(errorDua => ({
                ...errorDua,
                url: `Tipe file tidak didukung: ${file.type}`
            }))
        }
        else if (file.size >= 512000) {
            setErrorDua(errorDua => ({
                ...errorDua,
                url: `Ukuran file terlalu besar > 500KB`
            }))
        } else {

            const reader = new FileReader();

            reader.onabort = () => {

                setErrorDua(errorDua => ({
                    ...errorDua,
                    url: `Proses pembacaan file dibatalkan`
                }))
            }

            reader.onerrorDua = () => {

                setErrorDua(errorDua => ({
                    ...errorDua,
                    url: 'File tidak bisa dibaca'
                }))
            }

            reader.onload = async () => {
                setErrorDua(errorDua => ({
                    ...errorDua,
                    url: ''
                }))
                setSubmitting(true);
                try {
                    const bannerStorageRef = storage.ref(`banner`);

                    const urlExt = file.name.substring(file.name.lastIndexOf('.'));

                    const urlRef = bannerStorageRef.child(`banner-dua${urlExt}`);

                    const urlSnapshot = await urlRef.putString(reader.result, 'data_url');

                    const urlUrl = await urlSnapshot.ref.getDownloadURL();

                    setFormDua(currentForm => ({
                        ...currentForm,
                        url: urlUrl
                    }));

                    // setSomethingChange(true);
                } catch (e) {
                    setErrorDua(errorDua => ({
                        ...errorDua,
                        url: e.message
                    }))


                }

                setSubmitting(false);

            }

            reader.readAsDataURL(file);

        }
    }

    const kategCol = firestore.collection(`categories`);

    const [kategData, loadingKateg] = useCollectionData(kategCol, { idField: 'id' })

    const kateg2 = kategData?.filter((katego) => {
        return katego.level === 2
    })

    const kateg3 = kategData?.filter((kategor) => {
        return kategor.level === 3

    })

    console.log(formDua?.posisi)


    const target = [
        'kategori1',
        'kategori2',
        'kategori3',
        'brand',
        'promo',
        'produk',
        'webview'
    ];

    const position = [
        'banner1',
        'banner2',

    ];



    if (bannerLoading || bannerDuaLoading || loadingKateg) {
        return <PageLoading />
    }
    return <>
        <Container maxWidth="lg">
            <div className={classes.pageWrap}>
                <Typography component="h1" variant="h6" className={classes.pageTitle}>Daftar Banner</Typography>
                <Grid container spacing={6} className={classes.paperWrap}>
                    <Grid item xs={6}>
                        <Grid container spacing={3} style={{border: '1px solid grey', padding: 15, borderRadius: 10, marginTop: 10}}>
                            <Grid item xs={12}>
                                <Typography>Banner 1</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="target_type"
                                    name="target_type"
                                    margin="normal"
                                    required
                                    value={form?.target_type ?? []}
                                    onChange={(event, value) => {
                                        handleChange({ target: { name: "target_type", value } })
                                    }}
                                    disabled={isSubmitting}
                                    options={target}
                                    renderInput={(params) => <TextField helperText={error?.target_type}
                                        error={error?.target_type ? true : false} {...params} label="Tipe Target" variant="outlined"
                                    />}
                                />
                            </Grid>
                            {
                                form.target_type === 'kategori1' &&
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="target_id"
                                        name="target_id"
                                        margin="normal"
                                        required
                                        value={form.target_id}
                                        onChange={(event, value) => {
                                            handleChange({ target: { name: "target_id", value } })
                                        }}
                                        disabled={isSubmitting}
                                        options={treeDataKategori}
                                        getOptionLabel={(option) => option?.nama}
                                        renderInput={(params) => <TextField helperText={error?.target_id}
                                            error={error?.target_id ? true : false} {...params} label="Target ID" variant="outlined"
                                        />}
                                    />
                                </Grid>
                            }
                            {
                                form.target_type === 'kategori2' &&
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="target_id"
                                        name="target_id"
                                        margin="normal"
                                        required
                                        value={form.target_id}
                                        onChange={(event, value) => {
                                            handleChange({ target: { name: "target_id", value } })
                                        }}
                                        disabled={isSubmitting}
                                        options={kateg2}
                                        getOptionLabel={(option) => option?.nama}
                                        renderInput={(params) => <TextField helperText={error.target_id}
                                            error={error.target_id ? true : false} {...params} label="Target ID" variant="outlined"
                                        />}
                                    />
                                </Grid>
                            }
                            {
                                form.target_type === 'kategori3' &&
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="target_id"
                                        name="target_id"
                                        margin="normal"
                                        required
                                        value={form.target_id}
                                        onChange={(event, value) => {
                                            handleChange({ target: { name: "target_id", value } })
                                        }}
                                        disabled={isSubmitting}
                                        options={kateg3}
                                        getOptionLabel={(option) => option?.nama}
                                        renderInput={(params) => <TextField helperText={error.target_id}
                                            error={error.target_id ? true : false} {...params} label="Target ID" variant="outlined"
                                        />}
                                    />
                                </Grid>
                            }
                            {
                                form.target_type === 'brand' &&
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="target_id"
                                        name="target_id"
                                        margin="normal"
                                        required
                                        value={form.target_id}
                                        onChange={(event, value) => {
                                            handleChange({ target: { name: "target_id", value } })
                                        }}
                                        disabled={isSubmitting}
                                        options={brandList}
                                        getOptionLabel={(option) => option?.nama}
                                        renderInput={(params) => <TextField helperText={error.target_id}
                                            error={error.target_id ? true : false} {...params} label="Target ID" variant="outlined"
                                        />}
                                    />
                                </Grid>
                            }
                            {
                                form.target_type === 'produk' &&
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="target_id"
                                        name="target_id"
                                        margin="normal"
                                        required
                                        value={form.target_id}
                                        onChange={(event, value) => {
                                            handleChange({ target: { name: "target_id", value } })
                                        }}
                                        disabled={isSubmitting}
                                        options={peoductList}
                                        getOptionLabel={(option) => option?.nama}
                                        renderInput={(params) => <TextField helperText={error.target_id}
                                            error={error.target_id ? true : false} {...params} label="Target ID" variant="outlined"
                                        />}
                                    />
                                </Grid>
                            }
                            {
                                form.target_type === 'promo' &&
                                <Grid item xs={12}>
                                    <TextField
                                        autoComplete="off"
                                        disabled={isSubmitting}
                                        id="target_id"
                                        name="Target ID"
                                        label="target_id"
                                        value={form.target_id || ''}
                                        onChange={handleChange}
                                        error={error.target_id ? true : false}
                                        helperText={error.target_id}
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                    />
                                </Grid>
                            }
                            {
                                form.target_type === 'webview' && 
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            autoComplete="off"
                                            disabled={isSubmitting}
                                            id="target_id"
                                            name="target_id"
                                            label="Target ID"
                                            value={form.target_id || ''}
                                            onChange={handleChange}
                                            error={error.target_id ? true : false}
                                            helperText={error.target_id}
                                            fullWidth
                                            multiline
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            autoComplete="off"
                                            disabled={isSubmitting}
                                            id="target_nama"
                                            name="target_nama"
                                            label="Target Nama"
                                            value={form.target_nama || ''}
                                            onChange={handleChange}
                                            error={error.target_nama ? true : false}
                                            helperText={error.target_nama}
                                            fullWidth
                                            multiline
                                            variant="outlined"
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="posisi"
                                    name="posisi"
                                    margin="normal"
                                    required
                                    value={form?.posisi}
                                    onChange={(event, value) => {
                                        handleChange({ target: { name: "posisi", value } })
                                    }}
                                    disabled={isSubmitting}
                                    options={position}
                                    renderInput={(params) => <TextField helperText={error?.posisi}
                                        error={error?.posisi ? true : false} {...params} label="Posisi" variant="outlined"
                                    />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel className={classes.urlLabel}>Thumbnail</InputLabel>
                                <div className={classes.uploadFotoBanner}>
                                    {form.url &&
                                        <div className={classes.imgWrap}><img src={form.url} className={classes.previewFotoBanner} alt="" /></div>}
                                    <input
                                        className={classes.hideInputFile}
                                        type="file"
                                        name="url"
                                        id="upload-url-banner"
                                        accept="image/jpeg,image/png,image/jpg"
                                        onChange={handleUploadFile}
                                    />
                                    <label htmlFor="upload-url-banner">
                                        <Button
                                            disabled={isSubmitting}
                                            name="url"
                                            endIcon={<PermMediaIcon/>}
                                            variant="outlined"
                                            component="span"
                                        >
                                            Pilih Gambar
                                        </Button>
                                    </label>
                                    {
                                        error.url &&
                                        <Typography color="error">
                                            {error.url}
                                        </Typography>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                                <Button
                                    disabled={isSubmitting}
                                    onClick={handleSubmit}
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                >
                                    Simpan
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3} style={{border: '1px solid grey', padding: 15, borderRadius: 10, marginTop: 10}}>
                            <Grid item xs={12}>
                                <Typography>Banner 2</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="target_type"
                                    name="target_type"
                                    margin="normal"
                                    required
                                    value={formDua?.target_type ?? []}
                                    onChange={(event, value) => {
                                        handleChangeDua({ target: { name: "target_type", value } })
                                    }}
                                    disabled={isSubmitting}
                                    options={target}
                                    renderInput={(params) => <TextField helperText={errorDua?.target_type}
                                        error={errorDua?.target_type ? true : false} {...params} label="Tipe Target" variant="outlined"
                                    />}
                                />
                            </Grid>
                            {
                                formDua.target_type === 'kategori1' &&
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="target_id"
                                        name="target_id"
                                        margin="normal"
                                        required
                                        value={formDua.target_id}
                                        onChange={(event, value) => {
                                            handleChangeDua({ target: { name: "target_id", value } })
                                        }}
                                        disabled={isSubmitting}
                                        options={treeDataKategori}
                                        getOptionLabel={(option) => option?.nama}
                                        renderInput={(params) => <TextField helperText={errorDua?.target_id}
                                            error={errorDua?.target_id ? true : false} {...params} label="Target ID" variant="outlined"
                                        />}
                                    />
                                </Grid>
                            }
                            {
                                formDua.target_type === 'kategori2' &&
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="target_id"
                                        name="target_id"
                                        margin="normal"
                                        required
                                        value={formDua.target_id}
                                        onChange={(event, value) => {
                                            handleChangeDua({ target: { name: "target_id", value } })
                                        }}
                                        disabled={isSubmitting}
                                        options={kateg2}
                                        getOptionLabel={(option) => option?.nama}
                                        renderInput={(params) => <TextField helperText={errorDua.target_id}
                                            error={errorDua.target_id ? true : false} {...params} label="Target ID" variant="outlined"
                                        />}
                                    />
                                </Grid>
                            }
                            {
                                formDua.target_type === 'kategori3' &&
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="target_id"
                                        name="target_id"
                                        margin="normal"
                                        required
                                        value={formDua.target_id}
                                        onChange={(event, value) => {
                                            handleChangeDua({ target: { name: "target_id", value } })
                                        }}
                                        disabled={isSubmitting}
                                        options={kateg3}
                                        getOptionLabel={(option) => option?.nama}
                                        renderInput={(params) => <TextField helperText={errorDua.target_id}
                                            error={errorDua.target_id ? true : false} {...params} label="Target ID" variant="outlined"
                                        />}
                                    />
                                </Grid>
                            }
                            {
                                formDua.target_type === 'brand' &&
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            id="target_id"
                                            name="target_id"
                                            margin="normal"
                                            required
                                            value={formDua.target_id}
                                            onChange={(event, value) => {
                                                handleChangeDua({ target: { name: "target_id", value } })
                                            }}
                                            disabled={isSubmitting}
                                            options={brandList}
                                            getOptionLabel={(option) => option?.nama}
                                            renderInput={(params) => <TextField helperText={errorDua.target_id}
                                                error={errorDua.target_id ? true : false} {...params} label="Target ID" variant="outlined"
                                            />}
                                        />
                                    </Grid>
                            }
                            {
                                formDua.target_type === 'produk' &&
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="target_id"
                                        name="target_id"
                                        value={formDua?.target_id}
                                        margin="normal"
                                        required
                                        onChange={(event, value) => {
                                            handleChangeDua({ target: { name: "target_id", value } })
                                        }}
                                        defaultValue={formDua?.target_id}
                                        disabled={isSubmitting}
                                        options={peoductList?.map((prod) => {
                                            return {
                                                id: prod?.id,
                                                nama: prod?.nama,
                                            };
                                        })}
                                        getOptionLabel={(option) => option?.nama}
                                        renderInput={(params) => <TextField helperText={errorDua.target_id}
                                            error={errorDua.target_id ? true : false} {...params} label="Target ID" variant="outlined"
                                            id="target_id"
                                            name="target_id"
                                            value={formDua?.target_id}
                                        />}
                                    />
                                </Grid>
                            }
                            {
                                formDua.target_type === 'promo' &&
                                <Grid item xs={12}>
                                    <TextField
                                        autoComplete="off"
                                        disabled={isSubmitting}
                                        id="target_id"
                                        name="target_id"
                                        label="target_id"
                                        value={formDua.target_id || ''}
                                        onChange={handleChangeDua}
                                        error={errorDua.target_id ? true : false}
                                        helperText={errorDua.target_id}
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                    />
                                </Grid>
                            }
                            {
                                formDua.target_type === 'webview' &&
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            autoComplete="off"
                                            disabled={isSubmitting}
                                            id="target_id"
                                            name="target_id"
                                            label="target_id"
                                            value={formDua.target_id || ''}
                                            onChange={handleChangeDua}
                                            error={errorDua.target_id ? true : false}
                                            helperText={errorDua.target_id}
                                            fullWidth
                                            multiline
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            autoComplete="off"
                                            disabled={isSubmitting}
                                            id="target_nama"
                                            name="target_nama"
                                            label="Target Nama"
                                            value={formDua.target_nama || ''}
                                            onChange={handleChange}
                                            error={errorDua.target_nama ? true : false}
                                            helperText={errorDua.target_nama}
                                            fullWidth
                                            multiline
                                            variant="outlined"
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="posisi"
                                    name="posisi"
                                    margin="normal"
                                    required
                                    value={formDua?.posisi ?? []}
                                    onChange={(event, value) => {
                                        handleChangeDua({ target: { name: "posisi", value } })
                                    }}
                                    disabled={isSubmitting}
                                    options={position}
                                    renderInput={(params) => <TextField helperText={errorDua?.posisi}
                                        error={errorDua?.posisi ? true : false} {...params} label="Posisi" variant="outlined"
                                    />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel className={classes.urlLabel}>Thumbnail Banner 2</InputLabel>
                                <div className={classes.uploadFotoBanner}>
                                    {formDua.url &&
                                        <div className={classes.imgWrap}><img src={formDua.url} className={classes.previewFotoBanner} alt="" /></div>}
                                    <input
                                        className={classes.hideInputFile}
                                        type="file"
                                        name="url"
                                        id="upload-url-banner-dua"
                                        accept="image/jpeg,image/png,image/jpg"
                                        onChange={handleUploadFileDua}
                                    />
                                    <label htmlFor="upload-url-banner-dua">
                                        <Button
                                            disabled={isSubmitting}
                                            name="url"
                                            endIcon={<PermMediaIcon/>}
                                            variant="outlined"
                                            component="span"
                                        >
                                            Pilih Gambar
                                        </Button>
                                    </label>
                                    {
                                        errorDua.url &&
                                        <Typography color="error">
                                            {errorDua.url}
                                        </Typography>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                                <Button
                                    disabled={isSubmitting}
                                    onClick={handleSubmitDua}
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                >
                                    Simpan
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Container>
    </>
}

export default Banner;