import React, { useEffect, useState } from 'react';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import qs from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { firestore } from '../../../components/FirebaseProvider';
import { useDocument } from 'react-firebase-hooks/firestore';
import useStyles from './styles';
import PageLoading from '../../../components/loading/pageLoading';
//import EditVariant from './editVariant';
import EditProduct from './editProduct';
import { useData } from '../../../components/DataProvider';
import Container from '@material-ui/core/Container';

function Edit() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    console.log(location)



    const [tabValue, setTabValue] = useState(qs.parse(location.search)?.tab ?? 'editProduct');
    const handleChangeTab = (event, value) => {
        setTabValue(value);

    }

    useEffect(() => {

        history.push(`?tab=${tabValue}`);
    }, [tabValue, history]);


    const [tabs] = useState([
        {
            label: "Produk", value: "editProduct"
        },
        // {
        //     label: "Variant", value: "editVariant"
        // }


    ])

    const { company_id } = useData();

    const prodDoc = firestore.doc(`produk/${params.productId}`);

    const [prodSnapshot, prodLoading] = useDocument(prodDoc);

    const [prodForm, setProdForm] = useState();
    useEffect(() => {
        if (prodSnapshot) {

            const data = prodSnapshot.data()
            setProdForm({
                ...data,
            });
        }

    }, [prodSnapshot]);




    if (prodLoading) {
        return <PageLoading />
    }

    return (<>
        <Paper className={classes.content}>
            {/* <Paper square>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={tabValue || "editProduct"}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                >
                    {tabs.map(tab => <Tab key={tab.value} label={tab.label} value={tab.value} />)}
                </Tabs>
            </Paper> */}

            <EditProduct value={tabValue} />
            {/* <EditVariant value={tabValue} prodForm={prodForm} /> */}

        </Paper>
    </>
    )
}

export default Edit;