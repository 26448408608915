/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { firestore } from "../../../components/FirebaseProvider";
import { useDocument } from "react-firebase-hooks/firestore";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import { DataGrid } from "@material-ui/data-grid";
import { useData } from "../../../components/DataProvider";






function AccessSequence2(props) {
  const filter = createFilterOptions();
  const { company_id } = useData();
  const { enqueueSnackbar } = useSnackbar();

  const accessSequence = firestore.doc(
    `pricing_conditions/${props.name}`
  );

  const [accessSequenceValue, accessSequenceLoading, accessSequenceError] =
    useDocument(accessSequence);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      sortable: false,
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 80,
      sortable: false,
    },
    {
      field: "key_combination",
      headerName: "Key Combination",
      width: 400,
      sortable: false,
      renderCell: (params) => {
        const result = getOptionsFormat(params.row?.key_combination.split("|"));
        return result.map((val) => {
          return <button>{val.label}</button>;
        });
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 90,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <EditIcon
              onClick={() => {
                setInputform({
                  index: params.row?.id,
                  priority: params.row?.priority,
                  key_combination: getOptionsFormat(
                    params.row?.key_combination.split("|")
                  ),
                  location: params.row?.location,
                });
                setOpendialog({
                  ...opendialog,
                  mode: "Edit",
                  open: true,
                });
              }}
            />
            <DeleteIcon
              onClick={async () => {
                if (
                  window.confirm(
                    `Apakah Anda Yakin Ingin menghapus ${props.name} access sequence: ${params.row.key_combination}?`
                  )
                ) {
                  await handleDialogDeleteAS(params.row.id);
                  enqueueSnackbar("Access Sequence berhasil dihapus", {
                    variant: "success",
                  });
                }
              }}
            />
          </div>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (accessSequenceValue) {
      const newGrid = [];

      accessSequenceValue.data()?.access_sequence.map((i, index) => {
        newGrid.push({
          id: index,
          priority: i.priority,
          key_combination: i.key_combination,
          location: i.location,
        });
      });
      setRows(newGrid);
    }
  }, [accessSequenceValue]);

  const [opendialog, setOpendialog] = useState({
    mode: "Tambah",
    open: false,
  });

  const [inputForm, setInputform] = useState({
    index: 0,
    key_combination: [],
    priority: 0,
    location: "records",
  });

  const handleDialogClose = () => {
    setOpendialog({ ...opendialog, open: false });
    setInputform({ ...inputForm, key_value: [] });
  };

  const handleDialogEditAS = async () => {
    let newArray = [];

    accessSequenceValue.data().access_sequence?.map((as, index) => {
      let newCombination = "";

      //change the selected value into flat string format : {{value1}}|{{value2}}|...etc
      inputForm.key_combination.map((i) => {
        if (newCombination === "") {
          newCombination = `{{${i.value}}}`;
        } else {
          newCombination = `${newCombination}|{{${i.value}}}`;
        }
      });

      //apply changed value & other unchanged value to new array
      if (inputForm.index === index) {
        newArray.push({
          key_combination: newCombination,
          location: inputForm.location,
          priority: inputForm.priority,
        });
      } else {
        newArray.push({
          key_combination: as.key_combination,
          location: as.location,
          priority: as.priority,
        });
      }
    });

    accessSequence.set(
      {
        access_sequence: newArray,
      },
      { merge: true }
    );
    setOpendialog({ ...opendialog, open: false });
    enqueueSnackbar("AccessSequence berhasil dirubah", {
      variant: "success",
    });
  };

  const handleDialogDeleteAS = async (index) => {
    let newArray = accessSequenceValue
      .data()
      .access_sequence.filter((i, y) => y !== index);

    // console.log(index);
    // console.log(newArray);
    accessSequence.set(
      {
        access_sequence: newArray,
      },
      { merge: true }
    );
    setOpendialog({ ...opendialog, open: false });
    enqueueSnackbar("AccessSequence berhasil dirubah", {
      variant: "success",
    });
  };

  const handleDialogAddAS = async () => {
    let newArray = accessSequenceValue.data().access_sequence;

    let newCombination = "";
    //change the selected value into flat string format : {{value1}}|{{value2}}|...etc
    inputForm.key_combination.map((i) => {
      if (newCombination === "") {
        newCombination = `{{${i.value}}}`;
      } else {
        newCombination = `${newCombination}|{{${i.value}}}`;
      }
    });

    newArray.push({
      key_combination: newCombination,
      location: inputForm.location,
      priority: parseInt(inputForm.priority),
    });

    //console.log(newArray);
    accessSequence.set(
      {
        access_sequence: newArray,
      },
      { merge: true }
    );
    setOpendialog({ ...opendialog, open: false });
    enqueueSnackbar("AccessSequence berhasil ditambah", {
      variant: "success",
    });
  };

  //   const handleDeleteCtStep = async (index) => {
  //     let newArray = [];
  //   };

  const sellerLevels = firestore.doc(
    `settings/sellers_level`
  );
  const [sellerLevelList] =
    useDocument(sellerLevels);

  const getKeyOptions = () => {
    const newInput = [];

    //get all selling location
    newInput.push({ label: "order_location", value: "order_location" });
    newInput.push({ label: "product_variant_id", value: "product_variant_id" });
    newInput.push({ label: "seller_id", value: "seller_id" });
    newInput.push({ label: "seller_level", value: "seller_level" });
    //filter against selected key
    let filteredInput = [];
    newInput.map((i) => {
      if (
        inputForm.key_combination?.find((x) => {
          return x.value === i.value;
        })
      ) {
        //do nothing to exclude
      } else {
        filteredInput.push(i);
      }
    });

    return filteredInput;
  };

  const getOptionsFormat = (arr) => {
    let newArray = [];
    if (arr instanceof Array) {
      arr.map((val) => {
        if (Number.isInteger(parseInt(val))) {
          const levelname = sellerLevelList.data()?.custom_name;
          newArray.push({
            label: levelname[val].replace("{{", "").replace("}}", ""),
            value: val.replace("{{", "").replace("}}", ""),
          });
        } else {
          newArray.push({
            label: val.replace("{{", "").replace("}}", ""),
            value: val.replace("{{", "").replace("}}", ""),
          });
        }
      });
    }
    return newArray;
  };

  return (
    <>
      <h4>Pricing Access Sequence - {props.name}</h4>
      <p>
        {accessSequenceError && (
          <strong>Error: {JSON.stringify(accessSequenceError)}</strong>
        )}
        {accessSequenceLoading && <span>Document: Loading...</span>}
      </p>
      {accessSequenceValue && (
        <div style={{ height: 400, width: 700, marginBottom: 50}}>
          <Button
            onClick={() =>
              setOpendialog({
                ...opendialog,
                mode: "Add",
                open: true,
              })
            }
          >
            Tambah Access Sequence
          </Button>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnFilter
            disableColumnMenu={true}
            rowsPerPageOptions={[]}
            disableSelectionOnClick
          />
        </div>
      )}
      <Dialog
        open={opendialog.open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {opendialog.mode === "Edit" ? "Edit" : "Add"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Access Sequence - {inputForm.name}
          </DialogContentText>

          <TextField
            margin="dense"
            id="priority"
            value={inputForm.priority}
            onChange={(e) =>
              setInputform({ ...inputForm, priority: e.target.value })
            }
            label="Priority"
            type="text"
            fullWidth
          />
          <Autocomplete
            multiple
            options={getKeyOptions()}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.label;
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Key" />
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  label: params.inputValue,
                  value: params.inputValue,
                });
              }

              return filtered;
            }}
            onChange={(event, value, reason) => {
              setInputform({ ...inputForm, key_combination: value });
            }}
            value={inputForm.key_combination}
          />

          <FormLabel component="legend">Location</FormLabel>
          <RadioGroup
            aria-label="location"
            name="location"
            value={inputForm.location}
            onChange={(e) =>
              setInputform({
                ...inputForm,
                location: e.target.value,
              })
            }
          >
            <FormControlLabel
              value="records"
              control={<Radio />}
              label="Records"
            />
            <FormControlLabel
              value="product_variant"
              control={<Radio />}
              label="Product Variant"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          {opendialog.mode === "Edit" ? (
            <Button onClick={handleDialogEditAS} color="primary">
              Edit
            </Button>
          ) : (
            <Button onClick={handleDialogAddAS} color="primary">
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
export default AccessSequence2;
