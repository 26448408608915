import parse from 'date-fns/parse';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO'
export const unixToDate = (unix) => {

    if (!unix) {
        return '';
    }
    return format(parse(unix, 'T', new Date()), "dd/MM/yyy HH.mm")
}

export const isoToDate = (iso) => {
    console.log(iso)
    if (!iso) {
        return '';
    }
    return format(parseISO(iso), "dd/MM/yyy")
}

export const isoToTime = (iso) => {
    console.log(iso)
    if (!iso) {
        return '';
    }
    return format(parseISO(iso), "HH.mm")
}

export const isoToDateTime = (iso) => {
    console.log(iso)
    if (!iso) {
        return '';
    }
    return format(parseISO(iso), "ddMMyyyHHmm")
}


