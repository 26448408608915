import React, { useEffect, useState } from "react";
import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import {
  FieldValue,
  firestore,
  useFirebase,
  functions
} from "../../../components/FirebaseProvider";
import UploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import useStyles from "./styles";
import AddMitra from "./add";
import ExelRead from "./addFileExelMitra";
import Paper from "@material-ui/core/Paper";
import PageLoading from "../../../components/loading/pageLoading";
import AppLoading from "../../../components/loading/appLoading";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import PostAddIcon from '@material-ui/icons/PostAdd';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import Alamat from './alamat';
import { useData } from "../../../components/DataProvider";
// import AddSeller from './add';
import Switch from "@material-ui/core/Switch";
import PostAddIcon from "@material-ui/icons/PostAdd";

// lab
import Pagination from "@material-ui/lab/Pagination";

// ico
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import Search from "@material-ui/icons/Search";
// import DeleteSeller from './deleteSeller';
// import EditWa from './editWa';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/app";
import { useSnackbar } from "notistack";
import Virtualize from '../../../components/Virtualization';

export const auth = firebase.auth();

function Penonton() {
  const [AuthData, setAuthData] = useAuthState(auth);
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState("");
  // const [searchEmail , setSearchEmail] = React.useState("");
  const { dataUsers, peoductList } = useData();
  const listComp = firestore.collection(`user`);
  const [dataComp, loadingDataKomisi] = useCollectionData(listComp);
  // console.log('DATA=>', dataComp);
  const [openAddMitra, setOpenAddMitra] = useState(false);
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  const noOfPages = Math.ceil(dataComp?.length / itemsPerPage);
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const [form, setForm] = useState({
        product: ''
    });
  // const phonesCol = firestore.collection(`phones`);
  // const [dataPhones, loadingDataPhones] = useCollectionData(phonesCol, {
  //   idField: "id",
  // });
  // const levelsCol = firestore.collection(`levels`);
  // const [dataLevels, loadingDataLevels] = useCollectionData(levelsCol, {
  //   idField: "id",
  // });
  // const webstoresCol = firestore.collection(`webstores`);
  // let query = webstoresCol;
  // const [dataWeb, loadingDataWeb, errorLoading] = useCollectionData(query, {
  //   idField: "id",
  // });

  const [openDialogAddProduk, setOpenDialogAddProduk] = useState({
    open: false,
    data: "",
    user: ""
  });

  const [isSubmitting, setIssubmitting] = useState(false);
  

  const { enqueueSnackbar } = useSnackbar();

  // dialog tampilkan produk penonton
  const [openDialogCekProduk, setOpenDialogCekProduk] = useState({ open: false, data: "" });
  const [textBlock, setTextBlock] = useState();
  const handleChange = e => {
        let value = e.target.value;
        if (e.target.type === "number") {
            value = parseInt(value);
        }
        // console.log(e.target.type);
        setForm({
            ...form,
            [e.target.name]: value,
        });
        // setError({
        //     ...error,
        //     [e.target.name]: "",
        // });
    }
  const handleCloseCekProduk = () => {
    setOpenDialogCekProduk({ open: false });
  };

  const handleCloseAddProduk = () => {
    setOpenDialogAddProduk({open: false})
  };

  const handleCloseMitra = () => {
    setOpenAddMitra({ open: false });
  };

  async function handleCek (id) {
    const userDoc = await firestore.collection('user').where("email","==",id).get();
    const userInfo = userDoc.docs[0].data();
    //console.log(userDoc.docs[0].id);
    const aksesDoc = await firestore.doc(`akses_produk/${userDoc.docs[0].id}`).get();
    const akses_produk = aksesDoc.data();

    setOpenDialogCekProduk({data:{...userInfo, akses_produk}, open: true});
  };

  async function handleAddProduk (id) {
    
    // const produksList = await firestore.collection('produk').get();
    // const produksInfo = [];
    // produksList.docs.map((prod)=>{
    //   produksInfo.push(prod.data());
    // });

    const userDoc = await firestore.collection('user').where("email","==",id).get();
    const userInfo = userDoc.docs[0].data();

    setOpenDialogAddProduk({ user:{...userInfo, id: userDoc.docs[0].id}, open: true});
  }

  async function handleSubmitAddProduk (id) {
    // console.log('id' , id);
    // console.log('produk_id:', form?.product?.produk_id);

    setIssubmitting(true);
      try {
        const dataPost = {
          produk_id: form?.product?.produk_id,
          userid: id
        };
        const AssignProduk = functions.httpsCallable("user-assignProduk");
        const response = await AssignProduk(dataPost);
        const res = response?.data;
        
        enqueueSnackbar(res.message, { variant: "success" });
        // console.log("=>",dataPost);
      } catch (e) {
        
      }
      setIssubmitting(false);
      handleCloseAddProduk();
  }
  // Search DATA With EMAIL
  const dataSearch = dataComp?.filter?.((data) => {
    if (searchTerm === data?.email) {
      return data;
    }
  });

  // console.log(openDialog)
  if (loadingDataKomisi) {
    return <PageLoading />;
  }
  return (
    <>
      <Container maxWidth="lg">
        <Paper className={classes.paperWrap}>
          <div className={classes.pageWrap}>
            <Grid container item xs={12}>
              <Grid item xs={9}>
                <Grid>
                  <Typography
                    className={classes.pageTitle}
                    component="h1"
                    variant="h6"
                  >
                    Daftar Penonton
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Cari Email Penonton"
                  value={searchTerm}
                  id="search"
                  name="search"
                  variant="outlined"
                  size="small"
                  helperText="masukan email lengkap"
                  onChange={(e) => {
                    setSearchTerm(e?.target?.value);
                  }}
                  InputProps={{
                    endAdornment: <Search color="secondary" />,
                  }}
                />
              </Grid>
            </Grid>
            {searchTerm
              ? dataSearch
                  ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  ?.map?.((users) => {
                    return (
                      <Grid key={users.id}>
                        <Paper variant="outlined" className={classes.paper}>
                          <div className={classes.topBox}>
                            <Typography className={classes.boxTitle}>
                              Info Penonton
                            </Typography>

                            <div className={classes.sellerRow}>
                              <Typography className={classes.rowLabel}>
                                Nama
                              </Typography>
                              <Typography className={classes.rowValue}>
                                :{" "}
                                {users?.nama !== undefined ? (
                                  users?.nama
                                ) : (
                                  <text style={{ color: "red" }}>
                                    nama tidak ada
                                  </text>
                                )}
                              </Typography>
                            </div>
                            <div className={classes.sellerRow}>
                              <Typography className={classes.rowLabel}>
                                Email
                              </Typography>
                              <Typography className={classes.rowValue}>
                                :{" "}
                                {users?.email !== undefined ? (
                                  users?.email
                                ) : (
                                  <text style={{ color: "red" }}>
                                    email tidak ada
                                  </text>
                                )}
                              </Typography>
                            </div>
                            <div className={classes.sellerRow}>
                              <Typography className={classes.rowLabel}>
                                WA User
                              </Typography>
                              <Typography className={classes.rowValue}>
                                :{" "}
                                {users?.wa !== undefined ? (
                                  users?.wa
                                ) : (
                                  <text style={{ color: "red" }}>
                                    Whatsapp tidak ada
                                  </text>
                                )}
                              </Typography>
                            </div>

                            <div className={classes.sellerRow}>
                              <Typography className={classes.rowLabel}>
                                Password
                              </Typography>
                              <Typography className={classes.rowValue}>
                                :{" "}
                                {users?.password !== undefined ? (
                                  users?.password
                                ) : (
                                  <text style={{ color: "red" }}>
                                    Password tidak ada
                                  </text>
                                )}
                              </Typography>
                            </div>
                            <div className={classes.sellerRow}>
                              <Typography className={classes.rowLabel}>
                                Tanggal Bayar
                              </Typography>
                              <Typography className={classes.rowValue}>
                                :{" "}
                                {users?.created_at !== undefined ? (
                                  users?.created_at.toDate().toDateString()
                                ) : (
                                  <text style={{ color: "red" }}>
                                    Tanggal Bayar tidak ada
                                  </text>
                                )}
                              </Typography>
                            </div>
                            {users?.refgroup ? <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Nama RefGroup 
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.refgroup_nama !== undefined ? (
                                    users?.refgroup_nama
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      Refgroup kosong
                                    </text>
                                  )}
                                </Typography>
                              </div> : ""}
                              {users?.isGroupLeader ? <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Group Leader
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" Ya"}
                                </Typography>
                              </div> : ""}
                            <div className={classes.sellerRow}>
                                <Button
                                    className={classes.btnSave}
                                    onClick={()=>handleCek(users?.email)}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    id={users?.id}
                                    //fullWidth
                                  >
                                    {" "}
                                    Cek Produk{" "}
                                </Button>
                                </div>
                                <div className={classes.sellerRow}>
                                <Button
                                    className={classes.btnSave}
                                    onClick={()=>handleAddProduk(users?.email)}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    //fullWidth
                                  >
                                    {" "}
                                    Tambah Produk{" "}
                                </Button>
                              </div>
                            {/* <div className={classes.sellerRow}>
                              <Typography className={classes.rowLabel}>
                                Tanggal Expired
                              </Typography>
                              <Typography className={classes.rowValue}>
                                :{" "}
                                {users?.expired_at !== undefined ? (
                                  users?.expiryDate.toDate().toDateString()
                                ) : (
                                  <text style={{ color: "red" }}>-</text>
                                )}
                              </Typography>
                            </div> */}
                          </div>
                        </Paper>
                      </Grid>
                    );
                  })
              : dataComp
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  ?.map?.((users) => {
                    return (
                      <>
                        <Grid key={users?.id}>
                          <Paper variant="outlined" className={classes.paper}>
                            <div className={classes.topBox2}>
                              <Typography className={classes.boxTitle}>
                                Info Penonton
                              </Typography>

                              <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Nama
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.nama !== undefined ? (
                                    users?.nama
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      nama tidak ada
                                    </text>
                                  )}
                                </Typography>
                              </div>
                            </div>

                            <Grid container item xs={12}>
                              <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Email
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.email !== undefined ? (
                                    users?.email
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      email tidak ada
                                    </text>
                                  )}
                                </Typography>
                              </div>
                              <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Telepon
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.wa !== undefined ? (
                                    users?.wa
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      Whatsapp tidak ada
                                    </text>
                                  )}
                                </Typography>
                              </div>
                              <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Password
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.password !== undefined ? (
                                    users?.password
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      Password tidak ada
                                    </text>
                                  )}
                                </Typography>
                              </div>
                              <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Tanggal Bayar
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.created_at !== undefined ? (
                                    users?.created_at.toDate().toDateString()
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      Tanggal Bayar tidak ada
                                    </text>
                                  )}
                                </Typography>
                              </div>
                              {users?.refgroup ? <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Nama RefGroup
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.refgroup_nama !== undefined ? (
                                    users?.refgroup_nama
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      Refgroup kosong
                                    </text>
                                  )}
                                </Typography>
                              </div> : ""}
                              {users?.isGroupLeader ? <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Group Leader
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" Ya"}
                                </Typography>
                              </div> : ""}
                              <div className={classes.sellerRow}>
                                <Button
                                    className={classes.btnSave}
                                    onClick={()=>handleCek(users?.email)}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    //fullWidth
                                  >
                                    {" "}
                                    Cek Produk{" "}
                                </Button>
                                </div>
                                <div className={classes.sellerRow}>
                                <Button
                                    className={classes.btnSave}
                                    onClick={()=>handleAddProduk(users?.email)}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    //fullWidth
                                  >
                                    {" "}
                                    Tambah Produk{" "}
                                </Button>
                              </div>
                              {/* <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Tanggal Expired
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.expiryDate !== undefined ? (
                                    users?.expiryDate.toDate().toDateString()
                                  ) : (
                                    <text style={{ color: "red" }}>-</text>
                                  )}
                                </Typography>
                              </div> */}
                            </Grid>
                          </Paper>
                        </Grid>
                      </>
                    );
                  })}
            <Pagination
              className={classes.pagination}
              count={noOfPages}
              page={page}
              onChange={handleChangePage}
              defaultPage={1}
              showFirstButton
              showLastButton
              color="primary"
            />
          </div>
        </Paper>
      </Container>

      <div className={classes.fab1}>
        <Fab
          color="secondary"
          onClick={() => {
            setOpenAddMitra(true);
          }}
          tooltip
        >
          <PostAddIcon />
        </Fab>
        <AddMitra
          open={openAddMitra}
          handleClose={() => {
            setOpenAddMitra(false);
          }}
        />
        {/* <Fab
          color="primary"
          variant="extended"
          className={classes.fab}
          onClick={(e) => {
            setOpenAddDialog({ open: true });
          }}
        >
          <UploadIcon className={classes.uploadIcon} />
          <span>Upload File</span>
        </Fab>
        <ExelRead
          dialog={openAddDialog}
          handleClose={() => {
            setOpenAddDialog({ open: false });
          }}
        /> */}
      </div>
      <Dialog
        open={openDialogCekProduk?.open}
        keepMounted
        onClose={handleCloseCekProduk}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialog}
      >
        <DialogContent>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
            </Grid>
          </Grid>
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className={classes.centerText}>
              Produk - {openDialogCekProduk?.data?.nama}
            </Typography>
          </DialogTitle>
          <Typography>
          {openDialogCekProduk?.data?.akses_produk?.akses_produk?.length > 0 ?
          openDialogCekProduk?.data?.akses_produk?.akses_produk?.map((prod)=>{
            return <>
              <p>{prod?.nama_produk} : {prod?.startDate?.seconds} - {prod?.expiryDate?.seconds} </p>
            </>
          }) : "Belum memiliki produk"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup fullWidth variant="text">
            <Button onClick={handleCloseCekProduk} color="primary">
              OK
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogAddProduk?.open}
        keepMounted
        onClose={handleCloseAddProduk}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialog}
      >
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className={classes.centerText}>
              Add Produk untuk {openDialogAddProduk?.user?.nama}
              
            </Typography>
          </DialogTitle>
          {isSubmitting ? <AppLoading /> : 
        <DialogContent>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
                          <Virtualize
                              value={peoductList}
                              data={form?.product}
                              handleChange={handleChange}
                              />
            </Grid>
        </DialogContent>
          }
        <DialogActions>
          <ButtonGroup fullWidth variant="text">
            <Button onClick={handleCloseAddProduk} color="primary">
              Batal
            </Button>
            <Button onClick={() => handleSubmitAddProduk(openDialogAddProduk?.user?.id)} color="primary">
              Tambah
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Penonton;
