import React from "react";
import {
  useCollectionData,
  useDocument,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { firestore, useFirebase } from "./FirebaseProvider";
import AppLoading from "./loading/appLoading";
import { arrayToTree } from "performant-array-to-tree";

const DataContext = React.createContext();

export function useData() {
  return React.useContext(DataContext);
}

export default function DataProvider(props) {
  const { user } = useFirebase();

  const queryKategori = firestore
    .collection(`categories`)
    .orderBy("created_at", "desc");
  const [kategoriList, loadingKategoriList] = useCollectionData(queryKategori, {
    idField: "id",
  });

  const queryBrand = firestore
    .collection(`brand`)
    .orderBy("created_at", "desc");
  const [brandList, loadingBrandList] = useCollectionData(queryBrand, {
    idField: "id",
  });

  const queryProduct = firestore
    .collection(`produk`)
    .orderBy("created_at");
  const [peoductList, loadingPeoductList] = useCollectionData(queryProduct, {
    idField: "id",
  });

  const levelSettingDoc = firestore.doc(`settings/sellers_level`);
  const [levelSetting, loadingLevelSetting] = useDocumentData(levelSettingDoc);

  const codSettingDoc = firestore.doc(`settings/cod`);
  const [codSetting, loadingCodSetting] = useDocument(codSettingDoc);

  const dropshipSettingDoc = firestore.doc(`settings/dropship`);
  const [dropshipSetting, loadingDropshipSetting] =
    useDocument(dropshipSettingDoc);

  const pencairanSettingDoc = firestore.doc(`settings/hide`);
  const [pencairanSetting, loadingPencairanSetting] =
    useDocument(pencairanSettingDoc);

  const usersCol = firestore.collection("user");
  const [dataUsers, loadingDataList] = useCollectionData(usersCol, {
    idField: "id",
  });

  // const querySubdomain = firestore.collection('subdomains').orderBy('created_at', 'desc');
  // const [subdomaintList, loadingSubdomain] = useCollectionData(querySubdomain, { idField: "id" });

  // const queryLevel = firestore.collection('levels').orderBy('created_at', 'desc');
  // const [levelList, loadingLevel] = useCollectionData(queryLevel, { idField: "id" });

  if (
    loadingKategoriList ||
    loadingBrandList ||
    loadingPeoductList ||
    loadingCodSetting ||
    loadingDropshipSetting ||
    loadingPencairanSetting ||
    loadingLevelSetting
    // || loadingDataList || loadingSubdomain || loadingLevel
  ) {
    return <AppLoading />;
  }
  const treeDataKategori = arrayToTree?.(kategoriList, {
    idField: "id",
    parentId: "parent",
    childrenField: "sub",
    dataField: null,
  });
  // console.log(treeDataKategori);
  return (
    <DataContext.Provider
      value={{
        treeDataKategori,
        brandList,
        peoductList,
        levelSetting,
        codSetting,
        dropshipSetting,
        pencairanSetting,
        dataUsers,
        // subdomaintList,
        // levelList
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
