import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExcelJS from "exceljs";
import { Button, LinearProgress, Typography } from "@material-ui/core";
import { functions } from "../../../components/FirebaseProvider";
// import { useData } from "../../../components/DataProvider";
import { useSnackbar } from "notistack";
// import chunk from 'lodash/chunk';
const mapColumnToField = [
  "",
  "mitra_id",
  "nama",
  "email",
  "password",
  "level",
  "nibras_id",
  "status",
  "kode_ref",
];

function ExcelRead({ dialog: { open }, handleClose }) {
  const [Erorr, setErr] = useState("");
  const [Sucsess, setSucsess] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  //const [dirHandle, setDirHandle] = useState();
  // console.log(brandList)

  const loginWithEmailandPass = async (value) => {
    try {
      let email = value.email;
      let password = value.password;
      let level = value.level;
      let nibras_id = value.nibras_id;
      let ref = value.ref;
      let uid = value.uid;
      let status = value.status;
      const dataPost = {
        email: email,
        nama: password,
        //wa: wa,
        api_key: "JheUr74#2|hfaFkkrhA2%93@49",
      };
      // console.log('data_PUSH=>',dataPost);
      const CreateMitra = functions.httpsCallable("user-addUser");
      const response = await CreateMitra(dataPost);
      const res = response?.data;
      setSucsess(res);
    } catch (err) {
      setErr(err);
      setTimeout(() => {
        enqueueSnackbar(`Eror : ${err.message}`, {
          variant: "error",
          autoHideDuration: 2500,
        });
        setErr("");
      }, 3000);
      // console.log(err?.message);
    }
  };

  const handleClick = async () => {
    try {
      const dirHandle = await window.showDirectoryPicker();
      setSubmitting(true);

      //setDirHandle(dirHandle);
      const excelFileHandle = await dirHandle.getFileHandle("mitra.xlsx");
      const excelFile = await excelFileHandle.getFile();

      // const stream = excelFile.stream()
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(excelFile);

      const worksheet = workbook.getWorksheet("data");
      // console.log(worksheet)
      const totalRows = worksheet.rowCount;
      const totalColumn = worksheet.columnCount;
      let rawData = [];
      for (let i = 2; i <= totalRows; i++) {
        const row = worksheet.getRow(i);
        let rowData = {};
        for (let j = 1; j <= totalColumn; j++) {
          const cell = row.getCell(j);
          //console.log("cell-" + j, cell.value);
          if (cell.value) rowData[mapColumnToField[j]] = cell?.value;
        }
        if (Object.keys(rowData).length > 0) {
          rawData.push(rowData);
        }
      }
      // const mapData = [];
      for (let i = 0; i < rawData.length; i++) {
        let data = rawData[i];
        const email = data["email"]?.text;
        const password = data["password"];
        const level = data["level"];
        const nibras_id = data["nibras_id"];
        const status = data["status"];
        const ref = data["kode_ref"];
        const uid = data["mitra_id"];
        // console.log('=>',data);
        loginWithEmailandPass({
          email,
          password,
          nibras_id,
          level,
          uid,
          ref,
          status,
        });
      }
      handleClose();
      enqueueSnackbar("Data Mitra Sedang Di Tambah kan", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      setSubmitting(false);
      if (Sucsess.success === true) {
        enqueueSnackbar("Data Mitra Berhasil Di Tambah kan ", {
          variant: "success",
        });
        setSucsess("");
      }
      // console.log(Err);
    } catch (e) {
      console.log(e.message);
      handleClose();
      enqueueSnackbar(`Produk gagal ditambahkan,${e.message}`, {
        variant: "error",
      });
      setSubmitting(false);
      return <Typography>{e.message}</Typography>;
    }
  };
  // console.log("=>",Sucsess.success);
  if (Sucsess.success === true) {
    enqueueSnackbar(`Succses : ${Sucsess.message}`, {
      variant: "success",
      autoHideDuration: 2500,
    });
    setSucsess("");
  }
  // console.log(Sucsess);
  return (
    <Dialog
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Tambah Data Mitra</DialogTitle>
      {isSubmitting ? <LinearProgress /> : ""}
      <DialogContent dividers>
        <form id="testForm">
          <Button
            variant="contained"
            disabled={isSubmitting}
            color="primary"
            fullWidth
            onClick={handleClick}
          >
            Pilih File
          </Button>
        </form>
        <br />
        <Typography color="error">
          Upload file hanya bisa di lakukan di Chrome
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose} fullWidth>
          Batal
        </Button>
        {/* <Button
                    onClick={submitForm}
                    disabled={isSubmitting}
                    color="primary"
                >
                    Buat
                </Button> */}
      </DialogActions>
    </Dialog>
  );
}
ExcelRead.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ExcelRead;
