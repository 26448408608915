import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paperWrap: {
    margin: '35px 2px',
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    borderRadius: 8,
  },
  tabPanel: {
    display: "flex",
  },
  pageTitle: {
    padding: '15px 30px',
  },
  btm: {
    marginBottom: '20px',
  },
  link: {
    textDecoration: 'none',
    color: '#cd3478',
    fontWeight: 'bold'
  },
  tabWrap: {
    borderBottom: "solid 1px " + theme.palette.textColor.gray2,
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  formWrap: {
    width: "100%",
  },
  alamatRow: {
    width: "100%",
    height: 60,
    marginBottom: 30,
  },
  autocomplete: {
    marginRight: theme.spacing(2),
    paddingRight: 15,
  },
  pickupRow: {
    margin: "20px 0",
    "@media(min-width:320px)": {
      width: 300,
    },
    "@media(min-width:600px)": {
      width: 500,
    },
  },
  btnSave: {
    marginTop: 50,
  },
  pickupTitle: {
    marginTop: 20,
    marginBottom: 10,
    color: theme.palette.textColor.lessTextColor,
    fontWeight: "bold",
  },
  pickupDesc: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
  },
  descLabel: {
    color: theme.palette.textColor.gray8,
    width: 100,
    fontSize: 13,
  },
  descValue: {
    color: theme.palette.textColor.primeTextColor,
    fontSize: 15,
    width: "60%",
    wordBreak: "break-word",
  },
  mapLabel: {
    color: theme.palette.textColor.gray8,
    fontSize: 13,
  },
  mapBox: {
    height: "40vh",
    width: "100%",
  },
  rateid: {
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStyles;
