import React, { useState } from "react";
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExcelJS from "exceljs";
import { Button, LinearProgress, Typography } from "@material-ui/core";
import { FieldValue, firestore, storage } from "../../../components/FirebaseProvider";
import { useData } from "../../../components/DataProvider";
import { useSnackbar } from "notistack";
import chunk from 'lodash/chunk';
const mapColumnToField = [
    '',
    // 'kode_nis',
    // 'kode_basic_item',
    // 'kode_kategori',
    // 'kategori_nis',
    // 'nama_nis',
    // 'kode_model_nis',
    'no',
    "nama",
    'product_id',
    'kategori1',
    'kategori2',
    'kategori3',
    'brand',
    'supplier',
    'deskripsi_singkat',
    'deskripsi_lengkap',
    'sku',
    'variant_id',
    'label_variant_1',
    'value_variant_1',
    'label_variant_2',
    'value_variant_2',
    'harga_retail',
    'panjang_paket',
    'lebar_paket',
    'tinggi_paket',
    'berat',
    'stok_available',
    'stok_booked',
    'lokasi_foto',
    'is_varian',
]



const kategoriCol = firestore.collection(`categories`);

const brandCol = firestore.collection(`brand`);

function ExcelRead({ dialog: { open, }, handleClose }) {
    const { company_id } = useData();

    const { treeDataKategori, brandList } = useData();

    const { enqueueSnackbar } = useSnackbar();


    const [isSubmitting, setSubmitting] = useState(false);
    //const [dirHandle, setDirHandle] = useState();
    // console.log(brandList)
    const handleClick = async () => {

        try {
            const dirHandle = await window.showDirectoryPicker();
            setSubmitting(true);

            //setDirHandle(dirHandle);

            const excelFileHandle = await dirHandle.getFileHandle('product.xlsx')
            const excelFile = await excelFileHandle.getFile()


            // const upImage = await 

            // const stream = excelFile.stream()
            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(excelFile);

            //console.log(workbook)

            const worksheet = workbook.getWorksheet('data');

            //console.log(worksheet)

            const totalRows = worksheet.rowCount;
            const totalColumn = worksheet.columnCount;
            let rawData = [];
            for (let i = 2; i <= totalRows; i++) {
                const row = worksheet.getRow(i);

                //console.log("row-" + i, row);
                //console.log("row-" + i + "-column-total", row.columnCount, worksheet.columnCount);
                let rowData = {};
                for (let j = 1; j <= totalColumn; j++) {

                    const cell = row.getCell(j);
                    //console.log("cell-" + j, cell.value);
                    if (cell.value)
                        rowData[mapColumnToField[j]] = cell?.value;
                }
                if (Object.keys(rowData).length > 0) {
                    rawData.push(rowData);
                }
            }
            // console.log(rawData)

            const mapData = [];
            const defaultVariant = []

            for (let i = 0; i < rawData.length; i++) {
                let data = rawData[i];
                if (data?.is_varian) {
                    defaultVariant.push({ id: data?.variant_id, sku: data?.sku })
                    mapData.push({
                        variant_id: data?.variant_id,
                        product_id: data?.product_id,
                        berat_paket: parseInt(data?.berat),
                        sku: data?.sku,
                        lebar_paket: parseInt(data?.lebar_paket),
                        panjang_paket: parseInt(data?.panjang_paket),
                        tinggi_paket: parseInt(data?.tinggi_paket),
                        // stok_available: parseInt(data?.stok_tersedia),
                        // stok_booked: parseInt(data?.stok_dipesan),
                        thumbnail: data?.lokasi_foto?.split?.(",") ?? [],
                        variant1: {
                            label: data?.label_variant_1,
                            nilai: data?.value_variant_1
                        },
                        variant2: {
                            label: data?.label_variant_2,
                            nilai: data?.value_variant_2
                        },
                        harga_normal: parseInt(data?.harga_retail),
                        // harga_distributor: parseInt(fix.round(data?.harga_retail * (1 - komisi?.distributor))),
                        // harga_reseller: parseInt(fix.round(data?.harga_retail * (1 - komisi?.reseller))),
                        // harga_mentorbisnis: parseInt(fix.round(data?.harga_retail * (1 - komisi?.mentorbisnis))),
                        // harga_agen: parseInt(fix.round(data?.harga_retail * (1 - komisi?.agen))),
                        created_at: FieldValue.serverTimestamp(),
                        updated_at: FieldValue.serverTimestamp()

                    })
                } else {
                    let findRootBrand = brandList?.find(merek => {
                        return merek?.nama === data?.brand
                    })

                    let brands;

                    if (findRootBrand)
                        brands = {
                            id: findRootBrand?.id,
                            nama: findRootBrand?.nama
                        }


                    let findRootKategori = treeDataKategori?.find(kateg => {
                        return kateg?.nama === data?.kategori1

                    })

                    let kateg1;
                    let kateg2;
                    let kateg3;

                    if (findRootKategori) {
                        kateg1 = {
                            id: findRootKategori?.id,
                            nama: findRootKategori?.nama
                        }

                        const findLevel2Kategori = findRootKategori?.sub.find(kateg => {
                            return kateg?.nama === data?.kategori2
                        })

                        if (findLevel2Kategori) {
                            kateg2 = {
                                id: findLevel2Kategori?.id,
                                nama: findLevel2Kategori?.nama
                            }
                            const findeLevel3Kategori = findLevel2Kategori?.sub.find(kateg => {
                                return kateg?.nama === data?.kategori3

                            })
                            if (findeLevel3Kategori) {
                                kateg3 = {
                                    id: findeLevel3Kategori?.id,
                                    nama: findeLevel3Kategori?.nama
                                }
                            }
                        }
                    }



                    // const kateg2 = kateg1.sub
                    // console.log(brands)
                    // console.log(kateg1)
                    // console.log(kateg2)
                    // console.log(kateg3)

                    // return 

                    mapData.push({
                        nama: data?.nama,
                        product_id: data?.product_id,
                        deskripsi_singkat: data?.deskripsi_singkat,
                        deskripsi_lengkap: data?.deskripsi_lengkap,
                        kategori1: kateg1,
                        kategori2: kateg2,
                        kategori3: kateg3,
                        brand: brands,
                        thumbnail: data?.lokasi_foto?.split?.(",") ?? [],
                        variant1_label: data?.label_variant_1,
                        variant2_label: data?.label_variant_2,
                        created_at: FieldValue.serverTimestamp(),
                        updated_at: FieldValue.serverTimestamp(),
                        sembunyikan_produk: false,
                        sorting_number: data?.no
                    })
                }
            }

            //)

            // );

            // console?.log(defaultVariant)


            // console.log(mapData);

            // for (let i = 0; i <= 22; i++) {
            //     const column = worksheet.getColumn(i)
            //     let writeBatch = firestore.batch();
            //     let documentRef = firestore.collection('products');

            //     writeBatch.create(documentRef, { nama: column[1] });

            //     writeBatch.commit().then(() => {
            //         console.log('Successfully executed batch.');
            //     });
            // }
            let lastProductId;
            let mapThumbnailsWithId = [];

            const chunkMapData = chunk(mapData, 500);

            for (let cMapData of chunkMapData) {
                const writeProductBatch = firestore.batch();

                for (let data of cMapData) {

                    const { thumbnail, ...restData } = data;
                    if (data.nama) {
                        const prodRef = firestore.collection('products').doc(`${data?.product_id}`)
                        writeProductBatch.set(prodRef, restData);
                        lastProductId = data?.product_id;
                        if (thumbnail) {
                            mapThumbnailsWithId.push({
                                id: data?.product_id,
                                thumbnail,
                                type: 'product'
                            })
                        }
                    } else {
                        const varianRef = firestore.collection(`products/${lastProductId}/variants`).doc(`${data?.variant_id}`)
                        // console.log(data)
                        writeProductBatch.set(varianRef, {

                            ...restData,
                        });
                        if (thumbnail) {
                            mapThumbnailsWithId.push({
                                id: data?.variant_id,
                                productId: lastProductId,
                                thumbnail,
                                type: 'variant'
                            })
                        }
                    }

                    // console.log(data);

                }
                await writeProductBatch.commit();

            }


            // looping mapThumbnailsWithId 
            const productsStorageRef = storage.ref(`products`);
            let uploadedImage = {};

            const chunkMapThumbnailWithId = chunk(mapThumbnailsWithId, 500);

            for (let cMapThumbnailsWithId of chunkMapThumbnailWithId) {
                const wirteThumbnailBatch = firestore.batch();
                for (let thumbnail of cMapThumbnailsWithId) {

                    //upload disini
                    // const productsStorageRef = storage.ref(`products`);


                    // 	const thumbnailRef = productsStorageRef.child(`${lastProductId}/thumbnail_${Date.now()}${thumbnailExt}`);

                    //      await thumbnailRef.ref.getDownloadURL();

                    let thumbnailUrls = [];
                    for (let thumb of thumbnail?.thumbnail) {
                        if (uploadedImage[thumb]) {
                            thumbnailUrls.push(uploadedImage[thumb])
                        } else {
                            const imgHandle = await dirHandle.getFileHandle(thumb?.trim?.());
                            const imageFile = await imgHandle.getFile();
                            const thumbnailExt = imageFile?.name.substring(imageFile?.name?.lastIndexOf('.'));

                            let thumbnailRef = productsStorageRef.child(`${thumbnail?.id}/thumbnail_${Date.now()}${thumbnailExt}`);
                            if (thumbnail?.type === 'variant') {
                                thumbnailRef = productsStorageRef.child(`${thumbnail?.productId}/variants/${thumbnail?.id}_${Date.now()}${thumbnailExt}`)

                            }
                            const thumbnailSnapshot = await thumbnailRef?.put(imageFile);

                            const thumbnailUrl = await thumbnailSnapshot?.ref.getDownloadURL();
                            thumbnailUrls.push(thumbnailUrl)
                            uploadedImage[thumb] = thumbnailUrl
                        }
                    }

                    // let nextImage 

                    if (thumbnail.type === 'product') {
                        const productRef = firestore.doc(`products/${thumbnail?.id}`);
                        wirteThumbnailBatch.set(productRef, {
                            thumbnail: thumbnailUrls
                        }, { merge: true });
                    } else {
                        const variantRef = firestore.doc(`products/${thumbnail?.productId}/variants/${thumbnail?.id}`)
                        wirteThumbnailBatch.set(variantRef, {
                            thumbnail: thumbnailUrls
                        }, { merge: true });

                    }


                }

                await wirteThumbnailBatch.commit();
            }
            handleClose();
            enqueueSnackbar('Produk ditambahkan', { variant: "success" });
            setSubmitting(false);


        } catch (e) {
            console.log(e.message)
            handleClose();
            enqueueSnackbar(`Produk gagal ditambahkan,${e.message}`, { variant: "error" });
            setSubmitting(false);
            return <Typography>{e.message}</Typography>
        }

    }

    // const submitForm = e => {
    //     e.preventDefault();
    //     setSubmitting(true)
    //     const wb = new ExcelJS.Workbook();
    //     const reader = new FileReader();
    //     var file = document.getElementById("testFile");
    //     reader.readAsArrayBuffer(file.files[0]);
    //     reader.onload = () => {
    //         const buffer = reader.result;
    //         wb.xlsx.load(buffer).then(workbook => {
    //             console.log(workbook, "workbook instance");
    //             workbook.eachSheet((sheet, id) => {
    //                 sheet.eachRow((row, rowIndex) => {
    //                     console.log(row.values, rowIndex);
    //                 });
    //             });
    //         });
    //         handleClose();

    //     };
    //     setSubmitting(false)

    // };


    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}

        >
            <DialogTitle>Tambah Produk</DialogTitle>
            {isSubmitting ? <LinearProgress /> : ''}
            <DialogContent dividers>
                <form id="testForm">
                    <Button variant="contained" disabled={isSubmitting} color="primary" fullWidth onClick={handleClick}>Pilih File</Button>
                </form>
                <br />
                <Typography color="error">Upload file hanya bisa di lakukan di Chrome</Typography>

            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    fullWidth
                >Batal</Button>
                {/* <Button
                    onClick={submitForm}
                    disabled={isSubmitting}
                    color="primary"
                >
                    Buat
                </Button> */}
            </DialogActions>
        </Dialog>
    )
}
ExcelRead.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default ExcelRead;


