import React, { useEffect, useState } from "react";
import TabPanel from "../../../components/TabPanel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";
import useStyles from "./styles";
import MidtransSetting from "./payment/midtrans";
import DokuSetting from "./payment/doku";
import MootaSetting from "./payment/moota";
import { useData } from "../../../components/DataProvider";
import Typography from "@material-ui/core/Typography";

export default function Payment({ value }) {
  const classes = useStyles();

  const [selected, setSelected] = useState("midtrans");
  const { company_id } = useData();

  const paymentSetting = firestore.doc(
    `settings/payment`
  );
  const [setting] = useDocument(paymentSetting);

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const selectedPayment = () => {
    switch (selected) {
      case "midtrans":
        return (
          <MidtransSetting
            user={company_id}
            ChangeUseDefaultPayment={ChangeUseDefaultPayment}
          />
        );
        break;
      case "doku":
        return (
          <DokuSetting
            user={company_id}
            ChangeUseDefaultPayment={ChangeUseDefaultPayment}
          />
        );
        break;
      case "moota":
        return (
          <MootaSetting
            user={company_id}
            ChangeUseDefaultPayment={ChangeUseDefaultPayment}
          />
        );
        break;
      default:
        return "";
        break;
    }
  };

  const ChangeUseDefaultPayment = async (sender) => {
    await paymentSetting.set(
      {
        use_available: sender,
      },
      { merge: true }
    );
  };

  useEffect(() => {
    if (setting) {
      setSelected(setting.data()?.use_available);
    }
  }, [setting]);

  return (
    <TabPanel value={value} index="payment">
      <p>Payment adalah layanan terkait pembayaran</p>
      {setting && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
              <FormControl component="fieldset">
                <Typography component="legend">Pilih layanan payment:</Typography>
                <RadioGroup
                  aria-label="payment"
                  name="payment"
                  value={selected}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="midtrans"
                    control={<Radio />}
                    label="Midtrans"
                  />
                  <FormControlLabel
                    value="doku"
                    control={<Radio />}
                    label="Doku"
                  />
                  <FormControlLabel
                    value="moota"
                    control={<Radio />}
                    label="Moota"
                  />
                  <FormControlLabel
                    value="lainnya..."
                    disabled
                    control={<Radio />}
                    label="(Lainnya...)"
                  />
                </RadioGroup>
              </FormControl>
          </Grid>
          <Grid item xs={6}>
            <div>{selectedPayment()}</div>
          </Grid>
        </Grid>
      )}
    </TabPanel>
  );
}
