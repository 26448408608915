import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Fab from '@material-ui/core/Fab';
import MaterialTable from 'material-table';
import useStyles from './styles';
import AddSuperadmin from './add';
import { useCollection } from 'react-firebase-hooks/firestore';
import PageLoading from '../../../components/loading/pageLoading';
import { unixToDate } from '../../../utils/dateFormatter';
import { useSnackbar } from 'notistack';
import tableIcons from '../../../config/table-icons';
import { firestore, FieldValue, useFirebase } from '../../../components/FirebaseProvider';
// import ExcelRead from './addFileExcel';
import { useData } from '../../../components/DataProvider';




function Superadmin() {

    const classes = useStyles();

    const { user } = useFirebase();
    console.log(user?.uid)
    const { enqueueSnackbar } = useSnackbar();


    const [openAddSuperadmin, setOpenAddSuperadmin] = useState(false);


    // const komisiDoc = firestore.doc(`settings/komisi`);

    // const [komisiSnapshot, komisiLoading] = useDocument(komisiDoc);

    // const [komisiForm, setKomisiForm] = useState();

    // console.log(komisiForm)

    // useEffect(() => {
    // 	if (komisiSnapshot) {

    // 		const data = komisiSnapshot.data()
    // 		setKomisiForm({
    // 			...data,
    // 		});
    // 	}

    // }, [komisiSnapshot]);



    // const { company_id } = useData();

    const col = firestore.collection(`superadmin`);

    const [snapshot, loading] = useCollection(col);

    const [table, setTable] = useState({
        columns: [
            // {
            // 	title: 'Thumbnail', field: 'thumbnail',
            // 	render: rowData => {
            // 		const firstImage = rowData.thumbnail?.[0]
            // 		if (firstImage)
            // 			return <img alt="" src={firstImage} height="120px" />

            // 		return <p>Belum Ada Photo</p>
            // 	}
            // },
            { title: 'Email', field: 'email', editable: 'never' },
            // { title: 'Password_tmp', field: 'password_tmp' },
            // { title: 'access_role', field: 'access_role' },
            {
                title: 'Diunggah', field: 'created_at', editable: 'never',

                render: rowData =>
                    <>
                        {
                            rowData && rowData.created_at !== null ?
                                <span>
                                    {unixToDate(rowData.created_at?.toMillis())}
                                </span>
                                :
                                <span>
                                    -
                                </span>
                        }
                    </>
            },
            {
                title: 'Diubah', field: 'updated_at', editable: 'never',

                render: rowData =>
                    <>
                        {
                            rowData && rowData.updated_at !== null ?
                                <span>
                                    {unixToDate(rowData.updated_at?.toMillis())}
                                </span>
                                :
                                <span>
                                    -
                                </span>
                        }
                    </>
            },

        ],
        data: [
        ],
    });

    useEffect(() => {
        if (snapshot && !snapshot.empty) {
            setTable(table => {
                return {
                    ...table,
                    data: snapshot.docs.map(doc => {
                        return {
                            uid: doc.id,
                            ...doc.data()
                        }
                    })
                }
            })
        } else {
            setTable(table => {
                return {
                    ...table,
                    data: []
                }
            })
        }
    }, [snapshot]);


    if (loading
        // || komisiLoading
    ) {
        return <PageLoading />
    }

    return (
        <>
            <div className={classes.root}>
                {
                    loading ?
                        <PageLoading />
                        :
                        <Container className={classes.tableCon} maxWidth="lg">
                            <div className={classes.tableWrap}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title="Daftar Admin"
                                    columns={table.columns}
                                    data={table.data}
                                    components={{
                                        Container: props => <div {...props} elevation={0} />
                                    }}
                                    editable={{

                                        onRowDelete: async (data) => {
                                            await firestore.doc(`superadmin/${data.uid}`).delete();
                                            enqueueSnackbar('Superadmin Berhasil Dihapus', { variant: "success" })
                                        },

                                        // onRowAdd: async (newData) => {

                                        // 	try {
                                        // 		if (!newData.thumbnail || !newData.nama || !newData.product_id || !newData.deskripsi) {

                                        // 			enqueueSnackbar('Data Produk Tidak Lengkap', { variant: "error" });
                                        // 			throw new Error("Data Produk Tidak Lengkap");
                                        // 		}
                                        // 		else {
                                        // 			const newProduct = {
                                        // 				thumbnail: newData.thumbnail,
                                        // 				nama: newData.nama,
                                        // 				product_id: newData.product_id,
                                        // 				deskripsi: newData.deskripsi,
                                        // 				created_at: FieldValue.serverTimestamp(),
                                        // 				updated_at: FieldValue.serverTimestamp(),
                                        // 			}

                                        // 			await firestore.collection('products').add(newProduct);
                                        // 			enqueueSnackbar('Produk Berhasil Ditambahkan', { variant: "success" });
                                        // 		}


                                        // 	}
                                        // 	catch (e) {
                                        // 		console.log(e.message)
                                        // 	}


                                        // },

                                        // onRowUpdate: async (newData, data) => {
                                        //     try {
                                        //         if (newData.password_tmp?.length < 8) {
                                        //             enqueueSnackbar('Password_tmp harus minimal 6 karakter', { variant: "error" });
                                        //         } else {
                                        //             const updateProduct = {
                                        //                 password_tmp: newData.password_tmp,
                                        //                 access_role: newData.access_role,
                                        //                 updated_at: FieldValue.serverTimestamp()
                                        //             }
                                        //             await firestore.doc(`superadmin/${data.uid}`).set(updateProduct, { merge: true });
                                        //             enqueueSnackbar('Superadmin Berhasil Diubah', { variant: "success" });
                                        //         }
                                        //     }
                                        //     catch (e) {
                                        //     }
                                        // }
                                    }}

                                    // actions={[
                                    // 	{
                                    // 		icon: 'edit',
                                    // 		tooltip: 'Edit',
                                    // 		onClick: (event, data) => {
                                    // 			history.push(`/product/edit/${data.uid}`);
                                    // 		}
                                    // 	}

                                    // ]}

                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: 'Tidak Ada Data',
                                            addTooltip: 'Tambah',
                                            editTooltip: 'Ubah',
                                            deleteTooltip: 'Hapus',
                                            editRow: {
                                                deleteText: 'Anda Yakin Akan Menghapus Data Ini ?',
                                                cancelTooltip: 'Batal',
                                                saveTooltip: 'Ya'
                                            }
                                        },
                                        toolbar: {
                                            searchTooltip: 'Cari',
                                            searchPlaceholder: 'Cari Data'
                                        },
                                        header: {
                                            actions: 'Actions'
                                        },
                                        pagination: {
                                            labelRowsSelect: 'Baris',
                                            labelDisplayedRows: ' {from}-{to} Dari {count} Baris',
                                            firstTooltip: 'Halaman Pertama',
                                            previousTooltip: 'Halaman Sebelumnya',
                                            nextTooltip: 'Halaman Selanjutnya',
                                            lastTooltip: 'Halaman Terakhir'
                                        }
                                    }}
                                />
                            </div>
                        </Container>
                }
            </div>
            <div className={classes.fab1}>
                <Fab
                    color="secondary"
                    onClick={() => {
                        setOpenAddSuperadmin(true);
                    }}
                    tooltip
                >
                    <PostAddIcon />
                </Fab>
                <AddSuperadmin
                    open={openAddSuperadmin}
                    handleClose={() => {
                        setOpenAddSuperadmin(false);
                    }}
                />
                {/* <Button color="primary" variant="contained" className={classes.fab}
					onClick={(e) => {
						setOpenAddDialog({
							open: true,
							// komisi: komisiForm
						});
					}}
				>
					<UploadIcon className={classes.uploadIcon} /> <span>Upload File</span>
				</Button>
				<ExcelRead
					dialog={openAddDialog}
					handleClose={() => {
						setOpenAddDialog({
							open: false,
							// komisi: null
						});
					}}
				/> */}
            </div>
        </>
    );
}

export default Superadmin;
