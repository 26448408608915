import { useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { auth,  firestore, useFirebase } from "../../components/FirebaseProvider"
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "../../components/Logo";
// import { useData } from "../../components/DataProvider";

function Validation() {
    const history = useHistory();
    const { user } = useFirebase();
    const classes = useStyles();

    // const { company_id } = useData();
    // back to login
    const handleLogout = (e) => {
        auth.signOut();
        history.push(`/login`)
    };
    // firestore
    const nibrasIdCol = firestore.collection(`superadmin`)
    const [data, loadData] = useCollectionData(nibrasIdCol, { idField: 'id' })
    // findData

    // State Data
    const [form, setForm] = useState()

    // State Error
    const [error, setError] = useState()
    //Handle Change Textfield
    const handleChange = e => {
        setForm(e.target.value)
        setError('')
    }
    let findNibrasId = data?.find(nibras => {
        return nibras?.superadmin_code === form
    })
    console.log(user?.uid)
    // role

    const [isSubmitting, setSubmitting] = useState(false);
    const handleSimpan = async e => {
        setSubmitting(true);
        try {
            if (!findNibrasId?.superadmin_code) {
                throw new Error('superadmin code tidak ditemukan');
            }
            const nibrasIddoc = firestore.doc(`superadmin/${findNibrasId?.id}`);
            await nibrasIddoc.set({
                superadmin_id: user?.uid
            }, { merge: true })
            history.push(`/`)
        } catch (e) {
            setError(e.message)
        }
        setSubmitting(false);
    }
    console.log(findNibrasId)
    return <>
        <Container maxWidth="xs" className={classes.container}>
            <div className={classes.logo}>
                <Logo />
            </div>
            <Typography className={classes.pageTitle}>Silahkan Masukkan Superadmin Code</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        id="superadmin_code"
                        label="Superadmin Code"
                        value={form}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        helperText={error}
                        error={error ? true : false}
                        InputProps={{
                            endAdornment: loadData && <CircularProgress size={24} />
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={isSubmitting}
                        onClick={handleSimpan}
                        fullWidth
                        color="primary"
                        variant="contained"
                    >
                        Submit
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={isSubmitting}
                        fullWidth
                        onClick={handleLogout}
                        color="primary"
                    >
                        Logout
                    </Button>
                </Grid>
            </Grid>
        </Container>
        {/* <PrivateRoute nibrasId={findNibrasId?.nibras_id} /> */}
    </>
}

export default Validation;
