import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '30px',
    },
    pageWrap: {
        padding: '0 30px',
        paddingBottom: 5,
        marginBottom: 40,
    },
    pageTitle: {
        paddingTop: 15,
    },
    addBox: {
        marginTop: 20,
    },
    listWrap: {
        // border: 'solid 1px #ddd',
        borderRadius: 8,
        margin: '20px 0'
    },
    nav: {
        '& div:first-child': {
            borderRadius: '8px 8px 0 0',
        }
    },
    fabKursus: {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    addKategori: {
        marginLeft: theme.spacing(2)
    },
    tableStyles: {
    },
    root: {
        display: 'flex',
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(1)
    },
    tree: {
        display: 'flex',
        marginRight: 7
    },
    hideInputFile: {
        display: 'none'
    },
    uploadIconCateg: {
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    iconRight: {
        paddingLeft: 5,
    },
    previewIconCateg: {
        width: '100%',
        height: 'auto',
        marginBottom: 20
    },
    listIcon: {
        minWidth: 30,
    },
    listTopLevel: {
        backgroundColor: '#fcead3',
        '&:hover': {
            backgroundColor: '#fae0be'
        },
        borderBottom: 'solid 1px ' + theme.palette.textColor.white,
    },
    listLevel1: {
        backgroundColor: '#f8d5a8',
        '&:hover': {
            backgroundColor: '#f7cb92'
        },
    },
    listLevel2: {
        backgroundColor: '#f5c07c',
        '&:hover': {
            backgroundColor: '#f3b666'
        },
    },
    nested: {
        paddingLeft: '40px !important',
        backgroundColor: '#f8d5a8',
        '&:hover': {
            backgroundColor: '#f7cb92'
        },
        borderBottom: 'solid 1px ' + theme.palette.textColor.white,
    },
    nested2: {
        paddingLeft: '65px !important',
        borderBottom: 'solid 1px ' + theme.palette.textColor.white,
        borderRadius: 0,

    },
    addChild: {
        backgroundColor: '#fff',
        border: 'solid 1px ' + theme.palette.textColor.gray9,
        textTransform: 'capitalize',
        marginLeft: 10,
        color: theme.palette.textColor.gray
    },
    addChild2: {
        backgroundColor: '#fff',
        border: 'solid 1px ' + theme.palette.textColor.gray9,
        textTransform: 'capitalize',
        marginLeft: 10,
        color: theme.palette.textColor.gray
    },
    levelText: {
        maxWidth: 200,
        flex: 'unset'
    },
    actionBtn: {
        display: 'flex',
        width: 180,
        position: 'absolute',
        top: 15,
        right: 15,
        color: theme.palette.textColor.blue
    },
    actionBtn2: {
        display: 'flex',
        width: 180,
        color: theme.palette.textColor.blue
    },
    actionBtnItem: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 10px',
        color: '#4f5e90'
    },
    addMore: {
        backgroundColor: '#fcead3',
        '&:hover': {
            backgroundColor: '#fae0be'
        },
        borderRadius: '0 0 8px 8px'
    },
    addMoreIcon: {
        minWidth: 30,
    }
}));

export default useStyles;
