import React from "react";
import "react-datasheet/lib/react-datasheet.css";
import AccessSequence2 from "./accessSequence2";
import ConditionRecords3 from "./conditionRecords3";

function PricingCondition(props) {
  return (
    <>
      <AccessSequence2 name={props?.name} />
      <ConditionRecords3 name={props?.name} />
    </>
  );
}

export default PricingCondition;
