import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { functions, firestore } from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import { useDocument } from "react-firebase-hooks/firestore";
import { useData } from "../../../components/DataProvider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";

function AddRefgroup({ open, handleClose }) {
  const [form, setForm] = useState({
    nama: "",
    leader: "",
    komisi: "",
  });
  const [error, setError] = useState({
    nama: "",
    leader: "",
    komisi: "",
  });
  // console.log("DATA FROM=>",form);
  // const [level, setlevel] = useState(null)
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  const [hideAktif, setHideAktif] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    // console.log(form)
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  
  const validate = async () => {
    const newError = { ...error };
    if (!form.nama) {
      newError.nama = "Nama Referal Group harus diisi";
    }
    if (!form.leader) {
      newError.password = "ID Leader harus diisi";
    }
    if (!form.komisi) {
      newError.wa = "Komisi harus diisi";
    }
    return newError;
  };
  // console.log(form.password?.length)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();
    // console.log(findError)
    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        
        //cari user berdasarkan input email
        const userDoc = await firestore.collection('user').where('email', '==', form.leader).get();
        const userId = userDoc.docs[0].id;
        const userRef = firestore.doc(`user/${userId}`);

        //... add refgroup
        const refgroupCol = firestore.collection(`refgroup`);
        const refgroupDoc = await refgroupCol.add({
          nama : form.nama,
          user_leader : {id : userId, email: form.leader, nama: userDoc.docs[0].data().nama},
          komisi : parseInt(form.komisi) / 100
        });

        await userRef.set({isGroupLeader: true, refgroup: refgroupDoc.id, refgroup_nama: form.nama },{merge: true})
        handleClose();
        enqueueSnackbar('Referal Group berhasil ditambahkan', { variant: "success" });
        // console.log("=>",dataPost);
      } catch (e) {
        console.log(e.message);
        enqueueSnackbar(e.message, { variant: "error" });
      }
      setSubmitting(false);
    }
  };

  const handleChangeAktif = () => {
    setHideAktif(!hideAktif);
    // console.log("Sekarang=>",hideAktif);
  };

  return (
    <Dialog
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Tambah Referal Group</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="nama"
              name="nama"
              label="Nama Group"
              onChange={handleChange}
              error={error.nama ? true : false}
              helperText={error.nama}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="leader"
              name="leader"
              label="Group Leader (Email user)"
              onChange={handleChange}
              error={error.leader ? true : false}
              helperText={error.leader}
              fullWidth
              multiline
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="komisi"
              name="komisi"
              label="Komisi (%)"
              onChange={handleChange}
              error={error.komisi ? true : false}
              helperText={error.komisi}
              fullWidth
              multiline
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose}>
          Batal
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
          Tambah
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddRefgroup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddRefgroup;
