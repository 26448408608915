import { makeStyles } from "@material-ui/core/styles";
import theme from "../../config/theme";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        marginTop: '10vh'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    listNav: {
        paddingTop: 0,
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        textDecoration: "none",
        padding: theme.spacing(1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    logo: {
        marginRight: 90,
    },
    content: {
        flexGrow: 1,
        marginTop: theme.spacing(10),
        //overflow: 'auto',
    },
    contentShrink: {
        flexGrow: 1,
        // marginTop: theme.spacing(10),
        //overflow: 'auto',
    },
    listItem: {
        paddingTop: 13,
        paddingBottom: 13,
        "&:hover": {
            backgroundColor: theme.palette.primary.lighter + " !important",
            "& *": {
                color: theme.palette.primary.main,
            },
        },
    },
    selectedList: {
        borderLeft: "solid 5px" + theme.palette.primary.main,
        backgroundColor: theme.palette.primary.lighter + " !important",
        "& *": {
            color: theme.palette.primary.main,
        },
    },
    liText: {
        color: "red",
    },
    centerText: {
        textAlign: "center",
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default useStyles;
