// React
import React, { useState } from 'react';

// Material UI
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import isEmail from 'validator/lib/isEmail';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Logo from '../../components/Logo';

// Firebase
import { useFirebase, auth, GoogleAuthProvider, firestore, FieldValue } from '../../components/FirebaseProvider';

//React Router Dom
import { Redirect, useHistory } from 'react-router-dom';


import PageLoading from '../../components/loading/pageLoading';
import { useSnackbar } from 'notistack';



function Login(props) {

    // Styles
    const { classes, location } = props;

    // Firebase
    const { loading, user } = useFirebase();

    // Snackbar
    const { enqueueSnackbar } = useSnackbar();

    // react-router-dom
    const history = useHistory();

    // google.auth
    const handleLogin = async () => {
        try {

            const provider = new GoogleAuthProvider();

            const result = await auth.signInWithPopup(provider);
            const logUser = auth.currentUser;
            // if (result.additionalUserInfo.isNewUser === true) {
            //     const compDoc = firestore.doc(`companies/${logUser.uid}`);

            //     await compDoc?.set({
            //         created_at: FieldValue.serverTimestamp()
            //     }, { merge: true })
            // }
            console.log(result.additionalUserInfo.isNewUser)
            console.log(logUser)
            // history.push(`/companies`)
        } catch (e) {
            console.log(e.message)
            enqueueSnackbar(e.message, { variant: "error" })
        }

    }
    // State Data
    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    // State Error
    const [error, setError] = useState({
        email: '',
        password: ''
    })

    // State Submitting
    const [isSubmitting, setSubmitting] = useState(false);

    //Handle Change Textfield
    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }

    // Validasi
    const validate = () => {
        const newError = { ...error };
        const { email, password } = form;


        if (!email) {
            newError.email = 'Email Harus Di Isi';

        } else if (!isEmail(email)) {
            newError.email = 'Email Tidak Valid';
        }

        if (!password) {
            newError.password = 'Password Harus Di Isi';
        }


        return newError;
    }

    // Handle Submit
    const handleSubmit = async e => {
        e.preventDefault();
        const findError = validate();

        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                await auth.signInWithEmailAndPassword(form.email, form.password);
            } catch (e) {
                const newError = {};
                console.log(e.message)
                switch (e.code) {

                    case 'auth/user-not-found':
                        newError.email = 'Email tidak terdaftar';
                        break;
                    case 'auth/invalid-email':
                        newError.email = 'Email tidak valid';
                        break;
                    case 'auth/wrong-password':
                        newError.password = 'Password salah';
                        break;
                    case 'auth/user-disabled':
                        newError.email = 'Pengguna di blokir';
                        break;
                    default:
                        newError.email = 'Terjadi kesalahan, silahkan coba lagi';
                        break;
                }

                setError(newError);
                setSubmitting(false)
            }
        }
    }

    // Loading
    if (loading) {
        return <PageLoading />
    }

    // Redirect User Terautentikasi
    if (user) {
        const redirectTo = location.state && location.state.from && location.state.from.pathname ? location.state.from.pathname : '/';
        return <Redirect to={redirectTo} />
    }

    return (

        <Container maxWidth="xs">
            <div className={classes.loginBlock}>
                <div style={{display: 'flex', justifyItems: 'center'}}><Logo/></div>
                <Typography className={classes.pageTitle}>Selamat Datang, Admin</Typography>
                <Typography className={classes.pageSubtitle}>Silahkan login untuk melanjutkan</Typography>
                <form onSubmit={handleSubmit}>
                    <div className={classes.formRow}>
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="email"
                            name="email"
                            placeholder="Masukkan Email"
                            className={classes.textField}
                            value={form.email}
                            onChange={handleChange}
                            helperText={error.email}
                            error={error.email ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon className={classes.iconColor} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className={classes.formRow}>
                        <TextField
                            autoComplete="off"
                            variant="outlined"
                            disabled={isSubmitting}
                            id="password"
                            name="password"
                            placeholder="Masukkan Password"
                            className={classes.textField}
                            type="password"
                            value={form.password}
                            onChange={handleChange}
                            helperText={error.password}
                            error={error.password ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <VpnKeyIcon className={classes.iconColor} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className={classes.formRow}>
                        <Button
                            disabled={isSubmitting}
                            className={classes.loginBtn}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {isSubmitting === true && <CircularProgress size={24} className={classes.buttonProgress} />}Masuk
                        </Button>
                    </div>
                </form>
                {/* <div className={classes.btnSignin}>
                    <Button variant="outlined" onClick={handleLogin} fullWidth> <img src={GoogleIcon} alt="google icon" className={classes.googleLogo} /> Sign in with Google</Button>
                </div> */}
            </div>
        </Container>

    );
}

export default withStyles(styles)(Login);