import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        padding: 36,
        marginTop: '8%',
    },
    logo: {
        display: 'flex',
        justifyContent: 'center'
    },
    pageTitle: {
        marginTop: 15,
        marginBottom: 20,
        color: 'grey',
        fontSize: 17,
        textAlign: 'center',
    },
}))


export default useStyles;
