/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { firestore } from "../../../components/FirebaseProvider";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import { DataGrid } from "@material-ui/data-grid";
import { useData } from "../../../components/DataProvider";


function ConditionRecords3(props) {
  const filter = createFilterOptions();
  const { company_id } = useData();
  const { enqueueSnackbar } = useSnackbar();

  const conditionRecords = firestore.collection(
    `pricing_conditions/${props.name}/records`
  );

  const [conditionRecordsList, conditionRecordsLoading, conditionRecordsError] =
    useCollection(conditionRecords);

  const columns = [
    {
      field: "key_value",
      headerName: "Key Combination",
      width: 300,
      sortable: false,
      renderCell: (params) => {
        const result = getOptionsFormat(params.row?.key_value.split("|"));
        return result.map((val) => {
          return <button>{val.label}</button>;
        });
      },
    },
    { field: "amount", headerName: "Amount", width: 90, sortable: false },
    {
      field: "amount_type",
      headerName: "Type",
      width: 90,
      sortable: false,
    },
    {
      field: "qty_relation",
      headerName: "Qty Relation",
      width: 110,
      sortable: false,
    },
    {
      field: "sign_operator",
      headerName: "Operator",
      width: 90,
      sortable: false,
    },
    {
      field: "statistical",
      headerName: "Statistical",
      width: 100,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <EditIcon
              onClick={() => {
                setInputform({
                  name: params.row?.key_value,
                  key_value: getOptionsFormat(params.row?.key_value.split("|")),
                  amount: params.row?.amount,
                  amount_type: params.row?.amount_type,
                  qty_relation: params.row?.qty_relation,
                  sign_operator: params.row?.sign_operator,
                  statistical: params.row?.statistical,
                });
                setOpendialog({
                  ...opendialog,
                  mode: "Edit",
                  open: true,
                });
              }}
            />
            <DeleteIcon
              onClick={async () => {
                if (
                  window.confirm(
                    `Apakah Anda Yakin Ingin menghapus ${props.name} records: ${params.row.key_value}?`
                  )
                ) {
                  await conditionRecords.doc(params.row.key_value).delete();
                  enqueueSnackbar("ConditionRecords berhasil dihapus", {
                    variant: "success",
                  });
                }
              }}
            />
          </div>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const newValues = [];
    if (conditionRecordsList) {
      conditionRecordsList.docs.map((doc, index) => {
        newValues.push({
          id: doc.id,
          key_value: doc.id,
          amount: doc.data().amount,
          amount_type: doc.data().amount_type,
          qty_relation: doc.data().qty_relation,
          sign_operator: doc.data().sign_operator,
          statistical: doc.data().statistical,
        });
      });
      setRows(newValues);
    }
    // else {
    //   setRows(newValues);
    // }
  }, [conditionRecordsList]);

  const [opendialog, setOpendialog] = useState({
    mode: "Tambah",
    open: false,
  });

  const [inputForm, setInputform] = useState({
    name: "",
    key_value: [],
    amount: 0,
    amount_type: "%",
    qty_relation: true,
    sign_operator: "-",
    statistical: false,
  });
  // console.log(inputForm)
  const handleDialogClose = () => {
    setOpendialog({ ...opendialog, open: false });
    setInputform({ ...inputForm, key_value: [] });
  };

  const handleDialogEditCR = async () => {
    const conditionRecordsDoc = inputForm.name;
    const newRecords = {
      amount: parseFloat(inputForm.amount),
      amount_type: inputForm.amount_type,
      qty_relation: inputForm.qty_relation,
      sign_operator: inputForm.sign_operator,
      statistical: inputForm.statistical,
    };
    await conditionRecords
      .doc(conditionRecordsDoc)
      .set(newRecords, { merge: true });
    setInputform({
      name: "",
      key_value: [],
      amount: 0,
      amount_type: "%",
      qty_relation: true,
      sign_operator: "-",
      statistical: false,
    });
    setOpendialog({ ...opendialog, open: false });
    enqueueSnackbar("ConditionRecords berhasil dirubah", {
      variant: "success",
    });
  };

  const handleDialogAddCR = async () => {
    let newDocId = "";

    inputForm.key_value.map((i) => {
      if (newDocId === "") {
        newDocId = `${i.value}`;
      } else {
        newDocId = `${newDocId}|${i.value}`;
      }
    });

    const newRecords = {
      amount: parseFloat(inputForm.amount),
      amount_type: inputForm.amount_type,
      qty_relation: inputForm.qty_relation,
      sign_operator: inputForm.sign_operator,
      statistical: inputForm.statistical,
    };
    const res = await conditionRecords.doc(newDocId).get();
    if (res.exists) {
      alert(
        "Data dengan Key Combination ini sudah ada, silahkan gunakan Key Combination lain atau edit records yang ada..."
      );
    } else {
      await conditionRecords.doc(newDocId).set(newRecords);
      setInputform({
        name: "",
        key_value: [],
        amount: 0,
        amount_type: "%",
        qty_relation: true,
        sign_operator: "-",
        statistical: false,
      });
      setOpendialog({ ...opendialog, open: false });
      enqueueSnackbar("ConditionRecords berhasil ditambah", {
        variant: "success",
      });
    }
  };

  const sellerLevels = firestore.doc(
    `settings/sellers_level`
  );
  const [sellerLevelList] =
    useDocument(sellerLevels);

  const getKeyOptions = () => {
    const newInput = [];
    //get all products variant

    //get all selling location
    newInput.push({ label: "sellerapp", value: "sellerapp" });
    newInput.push({ label: "webstore", value: "webstore" });

    //get all level
    if (sellerLevelList) {
      const levelname = sellerLevelList.data()?.custom_name;
      for (var i = 1; i <= sellerLevelList.data().depth; i++) {
        newInput.push({
          label: levelname[i],
          value: i,
        });
      }
    }

    //filter against selected key
    let filteredInput = [];
    newInput.map((i) => {
      if (
        inputForm.key_value.find((x) => {
          return x.value === i.value;
        })
      ) {
        //do nothing to exclude
      } else {
        filteredInput.push(i);
      }
    });

    return filteredInput;
  };

  const getOptionsFormat = (arr) => {
    let newArray = [];
    if (arr instanceof Array) {
      arr.map((val) => {
        if (Number.isInteger(parseInt(val))) {
          const levelname = sellerLevelList.data()?.custom_name;
          newArray.push({ label: levelname[val], value: val });
        } else {
          newArray.push({ label: val, value: val });
        }
      });
    }
    return newArray;
  };

  // change amount
  const handleChangeAmount = e => {
    let value = e.target.value
    if (e.target.type === 'number') {
      value = parseFloat(value)
    }
    // console.log(e.target.type)
    setInputform({ ...inputForm, amount: value })



  }

  return (
    <>
      <h4>Pricing Conditions Records - {props.name}</h4>
      <p>
        {conditionRecordsError && (
          <strong>Error: {JSON.stringify(conditionRecordsError)}</strong>
        )}
        {conditionRecordsLoading && <span>Document: Loading...</span>}
      </p>
      {conditionRecordsList && (
        <div style={{ height: 400, width: "100%" }}>
          <Button
            onClick={() =>
              setOpendialog({
                ...opendialog,
                mode: "Add",
                open: true,
              })
            }
          >
            Tambah Records
          </Button>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnFilter
            disableColumnMenu={true}
            rowsPerPageOptions={[]}
            disableSelectionOnClick
          />
        </div>
      )}

      <Dialog
        open={opendialog.open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {opendialog.mode === "Edit" ? "Edit" : "Add"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Condition Records - {inputForm.name}
          </DialogContentText>

          <Autocomplete
            multiple
            options={getKeyOptions()}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.label;
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Key" />
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  label: params.inputValue,
                  value: params.inputValue,
                });
              }

              return filtered;
            }}
            onChange={(event, value, reason) => {
              setInputform({ ...inputForm, key_value: value });
            }}
            value={inputForm.key_value}
            disabled={opendialog.mode === "Edit" ? true : false}
          />
          <TextField
            margin="dense"
            id="amount"
            value={inputForm.amount}
            onChange={handleChangeAmount}
            label="Amount"
            type="number"
            fullWidth
          />
          <FormLabel component="legend">Amount Type</FormLabel>
          <RadioGroup
            aria-label="amounttype"
            name="amount_type"
            value={inputForm.amount_type}
            onChange={(e) =>
              setInputform({
                ...inputForm,
                amount_type: e.target.value,
              })
            }
          >
            <FormControlLabel
              value="%"
              control={<Radio />}
              label="Percentage"
            />
            <FormControlLabel
              value="amount"
              control={<Radio />}
              label="Amount"
            />
          </RadioGroup>

          <FormLabel component="legend">Quantity Relation</FormLabel>
          <RadioGroup
            aria-label="amounttype"
            name="amount_type"
            value={inputForm.qty_relation}
            onChange={(e) => {
              console.log(e.target.value);
              setInputform({
                ...inputForm,
                qty_relation: e.target.value === "true" ? true : false,
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="True" />
            <FormControlLabel value={false} control={<Radio />} label="False" />
          </RadioGroup>

          <FormLabel component="legend">Sign Operator</FormLabel>
          <RadioGroup
            aria-label="amounttype"
            name="amount_type"
            value={inputForm.sign_operator}
            onChange={(e) =>
              setInputform({
                ...inputForm,
                sign_operator: e.target.value,
              })
            }
          >
            <FormControlLabel value="+" control={<Radio />} label="+" />
            <FormControlLabel value="-" control={<Radio />} label="-" />
            <FormControlLabel value="no" control={<Radio />} label="no" />
          </RadioGroup>

          <FormLabel component="legend">Statistical</FormLabel>
          <RadioGroup
            aria-label="statistical"
            name="statistical"
            value={inputForm.statistical}
            onChange={(e) => {
              console.log(e.target.value);
              setInputform({
                ...inputForm,
                statistical: e.target.value === "true" ? true : false,
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="True" />
            <FormControlLabel value={false} control={<Radio />} label="False" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          {opendialog.mode === "Edit" ? (
            <Button onClick={handleDialogEditCR} color="primary">
              Edit
            </Button>
          ) : (
            <Button onClick={handleDialogAddCR} color="primary">
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
export default ConditionRecords3;
