// React
import React from 'react';

// Material UI
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';



function PageLoading() {

    const loginContainer = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        marginTop: 210
    }

    return (
        <Container maxWidth="sm" style={loginContainer}>
            <CircularProgress size={60} style={loginContainer} />
        </Container>
    )
}
export default PageLoading;