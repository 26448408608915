import React, { useState, useEffect } from 'react';
import Fab from '@material-ui/core/Fab';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import EditIcon from '@material-ui/icons/Edit';
import Container from '@material-ui/core/Container';
import MaterialTable from 'material-table';

import { useCollection } from 'react-firebase-hooks/firestore';
import { firestore } from '../../../components/FirebaseProvider';
import PageLoading from '../../../components/loading/pageLoading';
import tableIcons from '../../../config/table-icons';
import { unixToDate } from '../../../utils/dateFormatter';
import useStyles from './styles';
import IconButton from '@material-ui/core/IconButton';
// import { Helmet } from 'react-helmet';
import AddBrand from './addBrand';
import { useData } from '../../../components/DataProvider';

function Brand() {

    const classes = useStyles();
    const { company_id } = useData();

    const brandCol = firestore.collection(`brand`);

    const [snapshot, loading] = useCollection(brandCol);

    const [openAddDialog, setOpenAddDialog] = useState({
        mode: 'Tambah',
        open: false,
        data: {},
        uid: {}
    });
    const [table, setTable] = useState({
        columns: [
            {
                title: 'Icon', field: 'icon',
                render: rowData => <img alt="" src={rowData.icon} height="100px" />
            },
            { title: 'Name', field: 'nama' },
            {
                title: 'Created At',
                field: 'created_at',
                render: rowData =>
                    <>
                        {
                            rowData && rowData.created_at !== null ?
                                <span>
                                    {unixToDate(rowData.created_at.toMillis())}
                                </span>
                                :
                                <span>
                                    -
                                </span>
                        }
                    </>,
                editable: 'never'
            },
            {
                title: 'Updated At',
                field: 'updated_at',
                render: rowData =>
                    <>
                        {
                            rowData && rowData.updated_at !== null ?
                                <span>
                                    {unixToDate(rowData.updated_at.toMillis())}
                                </span>
                                :
                                <span>
                                    -
                                </span>
                        }
                    </>,

                editable: 'never'
            }
        ],
        data: [

        ],
    });

    useEffect(() => {
        if (snapshot && !snapshot.empty) {
            console.log(snapshot)
            setTable(table => {
                return {
                    ...table,
                    data: snapshot.docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        }
                    })
                }
            })

        } else {
            setTable(table => {
                return {
                    ...table,
                    data: []
                }
            })
        }
    }, [snapshot]);

    if (loading) {
        return <PageLoading />
    }



    console.log(table);


    return <>
        <Container maxWidth="lg" className={classes.pageWrap}>
            <MaterialTable
                icons={tableIcons}
                title="Daftar Brand"
                columns={table.columns}
                data={table.data}
                editable={{
                    onRowDelete: async (oldData) => {
                        await firestore.doc(`brand/${oldData.id}`).delete();
                    },
                }}

                actions={[
                    {
                        icon: () => <IconButton size="small" >
                            <EditIcon />
                        </IconButton>,
                        tooltip: 'Edit ',
                        onClick: (event, oldData) => {
                            setOpenAddDialog(openAddDialog => ({
                                ...openAddDialog,
                                mode: 'Edit',
                                data: oldData,
                                id: oldData.id,
                                open: true
                            }))
                        }

                    }

                ]}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'Tidak Ada Data',
                        addTooltip: 'Tambah',
                        editTooltip: 'Edit',
                        deleteTooltip: 'Delete',
                        editRow: {
                            deleteText: 'Anda Yakin Akan Menghapus Data Ini ?',
                            cancelTooltip: 'Cancel',
                            saveTooltip: 'Ya'
                        }
                    },
                    toolbar: {
                        searchTooltip: 'Search',
                        searchPlaceholder: 'Search Data'
                    },
                    header: {
                        actions: 'Actions'
                    },
                    pagination: {
                        labelRowsSelect: 'Line',
                        labelDisplayedRows: ' {from}-{to} From {count} Line',
                        firstTooltip: 'First Page',
                        previousTooltip: 'Prev Page',
                        nextTooltip: 'Next Page',
                        lastTooltip: 'Last Page'
                    }
                }}
            />
        </Container>

        <AddBrand
            openAddDialog={openAddDialog}
            handleClose={() => {
                setOpenAddDialog({ mode: 'Tambah', open: false, data: {} });
            }}
        />
        <Fab color="secondary" className={classes.fabKursus}
            onClick={(e) => {
                setOpenAddDialog({ mode: 'Tambah', open: true, data: {} });

            }}
        >
            <PlaylistAdd />
        </Fab>

    </>
}

export default Brand;
