import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import { firestore, useFirebase } from '../FirebaseProvider';

// import { useFirebase } from './FirebaseProvider';

// import { useDocument } from 'react-firebase-hooks/firestore';

// import AppPageLoading from './AppPageLoading';
import DataProvider from '../DataProvider';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import AppLoading from '../loading/appLoading';

function SuperAdminValidate({ component: Component, ...restProps }) {

    const { user } = useFirebase();
    const nibrasIdCol = firestore.collection(`superadmin`)
    const [data, loadData] = useCollectionData(nibrasIdCol, { idField: 'id' })
    let findNibrasId = data?.find(nibras => {
        return nibras?.superadmin_code === user?.uid
    })
    if (loadData) {
        return <AppLoading />
    }
    return <Route
        {...restProps}

        render={props => {
            // if (roleLoading) {
            //     return <AppPageLoading />
            // }
            return !findNibrasId &&
                <DataProvider>
                    <Component {...props} />
                </DataProvider>

        }}

    />
}

export default SuperAdminValidate;
