import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paperWrap: {
        margin: '35px 2px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        borderRadius: 8,
    },
    tabPanel: {
        display: 'flex'
    },
    pageTitle: {
        padding: '15px 30px',
    },
    tabWrap: {
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
    },
    listTopLevel: {
        backgroundColor: theme.palette.primary.lessMain,
        borderBottom: 'solid 1px ' + theme.palette.textColor.white,
    },
    pagination: {
        '& > *': {
            display: 'flex',
            marginTop: theme.spacing(2),
            alignItems: 'center',
            justifyContent: 'center'
        },
        display: 'flex',
        marginTop: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center'
    },
    upload: {
        display: 'flex',
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(6)
    },
    uploadIcon: {
        marginRight: 10,
    },
}));

export default useStyles;