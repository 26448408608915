import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./home";
import Product from "./product";
import Categories from "./categories";
import Tiket from "./tiket";
import clsx from "clsx";

// material-ui/core
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import { useTheme } from "@material-ui/core/styles";
import { Collapse, List } from "@material-ui/core";

// material ui icon
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import GroupIcon from "@material-ui/icons/Group";
// import FeedbackIcon from "@material-ui/icons/Feedback";
// import DnsIcon from "@material-ui/icons/Dns";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CategoryIcon from "@material-ui/icons/Category";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import TuneIcon from "@material-ui/icons/Tune";
// import StoreIcon from "@material-ui/icons/ShoppingCart";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import PanoramaIcon from "@material-ui/icons/Panorama";
import BurstModeIcon from "@material-ui/icons/BurstMode";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import RedeemIcon from "@material-ui/icons/Redeem";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import HomeIcon from "@material-ui/icons/Home";
// import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
// import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
import LabelIcon from "@material-ui/icons/Label";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import useStyles from "./styles";
import { auth } from "../../components/FirebaseProvider";
import Brand from "./brand";
// import Orders from "./orders";
// import Komisi from "./komisi";
import Banner from "./banner";
import Slider from "./slider";
import Pricing from "./pricing";
// import Promo from "./promo";
// import Reward from "./reward";
// import Penukaran from "./penukaran";
import Analytics from "./analytics";
import Settings from "./settings";
// import Pencairan from "./pencairan";

// import LogoTokoRame from "../../images/logo";
// import PilihProduk from "./pilihProduk";
// import ListSeller from "./listSeller";
// import Feedback from "./feedback";
// import GudangRoute from "../../components/routing/GudangRoute";
// import AppLoading from "../../components/AppLoading";
// import Companies from "./companies";
// import Seller from "./seller";
// import Settings from "./settings";
import Integrations from "./integrations";
import { useDashboardUI } from "../../components/DashboardUIProvider";
import Penonton from "./penonton";
import Coverage from "./coverage";
import Notification from "./notification";
import Refgroup from "./refgroup";
// import Admin from "./admin";
import Logo from "../../components/Logo";
import PencairanRekber from "./pencairanRekber";
import Superadmin from "./superadmin";
import PromoOngkir from "./promoOngkir";
import PromoProduct from "./promoProduct";
import { useData } from "../../components/DataProvider";

function Private() {
  const classes = useStyles();
  const theme = useTheme();
  const { appbar } = useDashboardUI();
  const [openDrawer, setOpenDrawer] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const { pencairanSetting } = useData();

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleLogout = (e) => {
    auth.signOut();
  };

  const [openMasterData, setOpenMasterData] = React.useState(false);

  const handleClickMasterData = () => {
    setOpenMasterData(!openMasterData);
    setOpenReward(false);
    setOpenSetting(false);
  };

  const [openReward, setOpenReward] = React.useState(false);

  const handleClickReward = () => {
    setOpenReward(!openReward);
    setOpenMasterData(false);
    setOpenSetting(false);
  };

  const [openSetting, setOpenSetting] = React.useState(false);

  const handleClickSetting = () => {
    setOpenSetting(!openSetting);
    setOpenMasterData(false);
    setOpenReward(false);
  };

  // const { user } = useFirebase();
  //   const roleDoc = firestore.doc(`admins/${user.uid}`)
  //   const [role, roleLoading, error] = useDocument(roleDoc);
  //   const [form, setForm] = useState();

  //   useEffect(() => {
  //     if (role) {

  //       const data = role.data()
  //       setForm({
  //         ...data,
  //       });
  //     }

  //   }, [role]);
  //   console.log(error)
  //   if (roleLoading) {
  //     return <AppLoading />
  //   }

  return (
    <>
      <div className={classes.root}>
        {appbar ? (
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: openDrawer,
            })}
          >
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: openDrawer,
                })}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                <Switch>
                  {/* <Route path="/companies" children="Companies" /> */}
                  <Route path="/categories" children="Master Data" />
                  <Route path="/tiket" children="Tiket" />
                  <Route path="/product" children="Produk" />
                  <Route path="/brand" children="Master Data" />

                  <Route path="/orders" children="Orders" />
                  {/* <Route path="/komisi" children="Komisi" /> */}
                  <Route path="/banner" children="Banner" />
                  <Route path="/slider" children="Slider" />
                  <Route path="/pricing" children="Pricing" />
                  <Route path="/promoOngkir" children="Promo Ongkir" />
                  <Route path="/promoProduct" children="Promo Product" />
                  <Route path="/coverage" children="Settings" />
                  {/* <Route path="/penukaran" children="Penukaran" /> */}
                  <Route path="/analytics" />
                  <Route path="/settings" children="Settings" />
                  <Route path="/integrations" children="Settings" />
                  <Route path="/pencairanRekber" children="Pencairan Rekber" />
                  <Route path="/notification" children="Push Notification" />
                  <Route path="/superadmin" children="Super Admin" />
                  {/* <Route path="/feedback" children="Feedback" /> */}
                  {/* <Route path="/401" children="Unauthorized" /> */}
                  {/* <Route path="/404" children="Page Not Found" /> */}
                  <Route path="/penonton" children="Penonton" />
                  <Route path="/refgroup" children="Refgroup" />

                  <Route children="Dashboard" />
                </Switch>
              </Typography>
            </Toolbar>
          </AppBar>
        ) : null}

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: openDrawer,
              [classes.drawerClose]: !openDrawer,
            }),
          }}
        >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.listNav}
          >
            <div className={classes.toolbar}>
              {/* <LogoTokoRame /> */}
              <Logo />
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>

            <Divider />
            {/* {
              form?.role === 'administrator' &&
              <> */}
            <Route
              exact
              path="/"
              children={({ match, history }) => {
                return (
                  <ListItem
                    button
                    selected={match ? true : false}
                    onClick={() => history.push("/")}
                    className={classes.listItem}
                    classes={{ selected: classes.selectedList }}
                  >
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItem>
                );
              }}
            />

            <Divider />
            <Route
              exact
              path="/penonton"
              children={({ match, history }) => {
                return (
                  <ListItem
                    button
                    selected={match ? true : false}
                    onClick={() => history.push("/penonton")}
                    className={classes.listItem}
                    classes={{ selected: classes.selectedList }}
                  >
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="List Penonton" />
                  </ListItem>
                );
              }}
            />
            <Divider />
            {/* <Route
              path="/analytics"
              children={({ match, history }) => {
                return (
                  <ListItem
                    button
                    selected={match ? true : false}
                    onClick={() => history.push("/analytics")}
                    className={classes.listItem}
                    classes={{ selected: classes.selectedList }}
                  >
                    <ListItemIcon>
                      <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Analytics" />
                  </ListItem>
                );
              }}
            /> */}

            {/* <Divider />
            <Route
              exact
              path="/superadmin"
              children={({ match, history }) => {
                return (
                  <ListItem
                    button
                    selected={match ? true : false}
                    onClick={() => history.push("/superadmin")}
                    className={classes.listItem}
                    classes={{ selected: classes.selectedList }}
                  >
                    <ListItemIcon>
                      <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText primary="Super Admin" />
                  </ListItem>
                );
              }}
            />

            <Divider /> */}
            {/* <Route
              path="/orders"
              children={({ match, history }) => {
                return (
                  <ListItem
                    button
                    selected={match ? true : false}
                    onClick={() => history.push("/orders")}
                    className={classes.listItem}
                    classes={{ selected: classes.selectedList }}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary="Orders" />
                  </ListItem>
                );
              }}
            />

            <Divider />
 */}

            <Route
              path="/pencairanRekber"
              children={({ match, history }) => {
                return (
                  (pencairanSetting.data()?.hideRekber) ? <></>:
                  <ListItem
                    button
                    selected={match ? true : false}
                    onClick={() => history.push("/pencairanRekber")}
                    className={classes.listItem}
                    classes={{ selected: classes.selectedList }}
                  >
                    <ListItemIcon>
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary="Pencairan" />
                  </ListItem>
                );
              }}
            />

            <Divider />

            {/* <Route
              path="/analytics"
              children={({ match, history }) => {
                return (
                  <ListItem
                    button
                    selected={match ? true : false}
                    onClick={() => history.push("/analytics")}
                    className={classes.listItem}
                    classes={{ selected: classes.selectedList }}
                  >
                    <ListItemIcon>
                      <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Analytics" />
                  </ListItem>
                );
              }}
            />

            <Divider /> */}
            {/* <Route
              path="/seller"
              children={({ match, history }) => {
                return (
                  <ListItem
                    button
                    selected={match ? true : false}
                    onClick={() => history.push("/seller")}
                    className={classes.listItem}
                    classes={{ selected: classes.selectedList }}
                  >
                    <ListItemIcon>
                      <DnsIcon />
                    </ListItemIcon>
                    <ListItemText primary="List Seller" />
                  </ListItem>
                );
              }}
            />

            <Divider /> */}

            {/* <ListItem
              button
              onClick={handleClickMasterData}
              className={classes.listItem}
              classes={{ selected: classes.selectedList }}
            >
              <ListItemIcon>
                <FolderSpecialIcon />
              </ListItemIcon>
              <ListItemText primary="Master Data" />
              {openMasterData ? <ExpandLess /> : <ExpandMore />}
            </ListItem> */}

            <List component="div" disablePadding>
              <Route
                path="/tiket"
                children={({ match, history }) => {
                  return (
                    <ListItem
                      button
                      selected={match ? true : false}
                      onClick={() => history.push("/tiket")}
                      className={classes.ListItem}
                      classes={{ selected: classes.selectedList }}
                    >
                      <ListItemIcon>
                        <CategoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="Tiket" />
                    </ListItem>
                  );
                }}
              />

              {/* <Route
                  path="/brand"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/brand")}
                        className={classes.nested}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <LoyaltyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Brand" />
                      </ListItem>
                    );
                  }}
                />

              */}
               <Divider />
                <Route
                  path="/product"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/product")}
                        className={classes.ListItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <LocalMallIcon />
                        </ListItemIcon>
                        <ListItemText primary="Produk" />
                      </ListItem>
                    );
                  }}
                />

                <Divider />
                <Route
                  path="/refgroup"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/refgroup")}
                        className={classes.ListItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <GroupWorkIcon />
                        </ListItemIcon>
                        <ListItemText primary="Referal Group" />
                      </ListItem>
                    );
                  }}
                />
            </List>

            {/* <Divider />

            <ListItem
              button
              onClick={handleClickReward}
              className={classes.listItem}
              classes={{ selected: classes.selectedList }}
            >
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary="Promo" />
              {openReward ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openReward} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>


                <Route
                  path="/promoOngkir"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/promoOngkir")}
                        className={classes.nested}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <LocalShippingIcon />
                        </ListItemIcon>
                        <ListItemText primary="Promo Ongkir" />
                      </ListItem>
                    );
                  }}
                />
                <Route
                  path="/promoProduct"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/promoProduct")}
                        className={classes.nested}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <LabelIcon />
                        </ListItemIcon>
                        <ListItemText primary="Promo Product" />
                      </ListItem>
                    );
                  }}
                />
              </List>
            </Collapse> */}

            {/* <Divider /> */}

            {/* <Divider />

            <Route
              path="/notification"
              children={({ match, history }) => {
                return (
                  <ListItem
                    button
                    selected={match ? true : false}
                    onClick={() => history.push("/notification")}
                    className={classes.listItem}
                    classes={{ selected: classes.selectedList }}
                  >
                    <ListItemIcon>
                      <NotificationsActiveIcon />
                    </ListItemIcon>
                    <ListItemText primary="Push Notification" />
                  </ListItem>
                );
              }}
            /> */}

            <Divider />
            {/* <Route
              path="/feedback"
              children={({ match, history }) => {
                return (
                  <ListItem
                    button
                    selected={match ? true : false}
                    onClick={() => history.push("/feedback")}
                    className={classes.listItem}
                    classes={{ selected: classes.selectedList }}
                  >
                    <ListItemIcon>
                      <FeedbackIcon />
                    </ListItemIcon>
                    <ListItemText primary="Feedback" />
                  </ListItem>
                );
              }}
            />

            <Divider /> */}

            {/* <ListItem
              button
              onClick={handleClickSetting}
              className={classes.listItem}
              classes={{ selected: classes.selectedList }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
              {openSetting ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openSetting} timeout="auto" unmountOnExit> */}
            <List component="div" disablePadding>
              <Route
                path="/settings"
                children={({ match, history }) => {
                  return (
                    <ListItem
                      button
                      selected={match ? true : false}
                      onClick={() => history.push("/settings")}
                      className={classes.ListItem}
                      classes={{ selected: classes.selectedList }}
                    >
                      <ListItemIcon>
                        <TuneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Settings" />
                    </ListItem>
                  );
                }}
              />

              {/* <Route
                  path="/banner"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/banner")}
                        className={classes.nested}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <PanoramaIcon />
                        </ListItemIcon>
                        <ListItemText primary="Banner" />
                      </ListItem>
                    );
                  }}
                />

                <Route
                  path="/slider"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/slider")}
                        className={classes.nested}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <BurstModeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Slider" />
                      </ListItem>
                    );
                  }}
                />

                <Route
                  path="/pricing"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/pricing")}
                        className={classes.nested}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <MonetizationOnIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pricing" />
                      </ListItem>
                    );
                  }}
                />

                <Route
                  path="/integrations"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/integrations")}
                        className={classes.nested}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <SettingsInputComponentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Integrations" />
                      </ListItem>
                    );
                  }}
                />
                <Route
                  path="/coverage"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/coverage")}
                        className={classes.nested}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <TrackChangesIcon />
                        </ListItemIcon>
                        <ListItemText primary="Coverage" />
                      </ListItem>
                    );
                  }}
                /> */}
            </List>
            {/* </Collapse> */}

            <Divider />

            {/* </>}
            {/* {
              form?.role === 'akunting' &&
              <>
                <Route
                  path="/pencairan"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/pencairan")}
                        className={classes.listItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <MonetizationOnIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pencairan" />
                      </ListItem>
                    );
                  }}
                />

                <Divider />

                <Route
                  path="/analytics"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/analytics")}
                        className={classes.listItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <AssessmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Analytics" />
                      </ListItem>
                    );
                  }}
                />

                <Divider /></>
            }
            {
              form?.role === 'gudang' &&
              <>
                <Route
                  path="/orders"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/orders")}
                        className={classes.listItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <StoreIcon />
                        </ListItemIcon>
                        <ListItemText primary="Orders" />
                      </ListItem>
                    );
                  }}
                />

                <Divider />
                <Route
                  path="/analytics"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/analytics")}
                        className={classes.listItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <AssessmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Analytics" />
                      </ListItem>
                    );
                  }}
                />

                <Divider /> </>
            }
            {
              form?.role === 'cs' &&
              <>
                <Route
                  exact
                  path="/"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/")}
                        className={classes.listItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                      </ListItem>
                    );
                  }}
                />

                <Divider />

                <Route
                  path="/orders"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/orders")}
                        className={classes.listItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <StoreIcon />
                        </ListItemIcon>
                        <ListItemText primary="Orders" />
                      </ListItem>
                    );
                  }}
                />

                <Divider />

               

                <Route
                  path="/listSeller"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/listSeller")}
                        className={classes.listItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <DnsIcon />
                        </ListItemIcon>
                        <ListItemText primary="List Seller" />
                      </ListItem>
                    );
                  }}
                />

                <Divider />

                <ListItem
                  button
                  onClick={handleClickMasterData}
                  className={classes.listItem}
                  classes={{ selected: classes.selectedList }}
                >
                  <ListItemIcon>
                    <CategoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Master Data" />
                  {openMasterData ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openMasterData} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>


                    <Route
                      path="/categories"
                      children={({ match, history }) => {
                        return (
                          <ListItem
                            button
                            selected={match ? true : false}
                            onClick={() => history.push("/categories")}
                            className={classes.nested}
                            classes={{ selected: classes.selectedList }}
                          >
                            <ListItemIcon>
                              <CategoryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Kategori" />
                          </ListItem>
                        );
                      }}
                    />


                    <Route
                      path="/brand"
                      children={({ match, history }) => {
                        return (
                          <ListItem
                            button
                            selected={match ? true : false}
                            onClick={() => history.push("/brand")}
                            className={classes.nested}
                            classes={{ selected: classes.selectedList }}
                          >
                            <ListItemIcon>
                              <BrandingWatermarkIcon />
                            </ListItemIcon>
                            <ListItemText primary="Brand" />
                          </ListItem>
                        );
                      }}
                    />


                    <Route
                      path="/Product"
                      children={({ match, history }) => {
                        return (
                          <ListItem
                            button
                            selected={match ? true : false}
                            onClick={() => history.push("/Product")}
                            className={classes.nested}
                            classes={{ selected: classes.selectedList }}
                          >
                            <ListItemIcon>
                              <LocalOfferIcon />
                            </ListItemIcon>
                            <ListItemText primary="Products" />
                          </ListItem>
                        );
                      }}
                    />


                  </List>
                </Collapse>

                <Divider />

                <ListItem
                  button
                  onClick={handleClickReward}
                  className={classes.listItem}
                  classes={{ selected: classes.selectedList }}
                >
                  <ListItemIcon>
                    <LocalFloristIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reward" />
                  {openReward ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openReward} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>


                    <Route
                      path="/reward"
                      children={({ match, history }) => {
                        return (
                          <ListItem
                            button
                            selected={match ? true : false}
                            onClick={() => history.push("/reward")}
                            className={classes.nested}
                            classes={{ selected: classes.selectedList }}
                          >
                            <ListItemIcon>
                              <LocalFloristIcon />
                            </ListItemIcon>
                            <ListItemText primary="Products" />
                          </ListItem>
                        );
                      }}
                    />


                    <Route
                      path="/penukaran"
                      children={({ match, history }) => {
                        return (
                          <ListItem
                            button
                            selected={match ? true : false}
                            onClick={() => history.push("/penukaran")}
                            className={classes.nested}
                            classes={{ selected: classes.selectedList }}
                          >
                            <ListItemIcon>
                              <RedeemIcon />
                            </ListItemIcon>
                            <ListItemText primary="Pengiriman" />
                          </ListItem>
                        );
                      }}
                    />

                  </List>
                </Collapse>

                <Divider />

                <Route
                  path="/promo"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/promo")}
                        className={classes.listItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <LabelIcon />
                        </ListItemIcon>
                        <ListItemText primary="Promo" />
                      </ListItem>
                    );
                  }}
                />

                <Divider />

                <Route
                  path="/notification"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/notification")}
                        className={classes.listItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <PublishIcon />
                        </ListItemIcon>
                        <ListItemText primary="Push Notification" />
                      </ListItem>
                    );
                  }}
                />

                <Divider />

                <Route
                  path="/feedback"
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/feedback")}
                        className={classes.listItem}
                        classes={{ selected: classes.selectedList }}
                      >
                        <ListItemIcon>
                          <FeedbackIcon />
                        </ListItemIcon>
                        <ListItemText primary="Feedback" />
                      </ListItem>
                    );
                  }}
                />

                <Divider />

                <ListItem
                  button
                  onClick={handleClickSetting}
                  className={classes.listItem}
                  classes={{ selected: classes.selectedList }}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                  {openSetting ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSetting} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>


                    <Route
                      path="/settings"
                      children={({ match, history }) => {
                        return (
                          <ListItem
                            button
                            selected={match ? true : false}
                            onClick={() => history.push("/settings")}
                            className={classes.nested}
                            classes={{ selected: classes.selectedList }}
                          >
                            <ListItemIcon>
                              <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Settings" />
                          </ListItem>
                        );
                      }}
                    />

                    <Route
                      path="/banner"
                      children={({ match, history }) => {
                        return (
                          <ListItem
                            button
                            selected={match ? true : false}
                            onClick={() => history.push("/banner")}
                            className={classes.nested}
                            classes={{ selected: classes.selectedList }}
                          >
                            <ListItemIcon>
                              <PanoramaIcon />
                            </ListItemIcon>
                            <ListItemText primary="Banner" />
                          </ListItem>
                        );
                      }}
                    />

                    <Route
                      path="/slider"
                      children={({ match, history }) => {
                        return (
                          <ListItem
                            button
                            selected={match ? true : false}
                            onClick={() => history.push("/slider")}
                            className={classes.nested}
                            classes={{ selected: classes.selectedList }}
                          >
                            <ListItemIcon>
                              <BurstModeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Slider" />
                          </ListItem>
                        );
                      }}
                    />

                  </List>
                </Collapse>

                <Divider />

              </>} */}
            <ListItem
              button
              onClick={handleClickOpen}
              className={classes.listItem}
              selected={openDialog}
            >
              <ListItemIcon>
                <ExitToAppIcon color="error" />
              </ListItemIcon>
              <ListItemText className={classes.liText} primary="Logout" />
            </ListItem>
          </List>
        </Drawer>

        <main
          className={clsx(classes.contentShrink, {
            // [classes.content]: appbar,
          })}
        >
          <Switch>
            {/* {
              form?.role === 'administrator' &&
              <> */}
            <Route exact path="/" component={Home} />
            <Route path="/product" component={Product} />
            <Route path="/tiket" component={Tiket} />
            <Route path="/brand" component={Brand} />
            <Route path="/penonton" component={Penonton} />
            <Route path="/refgroup" component={Refgroup} />

            {/* <Route path="/orders" component={Orders} /> */}
            {/* <Route path="/komisi" component={Komisi} /> */}
            <Route path="/banner" component={Banner} />
            <Route path="/slider" component={Slider} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/promoOngkir" component={PromoOngkir} />
            <Route path="/promoProduct" component={PromoProduct} />
            <Route path="/coverage" component={Coverage} />
            <Route path="/analytics" component={Analytics} />
            <Route path="/settings" component={Settings} />
            <Route path="/integrations" component={Integrations} />
            <Route path="/pencairanRekber" component={PencairanRekber} />
            <Route path="/notification" component={Notification} />
            {/* <Route path="/penukaran" component={Penukaran} /> */}
            <Route path="/superadmin" component={Superadmin} />
            {/* <Route path="/feedback" component={Feedback} /> */}
            {/* </>
            } */}
            {/* {
              form?.role === 'gudang' &&
              <>
                <Route path="/orders" component={Orders} />
                <Route path="/analytics" component={Analytics} />
              </>
            } */}
            {/* {
              form?.role === 'akunting' &&
              <>
                <Route path="/pencairan" component={Pencairan} />
                <Route path="/analytics" component={Analytics} />
              </>
            }
            {
              form?.role === 'cs' &&
              <>
                <Route exact path="/" component={Home} />
                <Route path="/Product" component={Product} />
                <Route path="/categories" component={Categories} />
                <Route path="/brand" component={Brand} />
                <Route path="/orders" component={Orders} />
                {/* <Route path="/komisi" component={Komisi} /> */}
            {/* <Route path="/banner" component={Banner} />
                <Route path="/slider" component={Slider} />
                <Route path="/promo" component={Promo} />
                <Route path="/reward" component={Reward} />
                <Route path="/analytics" component={Analytics} />
                <Route path="/settings" component={Settings} />
                <Route path="/notification" component={PushNotification} /> */}
            {/* <Route path="/pilihProduk" component={PilihProduk} /> */}
            {/* <Route path="/seller" component={Seller} /> */}
            {/* <Route path="/feedback" component={Feedback} /> */}
            {/* </> */}
            {/* } */}
          </Switch>
        </main>
      </div>
      {/* Dialog Box Logout */}

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialog}
      >
        <DialogContent>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
            </Grid>
          </Grid>
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className={classes.centerText}>
              Apakah Anda Yakin?
            </Typography>
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <ButtonGroup fullWidth variant="text">
            <Button onClick={handleClose} color="primary">
              Tidak
            </Button>
            <Button onClick={handleLogout}>Logout</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Private;
