import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import UploadIcon from '@material-ui/icons/CloudUpload';

import { firestore, FieldValue, storage } from '../../../components/FirebaseProvider';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { useData } from '../../../components/DataProvider';


function AddDialog({ dialog: { mode, open, data, id, parent, level }, handleClose }) {


    const classes = useStyles();


    const { enqueueSnackbar } = useSnackbar();

    const { company_id } = useData();

    const [form, setForm] = useState({
        nama: '',
        icon: '',
        products_counter: ''

    });
    console.log(form)
    const [error, setError] = useState({
        nama: '',
        icon: '',
        products_counter: ''
    });
    useEffect(() => {
        const defaultData = {
            nama: '',
            icon: '',
            products_counter: ''
        }

        const defaultError = {
            nama: '',
            icon: '',
            products_counter: ''
        }
        if (mode === 'Tambah') {
            setForm(defaultData)
            setError(defaultError)
        } else if (mode === 'Edit') {
            setForm(data)
            setError(defaultError)
        }
    }, [mode, data])


    const [isSubmitting, setSubmitting] = useState(false);
    const handleChange = e => {
        let value = e.target.value
        if (e.target.type === 'number') {
            value = parseInt(value)
        }
        console.log(e.target.type)



        setForm({
            ...form,
            [e.target.name]: value,
        })


        setError({
            ...error,
            [e.target.name]: ''
        })
    }

    const validate = () => {

        const newError = {};

        if (!form.nama) {
            newError.nama = 'Nama Kategori wajib diisi';
        }
        if (!form.icon) {
            newError.icon = 'Icon Kategori wajib diisi';
        }

        return newError;

    }

    const handleSimpan = async e => {
        e.preventDefault();

        const findErros = validate();
        if (Object.values(findErros).some(err => err !== '')) {
            setError(findErros);
        } else {

            setSubmitting(true);
            try {
                const kategoriCol = firestore.collection(`categories`);


                if (mode === 'Tambah') {

                    await kategoriCol.add({
                        ...form,
                        parent,
                        level,
                        created_at: FieldValue.serverTimestamp(),
                        updated_at: FieldValue.serverTimestamp()
                    })
                } else if (mode === 'Edit') {
                    console.log('here', mode, id);
                    await kategoriCol.doc(id).set({
                        ...form,
                        updated_at: FieldValue.serverTimestamp()
                    }, {
                        merge: true
                    })
                    enqueueSnackbar('Kategori Berhasil Diperbarui', { variant: "success" })

                }


                handleClose();

            } catch (e) {

                setError(e.message)

            }
            setSubmitting(false);
        }
    }
    const handleUploadIcon = async (e) => {
        const file = e.target.files[0];

        if (!['image/svg+xml'].includes(file.type)) {

            setError(error => ({
                ...error,
                icon: `Tipe file tidak didukung: ${file.type}`
            }))
        }
        else if (file.size >= 512000) {
            setError(error => ({
                ...error,
                icon: `Ukuran file terlalu besar > 500KB`
            }))
        } else {

            const reader = new FileReader();

            reader.onabort = () => {

                setError(error => ({
                    ...error,
                    icon: `Proses pembacaan file dibatalkan`
                }))
            }

            reader.onerror = () => {

                setError(error => ({
                    ...error,
                    icon: 'File tidak bisa dibaca'
                }))
            }

            reader.onload = async () => {
                setError(error => ({
                    ...error,
                    icon: ''
                }))
                setSubmitting(true);
                try {
                    const categoriesStorageRef = storage.ref(`categories`);

                    const iconExt = file.name.substring(file.name.lastIndexOf('.'));

                    const iconRef = categoriesStorageRef.child(`${form.nama}/icon_${Date.now()}${iconExt}`);

                    const iconSnapshot = await iconRef.putString(reader.result, 'data_url');

                    const iconUrl = await iconSnapshot.ref.getDownloadURL();

                    setForm(currentForm => ({
                        ...currentForm,
                        icon: iconUrl
                    }));

                    // setSomethingChange(true);
                } catch (e) {
                    setError(error => ({
                        ...error,
                        icon: e.message
                    }))


                }

                setSubmitting(false);

            }

            reader.readAsDataURL(file);

        }
    }
    return <Dialog
        disableBackdropClick={isSubmitting}
        disableEscapeKeyDown={isSubmitting}
        open={open}
        onClose={handleClose}
    >
        {mode === "Edit" ?
            <DialogTitle>Edit Kategori</DialogTitle> :
            <DialogTitle>Buat Kategori Baru</DialogTitle>}
        <DialogContent dividers>

            <TextField
                id="nama"
                name="nama"
                label="Nama Kategori"
                fullWidth
                value={form.nama}
                onChange={handleChange}
                helperText={error.nama}
                error={error.nama ? true : false}
                disabled={isSubmitting}
            />

            <Grid className={classes.addBox} item xs={12}>
                <InputLabel className={classes.iconLabel}>icon</InputLabel>
                <div className={classes.uploadIconCateg}>

                    {form.icon &&
                        <img src={form.icon} className={classes.previewIconCateg} alt="" />}
                    <input
                        className={classes.hideInputFile}
                        type="file"
                        name="icon"
                        id="upload-icon-categories"
                        accept="image/svg+xml"
                        onChange={handleUploadIcon}
                    />
                    <label htmlFor="upload-icon-categories">
                        <Button
                            disabled={isSubmitting}
                            name="icon"

                            variant="outlined"
                            component="span"
                        >Upload<UploadIcon className={classes.iconRight} /></Button>
                    </label>

                    {error.icon &&
                        <Typography color="error">
                            {error.icon}
                        </Typography>}

                </div>
            </Grid>
            <TextField
                id="products_counter"
                name="products_counter"
                label="Products Counter "
                type='number'
                fullWidth
                value={form.products_counter}
                onChange={handleChange}
                helperText={error.products_counter}
                error={error.products_counter ? true : false}
                disabled={isSubmitting}
            />




        </DialogContent>
        <DialogActions>
            <Button
                disabled={isSubmitting}
                onClick={handleClose}
            >Batal</Button>
            <Button
                disabled={isSubmitting}
                onClick={handleSimpan} color="primary">Simpan</Button>
        </DialogActions>
    </Dialog>

}

AddDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default AddDialog;