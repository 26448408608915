import React, { useEffect, useState } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { firestore } from "../../../components/FirebaseProvider";
import { useDocument } from "react-firebase-hooks/firestore";

import useStyles from "./styles";
import Vdocipher from "./vdocipher";
import Woowandroid from "./woowandroid";
import Alamat from "./alamat";
// import Shipping from './shipping';
import General from "./general";
import Subdomain from "./subdomain";
import { useData } from "../../../components/DataProvider";
import Xendit from "./xendit";

function Settings() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { company_id } = useData();

  //console.log(location)

  const [tabValue, setTabValue] = useState(
    qs.parse(location.search)?.tab ?? "xendit"
  );
  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    history.push(`?tab=${tabValue}`);
  }, [tabValue, history]);

  const [tabs] = useState([
    // {
    //   label: "General",
    //   value: "general",
    // },
    {
      label: "xendit",
      value: "xendit",
    },
    {
      label: "vdocipher",
      value: "vdocipher",
    },
    {
      label: "woowandroid",
      value: "woowandroid",
    }
  ]);

  return (
    <>
      <Container maxWidth="lg">
        <Paper className={classes.paperWrap}>
          <Typography className={classes.pageTitle} component="h1" variant="h6">
            Settings
          </Typography>
          <Tabs
            indicatorColor="primary"
            className={classes.tabWrap}
            textColor="primary"
            value={tabValue || "xendit"}
            onChange={handleChangeTab}
            variant="fullWidth"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <div className={classes.content}>
            {/* <Alamat uid={company_id} value={tabValue} use_available={form.use_available} shipLoading={shipLoading} /> */}
            {/* <Komisi value={tabValue} />
                        <PickupAgent value={tabValue} formAgent={form} />
                        <RateidList value={tabValue} /> */}
            {/* <Subdomain uid={company_id} value={tabValue} /> */}
            <Vdocipher uid={company_id} value={tabValue} />
            {/* <General uid={company_id} value={tabValue} /> */}
            <Xendit uid={company_id} value={tabValue} />
            <Woowandroid uid={company_id} value={tabValue} />
            {/* <Shipping uid={company_id} value={tabValue} settLoading={shipLoading} form={form} handleChange={handleChange} formShip={formShip} handleSubmit={handleSubmit} isSubmitting={isSubmitting} error={error} /> */}
          </div>
        </Paper>
      </Container>
    </>
  );
}

export default Settings;
