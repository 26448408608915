import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link, useHistory, useParams } from "react-router-dom";

// components
import PageLoading from "../../../components/loading/pageLoading";
import useStyles from "./styles";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import {
    firestore,
    storage,
    FieldValue,
} from "../../../components/FirebaseProvider";
import { unixToDate } from "../../../utils/dateFormatter";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TabPanel from "../../../components/TabPanel";

// material ui icons
import InputLabel from '@material-ui/core/InputLabel';
import UploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import { useSnackbar } from "notistack";
import { useData } from "../../../components/DataProvider";

function EditProduct({ value }) {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const params = useParams();

    const history = useHistory();

    const { treeDataKategori, brandList } = useData();
    console.log(treeDataKategori)
    const [isSubmitting, setSubmitting] = useState(false);

    // komisi


    // Product
    const { company_id } = useData();

    const prodDoc = firestore.doc(`produk/${params.productId}`);

    const [prodSnapshot, prodLoading] = useDocument(prodDoc);

    const [prodForm, setProdForm] = useState();
    const [errorProd, setErrorProd] = useState({});

    useEffect(() => {
        if (prodSnapshot) {
            const data = prodSnapshot.data();
            setProdForm({
                ...data,
            });
        }
    }, [prodSnapshot]);

    const handleChangeProd = (e) => {
        setProdForm({
            ...prodForm,
            [e.target.name]: e.target.value,
        });

        setErrorProd({
            ...errorProd,
            [e.target.name]: "",
        });
    };

    const validateProd = async () => {
        const newError = { ...errorProd };
        const {
            nama,
            deskripsi_singkat,
            deskripsi_lengkap,
            kategori1,
            kategori2,
            kategori3,
            brand,
            variant1_label,
            variant2_label,
            thumbnail,
            default_variant,
        } = prodForm;

        if (!nama) {
            newError.nama = "Nama harus diisi";
        }

        // if (!brand) {
        //     newError.brand = "Brand harus diisi";
        // }
        // if (!thumbnail) {
        //     newError.thumbnail = "thumbnail harus diisi";
        // }

        // if (!deskripsi_singkat) {
        //     newError.deskripsi_singkat = "Deskripsi harus diisi";
        // }

        // if (!deskripsi_lengkap) {
        //     newError.deskripsi_lengkap = "Deskripsi harus diisi";
        // }
        // if (!kategori1) {
        //     newError.kategori1 = "Kategori1 harus diisi";
        // }
        // if (!kategori2) {
        //     newError.kategori2 = "Kategori2 harus diisi";
        // }
        // if (!kategori3) {
        //     newError.kategori3 = "Kategori3 harus diisi";
        // }
        // if (!variant1_label) {
        //     newError.variant1_label = "Label variant 1 harus diisi";
        // }
        // if (!variant2_label) {
        //     newError.variant2_label = "Label variant 2harus diisi";
        // }
        // if (!default_variant) {
        //     newError.default_variant = "Default variant 2harus diisi";
        // }

        return newError;
    };

    const handleSubmitProd = async (e) => {
        e.preventDefault();
        const findError = await validateProd();

        if (Object.values(findError).some((m) => m !== "")) {
            setErrorProd(findError);
        } else {
            setSubmitting(true);
            try {
                const updProd = {
                    ...prodForm,
                    updated_at: FieldValue.serverTimestamp(),
                    product_id: prodForm.product_id || prodDoc.id,
                    kategori1: {
                        nama: prodForm.kategori1.nama,
                        id: prodForm.kategori1.id,
                    },
                    kategori2: {
                        nama: prodForm.kategori2.nama,
                        id: prodForm.kategori2.id,
                    },
                    kategori3: {
                        nama: prodForm.kategori3.nama,
                        id: prodForm.kategori3.id,
                    },
                    brand: { nama: prodForm.brand.nama, id: prodForm.brand.id },
                    sorting_number: parseInt(prodForm.sorting_number),
                };

                await prodDoc.set(updProd, { merge: true });
                history.replace("/Product");

                enqueueSnackbar("Produk Berhasil Diperbarui", { variant: "success" });
            } catch (e) {
                const newError = {};

                newError.title = e.message;

                setErrorProd(newError);
            }

            setSubmitting(false);
        }
    };

    const handleUploadProduct = async (e) => {
        const file = e.target.files[0];

        if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
            setErrorProd((errorProd) => ({
                ...errorProd,
                thumbnail: `Tipe file tidak didukung: ${file.type}`,
            }));
        } else if (file.size >= 512000) {
            setErrorProd((errorProd) => ({
                ...errorProd,
                thumbnail: `Ukuran file terlalu besar > 500KB`,
            }));
        } else {
            const reader = new FileReader();

            reader.onabort = () => {
                setErrorProd((errorProd) => ({
                    ...errorProd,
                    thumbnail: `Proses pembacaan file dibatalkan`,
                }));
            };

            reader.onerror = () => {
                setErrorProd((errorProd) => ({
                    ...errorProd,
                    thumbnail: "File tidak bisa dibaca",
                }));
            };

            reader.onload = async () => {
                setErrorProd((errorProd) => ({
                    ...errorProd,
                    thumbnail: "",
                }));
                setSubmitting(true);
                try {
                    const productsStorageRef = storage.ref(`products`);

                    const thumbnailExt = file.name.substring(file.name.lastIndexOf("."));

                    const thumbnailRef = productsStorageRef.child(
                        `${params.productId}/thumbnail_${Date.now()}${thumbnailExt}`
                    );

                    const thumbnailSnapshot = await thumbnailRef.putString(
                        reader.result,
                        "data_url"
                    );

                    const thumbnailUrl = await thumbnailSnapshot.ref.getDownloadURL();

                    setProdForm((currentForm) => ({
                        ...currentForm,
                        thumbnail: [...(prodForm?.thumbnail ?? []), thumbnailUrl],
                    }));
                    await prodDoc.set({
                        ...prodForm,
                        thumbnail: [...(prodForm?.thumbnail ?? []), thumbnailUrl],
                        updated_at: FieldValue.serverTimestamp(),
                    }, { merge: true });

                    // setSomethingChange(true);
                } catch (e) {
                    setErrorProd((errorProd) => ({
                        ...errorProd,
                        thumbnail: e.message,
                    }));
                }

                setSubmitting(false);
            };

            reader.readAsDataURL(file);
        }
    };



    // Variant

    const varCol = firestore.collection(`products/${params.productId}/variants`);

    const [varSnapshot, varLoading] = useCollection(varCol);

    const [varCollection, setVarCollection] = useState();

    useEffect(() => {
        if (varSnapshot && !varSnapshot.empty) {
            setVarCollection(
                varSnapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data(),
                    };
                })
            );
        }
    }, [varSnapshot]);

    const handleDelete = async (e) => {
        if (window.confirm("Anda yakin ingin menghapus produk ini?")) {
            // const varThumbnail = varCollection?.thumbnail
            // if (varThumbnail) {
            // 	const varThumbnailUrl = varThumbnail.map?.(thumbna => {
            // 		return thumbna
            // 	});;
            // 	await Promise.all(storage.refFromURL(varThumbnailUrl).delete());
            // }
            await Promise.all(
                varCollection?.thumbnail?.map?.((thumbna) => {
                    return storage.refFromURL(thumbna).delete();
                }) ?? []
            );

            // await varCol?.delete();

            await Promise.all(
                prodForm?.thumbnail?.map?.((thumbn) => {
                    return storage.refFromURL(thumbn).delete();
                }) ?? []
            );

            await prodDoc.delete();

            // const sectionCol = firestore.collection(`kursus/${params.kursusId}/section`);
            history.replace("/Product");
        }
    };
    // const handleDeleteThumb = (thumb) => async (e) => {
    //     if (window.confirm("Anda yakin ingin menghapus thumbnail ini?")) {
    //         const thumbnailURL = thumb;
    //         if (thumbnailURL) {
    //             await storage.refFromURL(thumbnailURL).delete();
    //             await prodDoc.update({
    //                 thumbnail: FieldValue.arrayRemove(thumb),
    //             });
    //             setProdForm((prodForm) => ({
    //                 ...prodForm,
    //                 thumbnail: prodForm.thumbnail.filter((m) => m !== thumb),
    //             }));
    //         }
    //     }
    // };

    // const kategoriLevel2 = prodForm?.kategori1?.sub ?? [];
    // const kategoriLevel3 = prodForm?.kategori2?.sub ?? [];

    // Page Loading
    if (prodLoading || varLoading) {
        return <PageLoading />;
    }
    return (
        <>
            <TabPanel value={value} index="editProduct">
                <div className={classes.root}>
                    <Grid container className={classes.gridCon}>
                        {/* Edit Produk */}
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className={classes.pageTitle}>
                                        <Button
                                            className={classes.backBtn}
                                            variant="outlined"
                                            component={Link}
                                            to={`/Product`}
                                        >
                                            <ArrowBackIcon />
                                        </Button>
                                        <Typography variant="h6">
                                            Edit Produk {prodForm.nama} -{" "}
                                            {prodForm.product_id || prodDoc.id}{" "}
                                        </Typography>
                                    </div>
                                    <div className={classes.dateInfo}>
                                        <Typography variant="subtitle2">
                                            {prodForm.created_at !== null ? (
                                                <span>
                                                    {" "}
                                                    Dibuat pada :{" "}
                                                    {unixToDate(prodForm.created_at?.toMillis())}{" "}
                                                </span>
                                            ) : (
                                                <span> Dibuat pada : - </span>
                                            )}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {prodForm.updated_at !== null ? (
                                                <span>
                                                    {" "}
                                                    Perubahan terakhir :{" "}
                                                    {unixToDate(prodForm.updated_at?.toMillis())}{" "}
                                                </span>
                                            ) : (
                                                <span> Perubahan terakhir : - </span>
                                            )}
                                        </Typography>
                                    </div>
                                </Grid>

                                {/* <Grid item xs={2}>
							<img src={prodForm.thumbnail} alt="" width="100%" />
						</Grid> */}
                                {/* <Grid item xs={12}>
                                    <InputLabel className={classes.thumbnailLabel}>
                                        Thumbnail
                                    </InputLabel>
                                    <div className={classes.uploadFotoKursus}>
                                        <div className={classes.previewFotoVar}>
                                            {prodForm.thumbnail?.map((thumb) => {
                                                const position = thumb?.lastIndexOf?.(".");
                                                const thumbnail300 =
                                                    thumb?.substring(0, position) +
                                                    "_300x300" +
                                                    thumb?.substring(position, position?.length);
                                                // console.log(thumb);
                                                return (
                                                    <>
                                                        <div className={classes.thumbWrap}>
                                                            <img
                                                                key={thumb}
                                                                src={thumbnail300}
                                                                width="200"
                                                                height="auto"
                                                                alt=""
                                                            />
                                                            <IconButton className={classes.closeBtn}>
                                                                <HighlightOffIcon
                                                                    onClick={handleDeleteThumb(thumb)}
                                                                />
                                                            </IconButton>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                        <input
                                            className={classes.hideInputFile}
                                            type="file"
                                            name="thumbnail"
                                            id="upload-thumbnail-products"
                                            accept="image/jpeg,image/png,image/jpg"
                                            onChange={handleUploadProduct}
                                        />
                                        <label htmlFor="upload-thumbnail-products">
                                            <Button
                                                disabled={isSubmitting}
                                                name="thumbnail"
                                                className={classes.uploadBtn}
                                                variant="outlined"
                                                component="span"
                                            >
                                                Upload
                                                <UploadIcon className={classes.iconRight} />
                                            </Button>
                                        </label>

                                        {errorProd.thumbnail && (
                                            <Typography color="error">
                                                {errorProd.thumbnail}
                                            </Typography>
                                        )}
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.sort}>
                                        <Grid item xs={3}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                disabled={isSubmitting}
                                                id="sort"
                                                name="sorting_number"
                                                label="sort"
                                                value={prodForm.sorting_number || ""}
                                                onChange={handleChangeProd}
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </div>
                                </Grid> */}

                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} lg={4}>
                                            <TextField
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                                id="nama"
                                                name="nama"
                                                label="Nama Produk"
                                                value={prodForm.nama_produk || ""}
                                                onChange={handleChangeProd}
                                                error={errorProd.nama_produk ? true : false}
                                                helperText={errorProd.nama_produk}
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item xs={6} lg={4}>
                                            <TextField
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                                id="product_id"
                                                name="product_id"
                                                label="Produk Id"
                                                value={prodForm.produk_id || prodDoc.id}
                                                onChange={handleChangeProd}
                                                error={errorProd.produk_id ? true : false}
                                                helperText={errorProd.produk_id}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                                id="harga_produk"
                                                name="harga_produk"
                                                label="Harga Tiket"
                                                value={prodForm.harga_produk || ""}
                                                onChange={handleChangeProd}
                                                error={errorProd.harga_produk ? true : false}
                                                helperText={errorProd.harga_produk}
                                                fullWidth
                                                multiline
                                                variant="outlined"
                                            />
                                        </Grid>
                                    {/*
                                        <Grid item xs={12}>
                                            <TextField
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                                id="deskripsi_lengkap"
                                                name="deskripsi_lengkap"
                                                label="Deskripsi Lengkap"
                                                value={prodForm.deskripsi_lengkap || ""}
                                                onChange={handleChangeProd}
                                                error={errorProd.deskripsi_lengkap ? true : false}
                                                helperText={errorProd.deskripsi_lengkap}
                                                fullWidth
                                                multiline
                                                variant="outlined"
                                            />
                                        </Grid>

                                         <Grid item xs={4}>
                                            <Autocomplete
                                                className={classes.siswa}
                                                id="kategori1"
                                                name="kategori1"
                                                margin="normal"
                                                required
                                                value={prodForm.kategori1}
                                                onChange={(event, value) => {
                                                    handleChangeProd({
                                                        target: { name: "kategori1", value },
                                                    });
                                                }}
                                                disabled={isSubmitting}
                                                options={treeDataKategori?.map?.((level1) => {
                                                    return {
                                                        id: level1.id,
                                                        nama: level1.nama,
                                                        sub: level1.sub,
                                                    };
                                                })}
                                                getOptionLabel={(option) => option.nama}
                                                renderInput={(params) => (
                                                    <TextField
                                                        helperText={errorProd.kategori1}
                                                        error={errorProd.kategori1 ? true : false}
                                                        {...params}
                                                        label="Kategori 1"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                className={classes.siswa}
                                                id="kategori2"
                                                name="kategori2"
                                                margin="normal"
                                                required
                                                value={prodForm.kategori2}
                                                onChange={(event, value) => {
                                                    handleChangeProd({
                                                        target: { name: "kategori2", value },
                                                    });
                                                }}
                                                disabled={isSubmitting}
                                                options={kategoriLevel2?.map?.((level2) => {
                                                    return {
                                                        id: level2.id,
                                                        nama: level2.nama,
                                                        sub: level2.sub.map((sub2) => {
                                                            return {
                                                                id: sub2.id,
                                                                nama: sub2.nama,
                                                            };
                                                        }),
                                                    };
                                                })}
                                                getOptionLabel={(option) => option.nama}
                                                renderInput={(params) => (
                                                    <TextField
                                                        helperText={errorProd.kategori2}
                                                        error={errorProd.kategori2 ? true : false}
                                                        {...params}
                                                        label="Kategori 2"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                className={classes.siswa}
                                                id="kategori3"
                                                name="kategori3"
                                                margin="normal"
                                                required
                                                value={prodForm.kategori3}
                                                onChange={(event, value) => {
                                                    handleChangeProd({
                                                        target: { name: "kategori3", value },
                                                    });
                                                }}
                                                disabled={isSubmitting}
                                                options={kategoriLevel3?.map?.((level3) => {
                                                    return {
                                                        id: level3.id,
                                                        nama: level3.nama,
                                                    };
                                                })}
                                                getOptionLabel={(option) => option.nama}
                                                renderInput={(params) => (
                                                    <TextField
                                                        helperText={errorProd.kategori3}
                                                        error={errorProd.kategori3 ? true : false}
                                                        {...params}
                                                        label="Kategori 3"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid> */}
                                        {/* <Grid item xs={3}>
                                            <Autocomplete
                                                className={classes.siswa}
                                                id="brand"
                                                name="brand"
                                                margin="normal"
                                                required
                                                value={prodForm.brand}
                                                onChange={(event, value) => {
                                                    handleChangeProd({
                                                        target: { name: "brand", value },
                                                    });
                                                }}
                                                disabled={isSubmitting}
                                                options={brandList.map((brand) => {
                                                    return {
                                                        id: brand.id,
                                                        nama: brand.nama,
                                                    };
                                                })}
                                                getOptionLabel={(option) => option.nama}
                                                renderInput={(params) => (
                                                    <TextField
                                                        helperText={errorProd.brand}
                                                        error={errorProd.brand ? true : false}
                                                        {...params}
                                                        label="Brand"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="default_variant"
                                                name="default_variant"
                                                margin="normal"
                                                required
                                                value={prodForm.default_variant}
                                                onChange={(event, value) => {
                                                    handleChangeProd({
                                                        target: { name: "default_variant", value },
                                                    });
                                                }}
                                                disabled={isSubmitting}
                                                options={
                                                    varCollection?.map?.((default_variant) => {
                                                        return {
                                                            id: default_variant?.id,
                                                            sku: default_variant?.sku,
                                                        };
                                                    }) ?? []
                                                }
                                                getOptionLabel={(option) => option.sku}
                                                renderInput={(params) => (
                                                    <TextField
                                                        helperText={errorProd.default_variant}
                                                        error={errorProd.default_variant ? true : false}
                                                        {...params}
                                                        label="Default Variant"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid> */}

                                        <Grid item xs={3}>
                                            <TextField
                                                variant="outlined"
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                                id="komisi_affiliate"
                                                name="komisi_affiliate"
                                                label="Komisi Affiliate"
                                                value={prodForm.komisi_affiliate || ""}
                                                onChange={handleChangeProd}
                                                error={errorProd.komisi_affiliate ? true : false}
                                                helperText={errorProd.komisi_affiliate}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                variant="outlined"
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                                id="expirydays"
                                                name="expirydays"
                                                label="expirydays"
                                                value={prodForm.expirydays || ""}
                                                onChange={handleChangeProd}
                                                error={errorProd.expirydays ? true : false}
                                                helperText={errorProd.expirydays}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                variant="outlined"
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                                id="launchdate"
                                                name="launchdate"
                                                label="Tgl launching"
                                                value={prodForm.launchdate || ""}
                                                onChange={handleChangeProd}
                                                error={errorProd.launchdate ? true : false}
                                                helperText={errorProd.launchdate}
                                                fullWidth
                                            />
                                        </Grid>
                                        {/* <Grid item xs={6} lg={4}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={isSubmitting}
                                                        onChange={((e) => {
                                                            handleChangeProd({
                                                                target: {
                                                                    name: e.target.name,
                                                                    value: e.target.checked,
                                                                }
                                                            })
                                                        })}
                                                        checked={prodForm.sembunyikan_produk}
                                                        name="sembunyikan_produk"
                                                        color="default"
                                                    />
                                                }
                                                label="Jangan Tampilkan Ke Publik"
                                            />
                                        </Grid> */}
                                        <Grid item xs={6} lg={1}>
                                            <Button
                                                disabled={isSubmitting}
                                                onClick={handleSubmitProd}
                                                size="large"
                                                color="secondary"
                                                variant="contained"
                                                fullWidth
                                            >
                                                <SaveIcon />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} lg={1}>
                                            <Button
                                                disabled={isSubmitting}
                                                size="large"
                                                color="primary"
                                                variant="contained"
                                                onClick={handleDelete}
                                                fullWidth
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </TabPanel>
        </>
    );
}

export default EditProduct;
