import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFound from "./pages/404";
import Login from "./pages/login";
import Private from "./pages/private";
import PrivateRoute from "./components/routing/PrivateRoute";
import FirebaseProvider from "./components/FirebaseProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";
import DashboardUIProvider from "./components/DashboardUIProvider";

import ThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from "./config/theme";
import SuperAdminValidate from "./components/routing/ValdateRoute";
import Validation from "./pages/validate";

function App() {
  return (
    <SnackbarProvider maxSnack={2} autoHideDuration={3000}>
      <FirebaseProvider>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <DashboardUIProvider>
            <Router>
              <Switch>
                <SuperAdminValidate path="/validate" component={Validation} />
                <PrivateRoute exact path="/" component={Private} />
                {/* <PrivateRoute path="/companies" component={Private} /> */}
                <PrivateRoute path="/product" component={Private} />
                {/* <PrivateRoute path="/coverage" component={Private} />
                <PrivateRoute path="/promoProduct" component={Private} />
                <PrivateRoute path="/promoOngkir" component={Private} />
                <PrivateRoute path="/categories" component={Private} />
                <PrivateRoute path="/brand" component={Private} /> */}
                <PrivateRoute path="/tiket" component={Private} />

                {/* <PrivateRoute path="/orders" component={Private} /> */}
                {/* <PrivateRoute path="/komisi" component={Private} /> */}
                {/* <PrivateRoute path="/banner" component={Private} />
                <PrivateRoute path="/slider" component={Private} />
                <PrivateRoute path="/pricing" component={Private} />
                <PrivateRoute path="/analytics" component={Private} /> */}
                <PrivateRoute path="/settings" component={Private} />
                {/* <PrivateRoute path="/integrations" component={Private} /> */}
                <PrivateRoute path="/pencairanRekber" component={Private} />
                {/* <PrivateRoute path="/notification" component={Private} /> */}
                <PrivateRoute path="/superadmin" component={Private} />
                {/* <PrivateRoute path="/feedback" component={Private} /> */}
                <PrivateRoute path="/penonton" component={Private} />
                <PrivateRoute path="/refgroup" component={Private} />

                <Route path="/login" component={Login} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </DashboardUIProvider>
        </ThemeProvider>
      </FirebaseProvider>
    </SnackbarProvider>
  );
}

export default App;
