import React from 'react';


const DashboardUIContext = React.createContext();



// create a hook to access this context
export function useDashboardUI() {

    return React.useContext(DashboardUIContext);
}


export default function DashboardUIProvider(props) {

    const [appbar, setAppbar] = React.useState(true);


    const toggleAppBar = React.useCallback(() => {

        setAppbar(appbar => !appbar)
    }, [])

    return <DashboardUIContext.Provider value={{ toggleAppBar, appbar }}>{props.children}</DashboardUIContext.Provider>
}