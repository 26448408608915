/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";
import "react-datasheet/lib/react-datasheet.css";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useData } from "../../../components/DataProvider";
import { useSnackbar } from "notistack";
import { DataGrid } from "@material-ui/data-grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

function PricingProcedure(props) {
  const [opendialog, setOpendialog] = useState({
    mode: "Tambah",
    open: false,
  });

  const [inputForm, setInputform] = useState({
    procedure: "",
    index: 0,
    step: 10,
    ctname: "",
  });

  const handleDialogClose = () => {
    setOpendialog({ ...opendialog, open: false });
    setInputform({ ...inputForm, key_value: [] });
  };

  const handleDialogEditCtStep = () => {
    let newArray = [];

    pricingProcedureValue.data().procedures.map((ct, index) => {
      if (inputForm.id === index) {
        newArray.push({
          step: inputForm.step,
          condition_type_name: inputForm.ctname,
        });
      } else {
        newArray.push({
          step: ct.step,
          condition_type_name: ct.condition_type_name,
        });
      }
    });

    pricingProcedures.set(
      {
        procedures: newArray,
      },
      { merge: true }
    );

    setOpendialog({ ...opendialog, open: false });
  };

  const handleDialogAddCtStep = () => {
    let newArray = [];

    //menambahkan existing ct
    pricingProcedureValue.data().procedures.map((ct, index) => {
      newArray.push({
        step: ct.step,
        condition_type_name: ct.condition_type_name,
      });
    });

    //menambahkan ct step baru
    newArray.push({
      step: inputForm.step,
      condition_type_name: inputForm.ctname,
    });

    pricingProcedures.set(
      {
        procedures: newArray,
      },
      { merge: true }
    );
    setOpendialog({ ...opendialog, open: false });
  };

  const handleDeleteCtStep = async (index) => {
    let newArray = [];
    newArray = pricingProcedureValue
      .data()
      .procedures.filter((ct, i) => i !== index);

    pricingProcedures.set(
      {
        procedures: newArray,
      },
      { merge: true }
    );
  };

  const { company_id } = useData();
  const { enqueueSnackbar } = useSnackbar();

  const pricingProcedures = firestore.doc(
    `pricing_procedures/${props.name}`
  );

  const [
    pricingProcedureValue,
    pricingProcedureLoading,
    pricingProcedureError,
  ] = useDocument(pricingProcedures);

  const pricingConditions = firestore.collection(
    `pricing_conditions/`
  );

  const [pc, setPc] = useState([]);
  const [
    pricingConditionsList
  ] = useCollection(pricingConditions);

  useEffect(() => {
    let pcList = [];
    if (pricingConditionsList) {
      pricingConditionsList.docs.map((doc, index) => {
        pcList.push(doc.id);
      });
      setPc(pcList);
    }
  }, [pricingConditionsList]);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    //membaca pricing procedure document
    if (pricingProcedureValue) {
      const newGrid = [];

      pricingProcedureValue.data()?.procedures.map((i, index) => {
        newGrid.push({
          id: index,
          step: i.step,
          ctname: i.condition_type_name,
        });
      });
      setRows(newGrid);
    }
  }, [pricingProcedureValue]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      sortable: false,
    },
    {
      field: "step",
      headerName: "Step",
      width: 80,
      sortable: false,
    },
    {
      field: "ctname",
      headerName: "Condition Type Name",
      width: 400,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <EditIcon
              onClick={() => {
                setInputform({
                  procedure: props.name,
                  id: params.row?.id,
                  step: params.row?.step,
                  ctname: params.row?.ctname,
                });
                setOpendialog({
                  ...opendialog,
                  mode: "Edit",
                  open: true,
                });
              }}
            />
            <DeleteIcon
              onClick={async () => {
                if (
                  window.confirm(
                    `Apakah Anda Yakin Ingin menghapus Pricing Procedure ${props.name} - step ${params.row?.step} ?`
                  )
                ) {
                  await handleDeleteCtStep(params.row.id);
                  enqueueSnackbar(`Step ${params.row.step} berhasil dihapus`, {
                    variant: "success",
                  });
                }
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <h4>Pricing Procedure - {props.name}</h4>
      <p>
        {pricingProcedureError && (
          <strong>Error: {JSON.stringify(pricingProcedureError)}</strong>
        )}
        {pricingProcedureLoading && <span>Document: Loading...</span>}
      </p>
      {pricingProcedureValue && (
        <React.Fragment key={props.name}>
          <div style={{ height: 400, width: 700 }}>
            <Button
              onClick={() =>
                setOpendialog({
                  ...opendialog,
                  mode: "Add",
                  open: true,
                })
              }
            >
              Tambah Step
            </Button>
            <DataGrid
              rows={rows}
              columns={columns}
              disableColumnFilter
              disableColumnMenu={true}
              rowsPerPageOptions={[]}
              disableSelectionOnClick
            />
          </div>
        </React.Fragment>
      )}

      <Dialog
        open={opendialog.open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {opendialog.mode === "Edit" ? "Edit" : "Add"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pricing Procedure - {inputForm.procedure}
          </DialogContentText>

          <TextField
            margin="dense"
            id="step"
            value={inputForm.step}
            onChange={(e) =>
              setInputform({ ...inputForm, step: parseInt(e.target.value) })
            }
            label="Step"
            type="number"
            fullWidth
          />

          <InputLabel id="demo-simple-select-label">
            Condition Type Name
          </InputLabel>
          <Select
            labelId="ctname"
            id="ctname"
            value={inputForm.ctname}
            onChange={(e) =>
              setInputform({ ...inputForm, ctname: e.target.value })
            }
          >
            {pc.map((i) => {
              return <MenuItem value={i}>{i}</MenuItem>;
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          {opendialog.mode === "Edit" ? (
            <Button onClick={handleDialogEditCtStep} color="primary">
              Edit
            </Button>
          ) : (
            <Button onClick={handleDialogAddCtStep} color="primary">
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PricingProcedure;
