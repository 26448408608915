
import React from 'react';
import { useDashboardUI } from '../../../components/DashboardUIProvider';
import AnalyticsEmbed from '../../../config/analyticsEmbed';
import useStyles from './styles';

function Analytics() {
    const classes = useStyles();
    const { toggleAppBar } = useDashboardUI();

    React.useEffect(() => {
        toggleAppBar();
        return () => {
            toggleAppBar();
        }
    }, [toggleAppBar])
    return <>
        <div className={classes.pageWrap}>
            <iframe
                width="100%"
                frameBorder="0"
                allowFullScreen
                title="Analytics"
                className={classes.iframe}
                src={AnalyticsEmbed}
            >
                Analytics
            </iframe>
        </div>
    </>
}

export default Analytics;