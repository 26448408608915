import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import useStyles from "./styles";
import Sender from "./sender";
import Payment from "./payment";
import Automation from "./automation";
import Shipping from "./shipping";
import Autoresponder from "./autoresponder";

function Integrations() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [tabValue, setTabValue] = useState(
    qs.parse(location.search)?.tab ?? "shipping"
  );
  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    history.push(`?tab=${tabValue}`);
  }, [tabValue, history]);

  const [tabs] = useState([
    {
      label: "Shipping",
      value: "shipping",
    },
    {
      label: "Sender",
      value: "sender",
    },
    {
      label: "Payment",
      value: "payment",
    },
    {
      label: "Autoresponder",
      value: "autoresponder",
    },
    {
      label: "Automation",
      value: "automation",
    },
  ]);

  return (
    <>
      <Container maxWidth="lg">
        <Paper className={classes.paperWrap}>
            <Typography
              className={classes.pageTitle}
              component="h1"
              variant="h6"
            >
              Integrations
            </Typography>
            <Tabs
              indicatorColor="primary"
              className={classes.tabWrap}
              textColor="primary"
              value={tabValue || "shipping"}
              onChange={handleChangeTab}
              variant="fullWidth"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <div className={classes.content}>
              <Shipping value={tabValue} />
              <Sender value={tabValue} />
              <Payment value={tabValue} />
              <Autoresponder value={tabValue} />
              <Automation value={tabValue} />
            </div>
        </Paper>
      </Container>
    </>
  );
}

export default Integrations;
