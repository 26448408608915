import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "react-datasheet/lib/react-datasheet.css";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import PricingProceduresList from "./pricingProceduresList";
import PricingConditionsList from "./PricingConditionsList";

function Pricing() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [tabValue, setTabValue] = useState(
    qs.parse(location.search)?.tab ?? "procedures"
  );
  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    history.push(`?tab=${tabValue}`);
  }, [tabValue, history]);

  const [tabs] = useState([
    {
      label: "Pricing Procedure",
      value: "procedures",
    },
    {
      label: "Pricing Conditions",
      value: "conditions",
    },
  ]);

  return (
    <>
      <Container maxWidth="lg">
        <Paper className={classes.paperWrap}>
          <div className={classes.pageWrap}>
            <Typography
              className={classes.pageTitle}
              component="h1"
              variant="h6"
            >
              Pricing
            </Typography>
            <Tabs
              indicatorColor="primary"
              className={classes.tabWrap}
              textColor="primary"
              value={tabValue || "procedures"}
              onChange={handleChangeTab}
              variant="fullWidth"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <div className={classes.content}>
              <TabPanel value={tabValue} index="procedures">
                <PricingProceduresList />
              </TabPanel>
              <TabPanel value={tabValue} index="conditions">
                <PricingConditionsList />
              </TabPanel>
            </div>
          </div>
        </Paper>
      </Container>
    </>
  );
}

export default Pricing;
