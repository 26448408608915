import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Fab from "@material-ui/core/Fab";
import MaterialTable from "material-table";
import useStyles from "./styles";
import { useCollection } from "react-firebase-hooks/firestore";
import PageLoading from "../../../components/loading/pageLoading";
import { unixToDate } from "../../../utils/dateFormatter";
import { useSnackbar } from "notistack";
import tableIcons from "../../../config/table-icons";
import { firestore } from "../../../components/FirebaseProvider";
// import ExcelPromoProduct from "./addViaExcel.js";
import AddPromoProduct from "./add";
import { useData } from "../../../components/DataProvider";
import Button from "@material-ui/core/Button";
import UploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import ExcelPromoProduct from "./addViaExcel";

function PromoProduct() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [dialog, setDialog] = useState({
    mode: "Tambah",
    open: false,
    data: {},
    uid: {},
  });

  // const komisiDoc = firestore.doc(`settings/komisi`);

  // const [komisiSnapshot, komisiLoading] = useDocument(komisiDoc);

  // const [komisiForm, setKomisiForm] = useState();

  // console.log(komisiForm)

  // useEffect(() => {
  // 	if (komisiSnapshot) {

  // 		const data = komisiSnapshot.data()
  // 		setKomisiForm({
  // 			...data,
  // 		});
  // 	}

  // }, [komisiSnapshot]);

  const { company_id } = useData();

  const col = firestore.collection(`pricing_conditions/promo/records`);

  const [snapshot, loading] = useCollection(col);

  const [table, setTable] = useState({
    columns: [
      { title: "Jumlah", field: "amount" },
      { title: "Satuan Jumlah", field: "amount_type" },
      // { title: "Max Ongkir", field: "max_promo_ongkir" },
      {
        title: "Dari Tanggal",
        field: "start_at",
        editable: "never",
        render: (rowData) => (
          <>
            {rowData && rowData.start_at !== null ? (
              <span>{unixToDate(rowData.start_at?.toMillis())}</span>
            ) : (
              <span>-</span>
            )}
          </>
        ),
      },
      {
        title: "Sampai Tanggal",
        field: "end_at",
        editable: "never",
        render: (rowData) => (
          <>
            {rowData && rowData.end_at !== null ? (
              <span>{unixToDate(rowData.end_at?.toMillis())}</span>
            ) : (
              <span>-</span>
            )}
          </>
        ),
      },
      {
        title: "Relasi Kuantitas",
        field: "qty_relation",
      },
      {
        title: "Tanda operator",
        field: "sign_operator",
      },
      {
        title: "Statistik",
        field: "statistical",
      },
    ],
    data: [],
  });

  useEffect(() => {
    if (snapshot && !snapshot.empty) {
      setTable((table) => {
        return {
          ...table,
          data: snapshot.docs.map((doc) => {
            return {
              uid: doc.id,
              ...doc.data(),
            };
          }),
        };
      });
    } else {
      setTable((table) => {
        return {
          ...table,
          data: [],
        };
      });
    }
  }, [snapshot]);

  if (
    loading
    // || komisiLoading
  ) {
    return <PageLoading />;
  }

  return (
    <>
      <div className={classes.root}>
        {loading ? (
          <PageLoading />
        ) : (
          <Container className={classes.tableCon} maxWidth="lg">
            <div className={classes.tableWrap}>
              <MaterialTable
                title="Daftar Promo Product"
                icons={tableIcons}
                columns={table.columns}
                data={table.data}
                components={{
                  Container: (props) => <div {...props} elevation={0} />,
                }}
                editable={{
                  onRowDelete: async (data) => {
                    await firestore.doc(`pricing_conditions/promo/records/${data.uid}`).delete();
                    enqueueSnackbar("Promo Berhasil Dihapus", {
                      variant: "success",
                    });
                  },
                }}
                actions={[
                  {
                    icon: () => <IconButton size="small" >
                      <EditIcon />
                    </IconButton>,
                    tooltip: "Edit",
                    onClick: (event, data) => {
                      setDialog((dialog) => ({
                        ...dialog,
                        mode: "Edit",
                        data: data,
                        id: data.uid,
                        open: true,
                      }));
                    },
                  },
                ]}
                localization={{
                  body: {
                    emptyDataSourceMessage: "Tidak Ada Data",
                    addTooltip: "Tambah",
                    editTooltip: "Ubah",
                    deleteTooltip: "Hapus",
                    editRow: {
                      deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
                      cancelTooltip: "Batal",
                      saveTooltip: "Ya",
                    },
                  },
                  toolbar: {
                    searchTooltip: "Cari",
                    searchPlaceholder: "Cari Data",
                  },
                  header: {
                    actions: "Actions",
                  },
                  pagination: {
                    labelRowsSelect: "Baris",
                    labelDisplayedRows: " {from}-{to} Dari {count} Baris",
                    firstTooltip: "Halaman Pertama",
                    previousTooltip: "Halaman Sebelumnya",
                    nextTooltip: "Halaman Selanjutnya",
                    lastTooltip: "Halaman Terakhir",
                  },
                }}
              />
            </div>
          </Container>
        )}
      </div>
      <div className={classes.fab1}>
        <Fab
          color="secondary"
          onClick={(e) => {
            setDialog({ mode: "Tambah", open: true, data: {} });
          }}
        >
          <PostAddIcon />
        </Fab>
        <AddPromoProduct
          dialog={dialog}
          promoId={table.data.uid}
          handleClose={() => {
            setDialog({ mode: "Tambah", open: false, data: {} });
          }}
        />


        <ExcelPromoProduct
        //   dialog={openAddDialog}
        //   handleClose={() => {
        //     setOpenAddDialog({
        //       open: false,
        //       // komisi: null
        //     });
        //   }}
        />
      </div>
    </>
  );
}

export default PromoProduct;
