import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    pageWrap: {
        padding: 0,
        marginTop: '-10vh'
    },
    iframe: {
        border: 0,
        height: '99vh'
    }
}));

export default useStyles;
