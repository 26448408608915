import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { useData } from "../../../../components/DataProvider";

export default function MidtransSetting(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { company_id } = useData();
  const midtransSetting = firestore.doc(
    `settings/payment/availables/midtrans`
  );

  const [setting, settingLoading] = useDocument(midtransSetting);

  const [clientkey, setClientkey] = useState("");
  const [serverkey, setServerkey] = useState("");
  const [idmerchant, setIdmerchant] = useState("");
  const [status, setStatus] = useState("");

  const handleClickSave = async () => {
    await midtransSetting.set(
      {
        client_key: clientkey,
        server_key: serverkey,
        status: status,
        id_merchant: idmerchant,
      },
      { merge: true }
    );

    props.ChangeUseDefaultPayment("midtrans");
    enqueueSnackbar("Setting Berhasil Diperbarui", { variant: "success" });
  };
  const handleClickTest = () => {
    //const result = sendZenziva();
    //console.log(result);
  };

  useEffect(() => {
    if (setting) {
      setClientkey(setting.data()?.client_key);
      setServerkey(setting.data()?.server_key);
      setIdmerchant(setting.data()?.id_merchant);
      setStatus(setting.data()?.status);
    }
  }, [setting]);

  return (
    <>
      {settingLoading && <p>Loading...</p>}
      {setting && (
        <div>
            <Typography className={classes.btm}>
              Koneksi dan Pengaturan API Untuk{" "}
              <a
                target="_blank"
                href="https://www.midtrans.com/"
                rel="noreferrer"
                className={classes.link}
              >
                Midtrans
              </a>
            </Typography>
            <form className={classes.formWrap} noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="idmerchant"
                  value={idmerchant}
                  onChange={(e) => setIdmerchant(e.target.value)}
                  label="Merchant Id"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="clientkey"
                  value={clientkey}
                  onChange={(e) => setClientkey(e.target.value)}
                  label="Client Key"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="serverkey"
                  value={serverkey}
                  onChange={(e) => setServerkey(e.target.value)}
                  label="Server Key"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup
                  aria-label="status"
                  name="status_radio"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <FormControlLabel
                    value="sandbox"
                    control={<Radio />}
                    label="Sandbox"
                  />
                  <FormControlLabel
                    value="prod"
                    control={<Radio />}
                    label="Production / Live"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={
                    clientkey && serverkey && idmerchant && status
                      ? false
                      : true
                  }
                  variant="contained"
                  color="secondary"
                  onClick={() => handleClickSave()}
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={true} //{userkey && apikey ? false : true}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleClickTest()}
                  fullWidth
                >
                  Test Sending
                </Button>
              </Grid>
            </Grid>
            </form>
        </div>
      )}
    </>
  );
}
