import React, { useEffect, useState } from "react";
import TabPanel from "../../../components/TabPanel";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";
import PageLoading from "../../../components/loading/pageLoading";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import { useSnackbar } from "notistack";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useData } from "../../../components/DataProvider";
import TextField from "@material-ui/core/TextField";
import { tr } from "date-fns/locale";

export default function Xendit({ value }) {
  const { codSetting, dropshipSetting, pencairanSetting } = useData();
  const xenditDoc = firestore.doc(`settings/payment/availables/xendit`);
  const [xenditSnapshot, xenditLoading] = useDocument(xenditDoc);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [form, setForm] = useState({
    secretkey: "",
    status: "",
  });

  useEffect(() => {
    if (xenditSnapshot) {
      const data = xenditSnapshot.data();
      setForm({
        ...data,
      });
    }
  }, [xenditSnapshot]);

  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    setSubmitting(true);
    try {
      await xenditDoc.set(
        {
          secretkey: form?.secretkey,
          status: form?.status,
        },
        { merge: true }
      );

      enqueueSnackbar("Data xendit berhasil disimpan", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(`Data xendit gagal disimpan,${e.message}`, {
        variant: "error",
      });
    }
    setSubmitting(false);
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") {
      value = parseInt(value);
    }
    // console.log(value)
    setForm({
      ...form,
      [e.target.name]: value,
    });
  };

  return (
    <TabPanel value={value} index="xendit">
      {/* <Typography className={classes.pageSubTitle} component="h1" variant="h5">Komisi</Typography> */}
      <TextField
        className={classes.grid}
        autoComplete="off"
        disabled={isSubmitting}
        id="secretkey"
        name="secretkey"
        label="Xendit API Secret Key"
        value={form.secretkey || ""}
        onChange={handleChange}
        type="text"
        variant="outlined"
        fullWidth
      />

      <TextField
        className={classes.grid}
        autoComplete="off"
        disabled={isSubmitting}
        id="status"
        name="status"
        label="Xendit Status"
        value={form.status || ""}
        onChange={handleChange}
        type="text"
        variant="outlined"
        fullWidth
      />

      <Grid item xs={12} lg={1}>
        <Button
          className={classes.btnSave}
          disabled={isSubmitting}
          onClick={handleSubmit}
          size="large"
          color="primary"
          variant="contained"
          fullWidth
        >
          {" "}
          Simpan{" "}
        </Button>
      </Grid>
    </TabPanel>
  );
}
