import { makeStyles } from '@material-ui/core/styles';
import { ImportantDevices } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    pageWrap: {
        margin: '35px 0px',
    },
    fabKursus: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    textfield: {
        marginBottom: '20px',
    },
    hideInputFile: {
        display: 'none'
    },
    uploadIconBrand: {
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    previewIconBrand: {
        width: '100%',
        height: 'auto'
    },
    iconRight: {
        paddingLeft: 5,
    },
}))


export default useStyles;