import React, { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { FieldValue, firestore, functions, storage } from '../../../components/FirebaseProvider';

// material ui core
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import Paper from '@material-ui/core/Paper';
import PageLoading from '../../../components/loading/pageLoading';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// icon
import InputLabel from '@material-ui/core/InputLabel';
import UploadIcon from '@material-ui/icons/CloudUpload';
import ImageIcon from '@material-ui/icons/Image';

// filter date
import 'date-fns';
import TabPanel from '../../../components/TabPanel';
import CekKredit from './cekKreditSeller';
import { unixToDate } from '../../../utils/dateFormatter';
import { useData } from '../../../components/DataProvider';
import { currency } from '../../../utils/formatter';
import { useSnackbar } from 'notistack';


function PencairanRekber() {
    const classes = useStyles();
    // const { company_id } = useData();
    const { enqueueSnackbar } = useSnackbar();
    const [filter, setFilter] = useState('created');
    const komisiCol = firestore.collection(`pencairan`);
    let query = komisiCol
    if (filter) {
        query = query.where("status", "==", filter)
    }
    // query = query.where('created_at', '>=', startDate).where('created_at', '<=', endDate);
    const [dataKomisi, loadingDataKomisi, errorLoading] = useCollectionData(query, { idField: 'id' });
    // console.log(errorLoading);
    const [isSubmitting, setSubmitting] = useState(false);

    const [form, setForm] = useState()

    const [error, setError] = useState({})

    // const kreditCol = firestore.collection(`kredit_mitra`)

    // const [dataKredit, loadingKredit] = useCollectionData(kreditCol, { idField: 'id' })

    const changeStatus = komisi => async () => {
        console.log(komisi)
        setSubmitting(true)
        try {
            //functions.useEmulator('localhost',8000);
            const transaksi = functions.httpsCallable("admin-processPencairan");
            const res = await transaksi({
                pencairan_id: komisi?.id,
                user_id: komisi?.user_id,
                status_approval: 'approved'
            });
            // console.log(res)
            // await komisiCol.doc(komisi.id).set({
            //     status: 'approved',
            //     updated_at: FieldValue.serverTimestamp(),
            //     approved_at: FieldValue.serverTimestamp()
            // }, {
            //     merge: true
            // })
            // await kreditCol.doc(komisi.user_id).set({
            //     total_pencairan_pending: FieldValue.increment(-komisi.total_pencairan),
            //     total_pencairan_approved: FieldValue.increment(+komisi.total_pencairan)
            // }, {
            //     merge: true
            // })
            // const userDoc = firestore.collection(`seller`).doc(komisi.user_id);
            // const notifCol = userDoc.collection(`notifications`)
            // await notifCol.add({
            //     data: { target_id: komisi.id, target_type: 'pencairan', target_name: '' },
            //     created_at: FieldValue.serverTimestamp(),
            //     updated_at: FieldValue.serverTimestamp(),
            //     message: `Pencairan anda sejumlah Rp.${komisi?.total_pencairan},sudah diapprove`,
            //     title: `Pencairan anda sejumlah Rp.${komisi?.total_pencairan},sudah diapprove`
            // })
            setSubmitting(false)
            enqueueSnackbar('Pencairan berhasil di approve ', { variant: "success" })

        } catch (e) {
            enqueueSnackbar('Pencairan gagal di approve ', { variant: "error" })

            setSubmitting(false)

            console.log(e.message)
        }

    }
    const changeStatusReject = komisi => async () => {
        setSubmitting(true)

        try {
            await komisiCol.doc(komisi.id).set({
                status: 'rejected',
                updated_at: FieldValue.serverTimestamp()
            }, {
                merge: true
            })
            // await kreditCol.doc(komisi?.mitra_id).set({
            //     total_pencairan_pending: FieldValue.increment(-komisi.total_pencairan),
            // }, {
            //     merge: true
            // })
            setSubmitting(false)
            enqueueSnackbar('Pencairan berhasil di reject ', { variant: "success" })

        } catch (e) {
            setSubmitting(false)
            enqueueSnackbar('Pencairan gagal di reject ', { variant: "error" })

            console.log(e.message)
        }

    }
    //console.log(dataKredit)

    const handleUploadIcon = komisi => async (e) => {
        const file = e.target.files[0];

        console.log(komisi)

        if (!['image/jpg', 'image/png', 'image/jpeg'].includes(file.type)) {

            setError(error => ({
                ...error,
                bukti_transfer_permbayaran: `Tipe file tidak didukung: ${file.type}`
            }))
        }
        else if (file.size >= 512000) {
            setError(error => ({
                ...error,
                bukti_transfer_permbayaran: `Ukuran file terlalu besar > 500KB`
            }))
        } else {

            const reader = new FileReader();

            reader.onabort = () => {

                setError(error => ({
                    ...error,
                    bukti_transfer_permbayaran: `Proses pembacaan file dibatalkan`
                }))
            }

            reader.onerror = () => {

                setError(error => ({
                    ...error,
                    bukti_transfer_permbayaran: 'File tidak bisa dibaca'
                }))
            }

            reader.onload = async () => {
                setError(error => ({
                    ...error,
                    bukti_transfer_permbayaran: ''
                }))
                setSubmitting(true);
                try {
                    const pencairanStorageRef = storage.ref(`pencairan_rekber`);

                    const iconExt = file.name.substring(file.name.lastIndexOf('.'));

                    const iconRef = pencairanStorageRef.child(`${komisi.id}_pencairanrekber_${iconExt}`);

                    const iconSnapshot = await iconRef.putString(reader.result, 'data_url');

                    const iconUrl = await iconSnapshot.ref.getDownloadURL();

                    setForm(currentForm => ({
                        ...currentForm,
                        bukti_transfer_permbayaran: iconUrl
                    }));
                    await komisiCol.doc(komisi.id).set({
                        bukti_transfer_permbayaran: iconUrl,
                        updated_at: FieldValue.serverTimestamp()
                    }, {
                        merge: true
                    })

                } catch (e) {
                    setError(error => ({
                        ...error,
                        bukti_transfer_permbayaran: e.message
                    }))


                }

                setSubmitting(false);
            }
            reader.readAsDataURL(file);
        }
    }

    const handleChangeTab = (event, value) => {
        setFilter(value);
    }

    const [openDialog, setOpenDialog] = useState({
        open: false,
        id: null,
    });

    // if (loadingDataKomisi) {
    //     return <PageLoading />
    // }
    return <>
        { loadingDataKomisi ?
            <PageLoading size={50} color='inherit' /> : ''}
        <Container maxWidth='lg'>
            <Paper className={classes.paperWrap}>
                <div className={classes.pageWrap}>
                    <Typography className={classes.pageTitle} component="h1" variant="h6">Daftar Pencairan Komisi</Typography>
                    {/* <div className={classes.filterBox}>
                                <div className={classes.filterForm}>
                                    <Typography>Filter By Date</Typography>
                                    <div className={classes.filterBoxDate}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                className={classes.KeyboardDatePicker}
                                                maxDate={new Date()}
                                                disableToolbar
                                                variant="inline"
                                                inputVariant='outlined'
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Start Date"
                                                value={startDate}
                                                onChange={handleStartDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                            <KeyboardDatePicker
                                                minDate={startDate}
                                                maxDate={new Date()}
                                                disableToolbar
                                                variant="inline"
                                                inputVariant='outlined'
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="End Date"
                                                value={endDate}
                                                onChange={handleEndDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div> */}
                    {/* </div> */}
                    <Tabs indicatorColor="primary"
                        className={classes.tabWrap}
                        textColor="primary"
                        value={filter}
                        onChange={handleChangeTab}
                        variant="fullWidth"
                    >
                        <Tab label="Dibuat" value='created' />
                        <Tab label="Diproses" value='pending' />
                        <Tab label="Berhasil" value='completed' />
                        <Tab label="Ditolak" value='rejected' />
                        <Tab label="Gagal" value='failed' />
                    </Tabs>
                    <TabPanel value={(e) => {
                        setFilter('created');
                    }} index='created' />
                    <TabPanel value={(e) => {
                        setFilter('pending');
                    }} index='pending' />
                    <TabPanel value={(e) => {
                        setFilter('completed');
                    }} index='completed' />
                    <TabPanel value={(e) => {
                        setFilter('rejected');
                    }} index='rejected' />
                    <TabPanel value={(e) => {
                        setFilter('failed');
                    }} index='failed' />

                    <div className={classes.grid}>
                        <Grid container>
                            {
                                dataKomisi?.length <= 0 &&
                                <Typography className={classes.noOrderMsg} variant="h5">Belum Ada Pencairan</Typography>
                            }
                            {console.log(dataKomisi)}
                            {
                                dataKomisi?.map?.((komisi) => {
                                    return <Grid className={classes.liquidBlock} key={komisi.id} item xs={12}>
                                        <Card variant="outlined" className={classes.card} >
                                            <div className={classes.liquidSection}>
                                                {
                                                    komisi?.status === 'created' ?
                                                    <Typography className={classes.liquidTitle}>Tanggal Dibuat : {unixToDate(komisi?.created_at?.toMillis())}</Typography> : null
                                                }
                                                {
                                                    komisi?.status === 'completed' ?
                                                        <Typography className={classes.liquidTitle}>Tanggal Disetujui : {unixToDate(komisi?.approved_at?.toMillis())}</Typography> : null
                                                }
                                                <div className={classes.liquidRow}>
                                                    <Typography className={classes.liquidTitle}>Tujuan Bank</Typography>
                                                </div>
                                                <div className={classes.liquidRow}>
                                                    <Typography className={classes.liquidLabel}>No Rek.</Typography>
                                                    <Typography className={classes.liquidValue}>: {komisi?.bank_tujuan?.no_rek || ''}</Typography>
                                                </div>
                                                <div className={classes.liquidRow}>
                                                    <Typography className={classes.liquidLabel}>Nama Bank</Typography>
                                                    <Typography className={classes.liquidValue}>: {komisi.bank_tujuan?.name || ''}</Typography>
                                                </div>
                                                {/* <div className={classes.liquidRow}>
                                                    <Typography className={classes.liquidLabel}>No Rek.</Typography>
                                                    <Typography className={classes.liquidValue}>: {komisi.tujuan_bank.no_rek || ''}</Typography>
                                                </div> */}
                                                <div className={classes.liquidRow}>
                                                    <Typography className={classes.liquidLabel}>Total Pencairan</Typography>
                                                    <Typography className={classes.liquidValue}>: {currency(komisi?.amount) || ''}</Typography>
                                                </div>
                                                <div className={classes.liquidRow}>
                                                    <Typography className={classes.liquidLabel}>User </Typography>
                                                    <Typography className={classes.liquidValue}>: {komisi?.user_nama || ''} ( {komisi?.user_email || ''} / {komisi?.user_wa || ''} )</Typography>
                                                </div>
                                                <div className={classes.liquidRow}>
                                                    <Typography className={classes.liquidLabel}>Status</Typography>
                                                    <Typography className={classes.liquidValue}>: {komisi?.status || ''}</Typography>
                                                </div>
                                                {
                                                    komisi?.status === 'failed' ?
                                                        <div className={classes.liquidRow}>
                                                    <Typography className={classes.liquidLabel}>Failure Code</Typography>
                                                    <Typography className={classes.liquidValue}>: {komisi?.failure_code || ''}</Typography>
                                                </div> : null
                                                }
                                                <div className={classes.liquidRow}>
                                                <Button className={classes.btnWrap3} variant="contained" color="primary"
                                                    disabled={isSubmitting}
                                                    // userId={users.id}
                                                    onClick={(e) => {
                                                        setOpenDialog({
                                                            open: true,
                                                            id: komisi?.user_id,
                                                        });
                                                    }}>Cek Kredit</Button>
                                                </div>
                                                <div className={classes.liquidRow}>
                                                    {komisi.status === 'created' ?
                                                        <div>

                                                            <div style={{ display: 'flex' }}>
                                                                <div className={classes.btnWrap}><Button
                                                                    disabled={isSubmitting}
                                                                    onClick={changeStatus(komisi)}
                                                                    color="primary" variant="contained">
                                                                    Approve
                                                                </Button></div>
                                                                <div className={classes.btnWrap2}><Button
                                                                    disabled={isSubmitting}
                                                                    onClick={changeStatusReject(komisi)}
                                                                    color="primary" variant="contained">
                                                                    Reject
                                                                </Button></div>
                                                            </div>
                                                        </div> :
                                                        ""}
                                                </div>
                                            </div>
                                            
                                            {/* <div className={classes.liquidRow}>
                                                {komisi.status === 'approved' ? <>
                                                    <Grid>
                                                        <InputLabel className={classes.liquidTitle}>Upload Bukti Transfer</InputLabel>
                                                        <div className={classes.uploadIconPencairan}>

                                                            {komisi?.bukti_transfer_permbayaran &&
                                                                <div className={classes.imgWrap}><img src={komisi?.bukti_transfer_permbayaran} className={classes.imgPreview} alt="" /></div>}
                                                            {!komisi?.bukti_transfer_permbayaran &&
                                                                <ImageIcon
                                                                    className={classes.previewIconPencairan}
                                                                    size="large"
                                                                    color="disabled"
                                                                />}

                                                            <input
                                                                className={classes.hideInputFile}
                                                                type="file"
                                                                name="bukti_transfer_permbayaran"
                                                                id={"upload-icon-pencairan-" + komisi.id}
                                                                accept="image/jpeg,image/png,image/jpg"
                                                                onChange={handleUploadIcon(komisi)}
                                                            />
                                                            <label htmlFor={"upload-icon-pencairan-" + komisi.id}>
                                                                <Button
                                                                    disabled={isSubmitting}
                                                                    name="bukti_transfer_permbayaran"

                                                                    variant="outlined"
                                                                    component="span"
                                                                >Upload<UploadIcon className={classes.iconRight} /></Button>
                                                            </label>

                                                            {error.bukti_transfer_permbayaran &&
                                                                <Typography color="error">
                                                                    {error.bukti_transfer_permbayaran}
                                                                </Typography>}

                                                        </div>
                                                    </Grid>
                                                </> : null
                                                }
                                            </div> */}
                                        </Card>
                                    </Grid>


                                })
                            }
                            <CekKredit
                                dialog={openDialog}
                                handleClose={() => {
                                    setOpenDialog({
                                        open: false,
                                        id: null,
                                    });
                                }}
                            />
                        </Grid>
                    </div>
                </div>
            </Paper>
        </Container>
    </>
}

export default PencairanRekber;