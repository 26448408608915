import React, { useState, useEffect, forwardRef } from 'react';
import Container from '@material-ui/core/Container';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Fab from '@material-ui/core/Fab';
import UploadIcon from '@material-ui/icons/CloudUpload';
import MaterialTable from 'material-table';
import useStyles from './styles';
import AddProduct from './add';
import { useCollection } from 'react-firebase-hooks/firestore';
import PageLoading from '../../../components/loading/pageLoading';
import { unixToDate } from '../../../utils/dateFormatter';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import tableIcons from '../../../config/table-icons';
import { firestore } from '../../../components/FirebaseProvider';
import ExcelRead from './addFileExcel';
import { useData } from '../../../components/DataProvider';
import Edit from '@material-ui/icons/Edit';

function Table() {
	//const { kategoriList } = useData();
	//console.log(kategoriList)

	const classes = useStyles();

	const history = useHistory();

	const { enqueueSnackbar } = useSnackbar();

	const [openAddProduct, setOpenAddProduct] = useState(false);
	

	const [openAddDialog, setOpenAddDialog] = useState({
		open: false,
		// komisi: null
	});

	const { company_id } = useData();

	const col = firestore.collection(`produk`);

	const [snapshot, loading] = useCollection(col);

	const [table, setTable] = useState({
		columns: [
			{ title: 'ID', field: 'produk_id' },
			{ title: 'Nama', field: 'nama_produk' },
			{ title: 'Harga Tiket', field: 'harga_produk' },
			{ title: 'Komisi Affiliate', field: 'komisi_affiliate' ,
				render: rowData =>
					<>
						{
							rowData && rowData.komisi_affiliate !== null ?
								<span>
									{rowData.komisi_affiliate * 100}%
								</span>
								:
								<span>-</span>
						}
					</>},
			{ title: 'Expiry days', field: 'expirydays' },

			{
				title: 'Tgl Launching', field: 'launchdate', editable: 'never',
				render: rowData =>
					<>
						{
							rowData && rowData.launchdate !== null ?
								<span>
									{unixToDate(rowData.launchdate?.toMillis())}
								</span>
								:
								<span>-</span>
						}
					</>
			},
		],
		data: [
		],
	});

	useEffect(() => {
		if (snapshot && !snapshot.empty) {
			setTable(table => {
				return {
					...table,
					data: snapshot.docs.map(doc => {
						return {
							uid: doc.id,
							...doc.data()
						}
					})
				}
			})
		} else {
			setTable(table => {
				return {
					...table,
					data: []
				}
			})
		}
	}, [snapshot]);


	if (loading
		// || komisiLoading
	) {
		return <PageLoading />
	}

	return (
		<>
			<div className={classes.root}>
				{loading ?
					<PageLoading />
						:
						<Container className={classes.tableWrap}>
							<MaterialTable
								icons={tableIcons}
								title="Daftar Produk"
								columns={table.columns}
								data={table.data}
								components={{
									Container: props => <div {...props} elevation={0} />
								}}
								editable={{
									onRowDelete: async (data) => {
										await firestore.doc(`products/${data.uid}`).delete();
										enqueueSnackbar('Produk Berhasil Dihapus', { variant: "success" })
									},

									// onRowAdd: async (newData) => {
									// 	try {
									// 		if (!newData.thumbnail || !newData.nama || !newData.product_id || !newData.deskripsi) {

									// 			enqueueSnackbar('Data Produk Tidak Lengkap', { variant: "error" });
									// 			throw new Error("Data Produk Tidak Lengkap");
									// 		}
									// 		else {
									// 			const newProduct = {
									// 				thumbnail: newData.thumbnail,
									// 				nama: newData.nama,
									// 				product_id: newData.product_id,
									// 				deskripsi: newData.deskripsi,
									// 				created_at: FieldValue.serverTimestamp(),
									// 				updated_at: FieldValue.serverTimestamp(),
									// 			}
									// 			await firestore.collection('products').add(newProduct);
									// 			enqueueSnackbar('Produk Berhasil Ditambahkan', { variant: "success" });
									// 		}
									// 	}
									// 	catch (e) {
									// 		console.log(e.message)
									// 	}
									// },
									// onRowUpdate: async (newData, data) => {

									//     const updateProduct = {
									//             thumbnail: newData.thumbnail,
									//             nama: newData.nama,
									//             product_id: newData.product_id,
									//             deskripsi: newData.deskripsi,
									//             updated_at: FieldValue.serverTimestamp()
									//     }
									//     await firestore.doc(`products/${data.uid}`).set(updateProduct, { merge: true });
									//     enqueueSnackbar('Produk Berhasil Diubah', { variant: "success" });
									// }
								}}
								actions={[
									{icon: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
										tooltip: 'Edit',
										onClick: (event, data) => {
											history.push(`/product/edit/${data.uid}`);
										}
									}
								]}
								localization={{
									body: {
										emptyDataSourceMessage: 'Tidak Ada Data',
										addTooltip: 'Tambah',
										editTooltip: 'Edit',
										deleteTooltip: 'Hapus',
										editRow: {
											deleteText: 'Anda Yakin Akan Menghapus Data Ini ?',
											cancelTooltip: 'Batal',
											saveTooltip: 'Ya'
										}
									},
									toolbar: {
										searchTooltip: 'Cari',
										searchPlaceholder: 'Cari Data'
									},
									header: {
										actions: 'Actions'
									},
									// pagination: {
									// 	labelRowsSelect: 'Baris',
									// 	labelDisplayedRows: ' {from}-{to} Dari {count} Baris',
									// 	firstTooltip: 'Halaman Pertama',
									// 	previousTooltip: 'Halaman Sebelumnya',
									// 	nextTooltip: 'Halaman Selanjutnya',
									// 	lastTooltip: 'Halaman Terakhir'
									// }
								}}
							/>
						</Container>
				}

			</div>
			<div className={classes.fab1}>
				<Fab
					color="secondary"
					onClick={() => {
						setOpenAddProduct(true);
					}}
				>
					<PostAddIcon />
				</Fab>
				<AddProduct
					open={openAddProduct}
					handleClose={() => {
						setOpenAddProduct(false);
					}}
				/>
				{/* <Fab color="primary" variant="extended" className={classes.fab}
					onClick={(e) => {
						setOpenAddDialog({
							open: true,
							// komisi: komisiForm
						});
					}}
				>
					<UploadIcon className={classes.uploadIcon} /> <span>Upload File</span>
				</Fab>
				<ExcelRead
					dialog={openAddDialog}
					handleClose={() => {
						setOpenAddDialog({
							open: false,
							// komisi: null
						});
					}}
				/> */}
			</div>
		</>
	);
}

export default Table;
