import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    pageWrap: {
        paddingBottom: theme.spacing(6),
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        marginTop: 35,
        marginBottom: 25,
    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    },
    pageTitle: {
        padding: 20,
    },
    button: {
        width: 100,
        padding: theme.spacing(1),
        right: theme.spacing(2)
    },
    grid: {
        display: 'flex',
        padding: '10px'
    },
    liquidBlock: {
        marginBottom: 30,
        maxHeight: 540,
    },
    card: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        height: '100%',
    },
    hideInputFile: {
        display: 'none'
    },
    imgWrap: {
        width: '100%',
        height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        overflow: 'hidden',
        padding: 10,
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    uploadIconPencairan: {
        textAlign: 'center',
        padding: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    previewIconPencairan: {
        width: '100%',
        height: 80,
        marginBottom: 13,
    },
    iconRight: {
        paddingLeft: 5,
    },
    cardFilter: {
        padding: theme.spacing(5),
    },
    gridFilter: {
        margin: theme.spacing(2),
    },
    filterBox: {
        margin: '20px 0',
        borderTop: 'solid 1px ' + theme.palette.textColor.gray2,
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
        padding: 20,
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    filterForm: {
        width: '60%',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        '@media (max-width:1280px)': {
            width: '100%',
        },
    },
    filterBoxDate: {
        marginLeft: 10,
        width: '70%',
    },
    KeyboardDatePicker: {
        margin: '16px 20px'
    },
    tabWrap: {
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
    },
    tabItem: {
        width: 200,
        display: 'flex',
        justifyContent: 'center',
        padding: '20px 0',
    },
    tabItemActive: {
        borderBottom: 'solid 1px ' + theme.palette.primary.main,
        color: theme.palette.primary.main
    },
    liquidSection: {
        marginBottom: 25,
    },
    liquidTitle: {
        color: theme.palette.textColor.lessTextColor,
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
        width: '100%',
        paddingBottom: 10,
        marginBottom: 10,
    },
    liquidRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        width: '100%'
    },
    liquidLabel: {
        color: theme.palette.textColor.lessTextColor,
        width: 140,
        marginRight: 10,
        fontSize: 14,
    },
    liquidValue: {
        color: theme.palette.textColor.primeTextColor,
        fontSize: 13,
        width: '75%',
        wordBreak: 'break-all'
    },
    innerRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        width: '100%'
    },
    btnWrap: {
        marginTop: 25,
    },
    btnWrap2: {
        marginTop: 25,
        marginLeft: theme.spacing(2)
    },
    liquidLabel2: {
        color: theme.palette.textColor.lessTextColor,
        width: 250,
        marginRight: 10,
        fontSize: 14,
    },
    btnWrap3: {
        marginBottom: theme.spacing(3)
    },
    noOrderMsg: {
        width: 200,
        height: 40,
        marginTop: '13%',
        marginBottom: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: theme.palette.textColor.primeTextColor,
        fontSize: 15,
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    jp1: {
        border: '2px solid #cd3478',
        borderRadius: '5px',
        padding: '13px',
        cursor: 'pointer',
        marginTop: 20,
    },

}))


export default useStyles;