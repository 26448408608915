import React, { useEffect, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
import TabPanel from "../../../components/TabPanel";
import useStyles from "./styles";
import PageLoading from "../../../components/loading/pageLoading";
import ZenzivaSetting from "./sender/zenziva";
import WoowaSetting from "./sender/woowa";
import QontakSetting from "./sender/qontak";
import Grid from "@material-ui/core/Grid";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";
import { useData } from "../../../components/DataProvider";
import Switch from "@material-ui/core/Switch";
import { Typography } from "@material-ui/core";

export default function Sender({ value }) {
  const classes = useStyles();

  const [selected, setSelected] = useState("zenziva");
  const [active, setActive] = useState(true);
  const { company_id } = useData();

  const senderSetting = firestore.doc(
    `settings/sender`
  );
  const [setting, settingLoading] = useDocument(senderSetting);

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const ChangeUseDefaultSender = async (sender) => {
    await senderSetting.set(
      {
        use_available: sender,
        active,
      },
      { merge: true }
    );
  };
  const selectedSender = () => {
    switch (selected) {
      case "zenziva":
        return (
          <ZenzivaSetting
            user={company_id}
            ChangeUseDefaultSender={ChangeUseDefaultSender}
          />
        );
        break;
      case "woowa":
        return (
          <WoowaSetting
            user={company_id}
            ChangeUseDefaultSender={ChangeUseDefaultSender}
          />
        );
        break;
      case "qontak":
        return (
          <QontakSetting
            user={company_id}
            ChangeUseDefaultSender={ChangeUseDefaultSender}
          />
        );
        break;
      default:
        return "";
        break;
    }
  };

  useEffect(() => {
    if (setting) {
      setSelected(setting.data()?.use_available);
      setActive(setting.data()?.active ? setting.data()?.active : false);
    }
  }, [setting]);

  if (settingLoading) {
    return <PageLoading />;
  }

  return (
    <>
      <TabPanel value={value} index="sender">
        <p className={classes.btm}>
          Sender adalah layanan pihak ketiga yang digunakan untuk mengirimkan pesan
          SMS/Whatsapp
        </p>
        {setting && (
          <Grid container spacing={3}>
            <Grid item xs={6}>
                <FormControl component="fieldset">
                  <Typography>
                    Gunakan layanan sender ?
                  </Typography>
                  <Switch
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                    name="active"
                    color="primary"
                    className={classes.btm}
                  />
                  <Typography>
                    Pilih layanan sender:
                  </Typography>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={selected}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="woowa"
                      control={<Radio />}
                      label="Woowa"
                    />
                    <FormControlLabel
                      value="zenziva"
                      control={<Radio />}
                      label="Zenziva"
                    />
                    <FormControlLabel
                      value="qontak"
                      control={<Radio />}
                      label="Qontak.id"
                    />
                    <FormControlLabel
                      value="lainnya..."
                      disabled
                      control={<Radio />}
                      label="(Lainnya...)"
                    />
                  </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
              {selectedSender()}
            </Grid>
          </Grid>
        )}
      </TabPanel>
    </>
  );
}
