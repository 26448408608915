import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    pageWrap: {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        borderRadius: 8,
        marginTop: 35,
        marginBottom: 45,
    },
    paperWrap: {
        padding: '0px 40px 20px 40px',
    },
    pageTitle: {
        padding: '20px 10px 10px 25px',
    },
    button: {
        width: 100,
        padding: theme.spacing(1),
        right: theme.spacing(2)
    },
    imgWrap: {
        width: '100%',
        height: 260,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000',
        overflow: 'hidden',
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        marginTop: 10,
        marginBottom: 15,
        borderRadius: 5,
        '& img': {
            width: '100%',
            height: 'auto',
        }
    },
    card: {
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    },
    hideInputFile: {
        display: 'none'
    },
    uploadFotoBanner: {
        textAlign: 'center',
    },
    iconRight: {
        paddingLeft: 5
    },
    margin: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        '@media (max-width: 1280px)': {
            marginRight: 0,
        }
    }

}))


export default useStyles;