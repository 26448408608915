import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paperWrap: {
    margin: "30px 2px",
  },
  pageWrap: {
    padding: "20px",
  },
  paper: {
    padding: 20,
    margin: "16px 0",
  },
  pageTitle: {
    paddingTop: 5,
  },
  button: {
    width: 100,
    padding: theme.spacing(1),
    right: theme.spacing(2),
  },
  grid: {
    display: "flex",
  },
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fab1: {
    display: "flex",
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(6),
  },
  fab: {
    marginLeft: theme.spacing(2),
  },
  topBox: {
    minHeight: 300,
    marginBottom: 20,
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "flex-start",
    "@media(max-width:768px)": {
      minHeight: 150,
    },
  },
  sellerRow: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    marginBottom: 5,
  },
  boxTitle: {
    color: theme.palette.textColor.lessTextColor,
    fontWeight: "bold",
    marginBottom: 20,
  },
  boxBlock: {
    color: theme.palette.textColor.lessTextColor,
    fontWeight: "bold",
    marginTop: 20,
  },
  rowLabel: {
    color: theme.palette.textColor.lessTextColor,
    fontSize: 15,
    width: 120,
  },
  rowValue: {
    color: theme.palette.textColor.primeTextColor,
    fontSize: 15,
  },
  viewBtn: {
    marginLeft: 5,
  },
  pagination: {
    "& > *": {
      display: "flex",
      marginTop: theme.spacing(2),
      alignItems: "center",
      justifyContent: "center",
    },
  },
  loading: {
    position: "absolute",
    justifyContent: "center",
  },
  btnEdit: {
    marginTop: 20,
  },
  btnEditWa: {
    marginLeft: theme.spacing(2),
  },
  accSummary: {
    backgroundColor: "#fafafa",
  },
}));

export default useStyles;
