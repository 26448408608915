import React, { useEffect, useState } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
// import { firestore, } from '../../../components/FirebaseProvider';
// import { useDocument } from 'react-firebase-hooks/firestore';
import useStyles from './styles';
import Pickup from './pickup';
import Cod from './cod_sicepat';
import CodJne from './cod_jne';

function Coverage() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    //console.log(location)



    const [tabValue, setTabValue] = useState(qs.parse(location.search)?.tab ?? 'pickup');
    const handleChangeTab = (event, value) => {
        setTabValue(value);

    }

    useEffect(() => {

        history.push(`?tab=${tabValue}`);
    }, [tabValue, history]);





    const [tabs] = useState([


        {
            label: "Pickup", value: "pickup"
        },
        {
            label: "cod sicepat", value: "cod_sicepat"
        },
        {
            label: "cod jne", value: "cod_jne"
        },



    ])

    return (<>
        <Container maxWidth='lg'>
            <Paper className={classes.paperWrap}>
                <Typography className={classes.pageTitle} component="h1" variant="h6">Coverage</Typography>
                <Tabs
                    indicatorColor="primary"
                    className={classes.tabWrap}
                    textColor="primary"
                    value={tabValue || "pickup"}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                >
                    {tabs.map(tab => <Tab key={tab.value} label={tab.label} value={tab.value} />)}
                </Tabs>
                <div className={classes.content}>
                    <Pickup value={tabValue} />
                    <Cod value={tabValue} />
                    <CodJne value={tabValue} />
                    {/* <Alamat uid={company_id} value={tabValue} use_available={form.use_available} shipLoading={shipLoading} /> */}
                    {/* 
                        <PickupAgent value={tabValue} formAgent={form} />
                        <RateidList value={tabValue} /> */}
                    {/* <Subdomain uid={company_id} value={tabValue} />
                        <Fitur uid={company_id} value={tabValue} />
                        <SellerLevel uid={company_id} value={tabValue} /> */}
                    {/* <Shipping uid={company_id} value={tabValue} settLoading={shipLoading} form={form} handleChange={handleChange} formShip={formShip} handleSubmit={handleSubmit} isSubmitting={isSubmitting} error={error} /> */}
                </div>
            </Paper>
        </Container>
    </>
    )
}

export default Coverage;