import React, { useState } from 'react';
import AddSlider from './addSlider';
import Fab from '@material-ui/core/Fab';
import AddPhoto from '@material-ui/icons/AddAPhoto';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { firestore } from '../../../components/FirebaseProvider';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import PageLoading from '../../../components/loading/pageLoading';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import { useData } from '../../../components/DataProvider';

function Slider() {
    const classes = useStyles();

    const { company_id } = useData();

    const sliderCol = firestore.collection(`slider`);

    const [dataSlider, loadingDataSlider] = useCollectionData(sliderCol, { idField: 'id' })

    const [dialog, setDialog] = useState({
        mode: 'Tambah',
        open: false,
        data: {},
        uid: {}
    });

    if (loadingDataSlider) {
        return <PageLoading />
    }
    return <>
        <Container maxWidth="lg">
            <Paper className={classes.paperWrap}>
                <div className={classes.pageWrap}>
                    <Typography className={classes.pageTitle} component="h1" variant="h6">Daftar Slider</Typography>
                    <div className={classes.sliderWrap}>
                        {
                            dataSlider?.length <= 0 &&
                            
                            <Typography className={classes.noOrderMsg} variant="h5">Belum Ada Slider</Typography>
                        }
                        {
                            dataSlider.map((slider) => {
                                return <div className={classes.sliderBox} container key={slider.id}>
                                    <div className={classes.sliderRow}>
                                        <Typography className={classes.slideLabel}>Target Type : </Typography>
                                        <Typography className={classes.slideValue}> {slider?.target_type}</Typography>
                                    </div>
                                    <div className={classes.sliderRow}>
                                        <Typography className={classes.slideLabel}>Target Nama : </Typography>
                                        <Typography className={classes.slideValue}> {slider?.target_nama}</Typography>
                                    </div>
                                    <div className={classes.sliderRow}>
                                        <Typography className={classes.slideLabel}>Target Id : </Typography>
                                        <Typography className={classes.slideValue}> {slider?.target_id}</Typography>
                                    </div>
                                    <Typography className={classes.slideLabel}>Thumbnail</Typography>
                                    <div className={classes.imgWrap}><img src={slider.url} width="100%" alt="" /></div>
                                    <Button color="primary" variant="contained" className={classes.padding} startIcon={<Edit />} onClick={(event) => {
                                        setDialog(dialog => ({
                                            ...dialog,
                                            mode: 'Edit',
                                            data: slider,
                                            id: slider.id,
                                            open: true,
                                        }))
                                    }}>
                                        Edit
                                    </Button>
                                </div>
                            })
                        }
                    </div>
                </div>
            </Paper>
        </Container>
        <Fab color="secondary"
            className={classes.fab1}
            onClick={(e) => {
                setDialog({ mode: 'Tambah', open: true, data: {} });

            }}
        >
            <AddPhoto />
        </Fab >
        <AddSlider
            dialog={dialog}
            sliderId={dataSlider.id}
            handleClose={() => {
                setDialog({ mode: 'Tambah', open: false, data: {} });
            }}

        />
    </>
}

export default Slider;