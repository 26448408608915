import React from "react";
import TabPanel from "../../../components/TabPanel";

export default function Automation({ value }) {
  return (
    <TabPanel value={value} index="automation">
      <p>Automation integration setting</p>
    </TabPanel>
  );
}
