import React, { useEffect, useState } from "react";
import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import {
  FieldValue,
  firestore,
  useFirebase,
  functions
} from "../../../components/FirebaseProvider";
import UploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import useStyles from "./styles";
//import AddMitra from "./add";
//import ExelRead from "./addFileExelMitra";
import Paper from "@material-ui/core/Paper";
import PageLoading from "../../../components/loading/pageLoading";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import PostAddIcon from '@material-ui/icons/PostAdd';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import Alamat from './alamat';
import { useData } from "../../../components/DataProvider";
// import AddSeller from './add';
import Switch from "@material-ui/core/Switch";
import PostAddIcon from "@material-ui/icons/PostAdd";

// lab
import Pagination from "@material-ui/lab/Pagination";

// ico
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import Search from "@material-ui/icons/Search";
// import DeleteSeller from './deleteSeller';
// import EditWa from './editWa';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/app";
import { useSnackbar } from "notistack";
import CsvDownload from 'react-json-to-csv'
import AppLoading from "../../../components/loading/appLoading";

export const auth = firebase.auth();

function Tiket() {
  const [AuthData, setAuthData] = useAuthState(auth);
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState("");
  // const [searchEmail , setSearchEmail] = React.useState("");
  const { dataUsers } = useData();
  const listComp = firestore.collection(`orders`);
  const [dataComp, loadingDataKomisi] = useCollectionData(listComp);
  // console.log('DATA=>', dataComp);
  const [openAddMitra, setOpenAddMitra] = useState(false);
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  const noOfPages = Math.ceil(dataComp?.length / itemsPerPage);
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const [isDownloading, setIsdownloading] = useState(false);
  const [dataDownload, setDatadownload] = useState([]);
  const [isDownloaded, setIsDownloaded] = useState(false);

  async function handleDownloadExpired() {
    setIsdownloading(true);
    const getData = functions.httpsCallable("statistic-getPendingPaymentList");
    const res = await getData({});
    setDatadownload(res.data);
    setIsDownloaded(true);
    setIsdownloading(false);
  }

  const [openAddDialog, setOpenAddDialog] = useState({
    open: false,
    // userId: null,
  });

  const { enqueueSnackbar } = useSnackbar();

  // dialog block mitra
  const [openDialog, setOpenDialog] = useState({ open: false, data: "" });
  const [textBlock, setTextBlock] = useState();
  const handleChange = (event) => {
    setTextBlock(event.target.value);
  };
  const handleClose = () => {
    setOpenDialog({ open: false });
  };

  // Search DATA With EMAIL
  const dataSearch = dataComp?.filter?.((data) => {
    if (searchTerm === data?.email) {
      return data;
    }
  });

  // console.log(openDialog)
  if (loadingDataKomisi) {
    return <PageLoading />;
  }
  return (
    <>
      <Container maxWidth="lg">
        <Paper className={classes.paperWrap}>
          <div className={classes.pageWrap}>
            <Grid container item xs={12}>
              <Grid item xs={9}>
                <Grid>
                  <Typography
                    className={classes.pageTitle}
                    component="h1"
                    variant="h6"
                  >
                    Daftar Tiket
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Cari Email Tiket"
                  value={searchTerm}
                  id="search"
                  name="search"
                  variant="outlined"
                  size="small"
                  helperText="masukan email lengkap"
                  onChange={(e) => {
                    setSearchTerm(e?.target?.value);
                  }}
                  InputProps={{
                    endAdornment: <Search color="secondary" />,
                  }}
                />
              </Grid>
              {isDownloading ? <AppLoading /> : isDownloaded ? <CsvDownload data={dataDownload} filename="pending-tiket.csv">Klik lagi untuk Download Sebagai CSV</CsvDownload> : <Button className={classes.loginBtn} variant="contained" color="primary" onClick={handleDownloadExpired}>Klik Untuk Mendapatkan Daftar Tiket Kadaluarsa</Button>}
              
            </Grid>
            {searchTerm
              ? dataSearch
                  ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  ?.map?.((users) => {
                    return (
                      <Grid key={users.id}>
                        <Paper variant="outlined" className={classes.paper}>
                          <div className={classes.topBox}>
                            <Typography className={classes.boxTitle}>
                              Info Tiket
                            </Typography>

                            <div className={classes.sellerRow}>
                              <Typography className={classes.rowLabel}>
                                Nama
                              </Typography>
                              <Typography className={classes.rowValue}>
                                :{" "}
                                {users?.nama !== undefined ? (
                                  users?.nama
                                ) : (
                                  <text style={{ color: "red" }}>
                                    nama tidak ada
                                  </text>
                                )}
                              </Typography>
                            </div>
                            <div className={classes.sellerRow}>
                              <Typography className={classes.rowLabel}>
                                Email
                              </Typography>
                              <Typography className={classes.rowValue}>
                                :{" "}
                                {users?.email !== undefined ? (
                                  users?.email
                                ) : (
                                  <text style={{ color: "red" }}>
                                    email tidak ada
                                  </text>
                                )}
                              </Typography>
                            </div>
                            <div className={classes.sellerRow}>
                              <Typography className={classes.rowLabel}>
                                WA User
                              </Typography>
                              <Typography className={classes.rowValue}>
                                :{" "}
                                {users?.wa !== undefined ? (
                                  users?.wa
                                ) : (
                                  <text style={{ color: "red" }}>
                                    Whatsapp tidak ada
                                  </text>
                                )}
                              </Typography>
                            </div>
                            <div className={classes.sellerRow}>
                              <Typography className={classes.rowLabel}>
                                Tanggal Beli Tiket
                              </Typography>
                              <Typography className={classes.rowValue}>
                                :{" "}
                                {users?.created_at !== undefined ? (
                                  users?.created_at.toDate().toDateString()
                                ) : (
                                  <text style={{ color: "red" }}>
                                    Tanggal Beli Tiket tidak ada
                                  </text>
                                )}
                              </Typography>
                            </div>
                            <div className={classes.sellerRow}>
                              <Typography className={classes.rowLabel}>
                                Status
                              </Typography>
                              <Typography className={classes.rowValue}>
                                :{" "}
                                {users?.status !== undefined ? (
                                  users?.status
                                ) : (
                                  <text style={{ color: "red" }}>-</text>
                                )}
                              </Typography>
                            </div>
                          </div>
                        </Paper>
                      </Grid>
                    );
                  })
              : dataComp
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  ?.map?.((users) => {
                    return (
                      <>
                        <Grid key={users?.id}>
                          <Paper variant="outlined" className={classes.paper}>
                            <div className={classes.topBox2}>
                              <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Nama
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.nama !== undefined ? (
                                    users?.nama
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      nama tidak ada
                                    </text>
                                  )}
                                </Typography>
                              </div>
                            </div>

                            <Grid container item xs={12}>
                              <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Email
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.email !== undefined ? (
                                    users?.email
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      email tidak ada
                                    </text>
                                  )}
                                </Typography>
                              </div>
                              <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Whatsapp
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.wa !== undefined ? (
                                    users?.wa
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      Whatsapp tidak ada
                                    </text>
                                  )}
                                </Typography>
                              </div>
                              <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Status
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.status !== undefined ? (
                                    users?.status
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      Status tidak ada
                                    </text>
                                  )}
                                </Typography>
                              </div>
                              <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Tanggal Beli Tiket
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.created_at !== undefined ? (
                                    users?.created_at.toDate().toDateString()
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      Tanggal Beli Tiket tidak ada
                                    </text>
                                  )}
                                </Typography>
                              </div>
                              <div className={classes.sellerRow}>
                                <Typography className={classes.rowLabel}>
                                  Link Bayar
                                </Typography>
                                <Typography className={classes.rowValue}>
                                  :{" "}
                                  {users?.payment_data?.invoice_url !==
                                  undefined ? (
                                    users?.payment_data?.invoice_url
                                  ) : (
                                    <text style={{ color: "red" }}>
                                      Link bayar tidak ada
                                    </text>
                                  )}
                                </Typography>
                              </div>
                            </Grid>
                          </Paper>
                        </Grid>
                      </>
                    );
                  })}
            <Pagination
              className={classes.pagination}
              count={noOfPages}
              page={page}
              onChange={handleChangePage}
              defaultPage={1}
              showFirstButton
              showLastButton
              color="primary"
            />
          </div>
        </Paper>
      </Container>
    </>
  );
}

export default Tiket;
