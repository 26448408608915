import React, { useState, useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { set, addDays } from "date-fns";
import { useSnackbar } from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import {
    firestore,
    FieldValue,
} from "../../../components/FirebaseProvider";
import { useData } from "../../../components/DataProvider";

const AddOngkir = ({ dialog: { mode, open, data, id }, handleClose }) => {
    const { company_id } = useData();

    const { enqueueSnackbar } = useSnackbar();
    const [form, setForm] = useState({
        nama: "",
        deskripsi: "",
        max_promo_ongkir: "",
    });
    const [error, setError] = useState({
        nama: "",
        deskripsi: "",
        max_promo_ongkir: "",
    });
    const [startDate, handleStartDateChange] = useState(
        set(new Date(), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
        })
    );

    const [endDate, handleEndDateChange] = useState(
        set(startDate, {
            hours: 47,
            minutes: 59,
            seconds: 59,
            milliseconds: 999,
        })
    );
    useEffect(() => {
        handleEndDateChange(
            set(startDate, {
                hours: 47,
                minutes: 59,
                seconds: 59,
                milliseconds: 999,
            })
        );
    }, [startDate]);

    const dates = startDate;
    const maxDate = addDays(dates, 30);
    const [isSubmitting, setSubmitting] = useState(false);
    useEffect(() => {
        const defaultData = {
            nama: "",
            deskripsi: "",
            max_promo_ongkir: "",
        };
        const defaultError = {
            nama: "",
            deskripsi: "",
            max_promo_ongkir: "",
        };
        if (mode === "Tambah") {
            setForm(defaultData);
            setError(defaultError);
        } else if (mode === "Edit") {
            setForm(data);
            setError(defaultError);
        }
    }, [mode, data]);
    const handleChange = (e) => {
        let value = e.target.value;
        if (e.target.type === "number") {
            value = parseInt(value);
        }
        setForm({
            ...form,
            [e.target.name]: value,
        });

        setError({
            ...error,
            [e.target.name]: "",
        });
    };
    const validate = async () => {
        const newError = { ...error };

        if (!form.nama) {
            newError.nama = "Nama promo harus di isi";
        }

        if (!form.deskripsi) {
            newError.deskripsi = "Deskripsi promo harus di isi";
        }
        if (!form.max_promo_ongkir) {
            newError.max_promo_ongkir = "Mak ongkir promo harus di isi";
        }
        return newError;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const findError = await validate();

        // console.log(findError);
        if (Object.values(findError).some((m) => m !== "")) {
            setError(findError);
        } else {
            setSubmitting(true);
            try {
                const promCol = firestore.collection(`promo_ongkir`);
                if (mode === "Tambah") {
                    await promCol.add({
                        ...form,
                        begins_at: startDate,
                        end_at: endDate,
                        status: "scheduled",
                        created_at: FieldValue.serverTimestamp(),
                        updated_at: FieldValue.serverTimestamp(),
                    });
                    enqueueSnackbar("Promo ditambahkan", { variant: "success" });
                } else if (mode === "Edit") {
                    await promCol.doc(id).set(
                        {
                            ...form,
                            begins_at: startDate,
                            end_at: endDate,
                            created_at: FieldValue.serverTimestamp(),
                            updated_at: FieldValue.serverTimestamp(),
                        },
                        { merge: true }
                    );
                    enqueueSnackbar("Promo Berhasil Ditambahkan", { variant: "success" });
                }
                handleClose();
            } catch (e) {
                const newError = {};
                newError.title = e.message;

                setError(newError);
            }

            setSubmitting(false);
        }
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
        >
            {mode === "Edit" ? (
                <DialogTitle>Edit Promo</DialogTitle>
            ) : (
                <DialogTitle>Tambah Promo Baru</DialogTitle>
            )}
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="nama"
                            name="nama"
                            label="Nama Promo"
                            value={form.nama || ""}
                            onChange={handleChange}
                            error={error.nama ? true : false}
                            helperText={error.nama}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="max_promo_ongkir"
                            name="max_promo_ongkir"
                            label="Max promo ongkir"
                            type="number"
                            value={form.max_promo_ongkir || ""}
                            onChange={handleChange}
                            error={error.max_promo_ongkir ? true : false}
                            helperText={error.max_promo_ongkir}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="deskripsi"
                            name="deskripsi"
                            label="Deskripsi Promo"
                            value={form.deskripsi || ""}
                            onChange={handleChange}
                            error={error.deskripsi ? true : false}
                            helperText={error.deskripsi}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={6}>
                            {mode === "Tambah" ? (
                                <KeyboardDatePicker
                                    minDate={new Date()}
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Start Date"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {mode === "Tambah" ? (
                                <KeyboardDatePicker
                                    minDate={endDate}
                                    maxDate={maxDate}
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="End Date"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}> Batal</Button>
                <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
                    Buat
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddOngkir;
