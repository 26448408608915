/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useDocument, } from "react-firebase-hooks/firestore";
import { firestore } from "../../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useData } from "../../../../components/DataProvider";

export default function SicepatAPI({ user }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { company_id } = useData();
    //   const kurirOptions = [
    //     { label: "JNE", value: "jne" },
    //     { label: "JNT", value: "jnt" },
    //     { label: "SiCepat", value: "sicepat" },
    //     { label: "PT.Pos", value: "pos" },
    //     { label: "TIKI", value: "tiki" },
    //     { label: "PCP", value: "pcp" },
    //     { label: "ESL", value: "esl" },
    //     { label: "RPX", value: "rpx" },
    //   ];

    const APIDoc = firestore.doc(
        `settings/shipping/availables/directapi`
    );

    //   const shippingSetting = firestore.doc(
    //     `settings/shipping/`
    //   );

    //   const alamatSetting = firestore.doc(`settings/alamat/`);

    const [setting, settingLoading] = useDocument(APIDoc);

    // sicepat_reference
    const sicepatReff = firestore.doc(`settings/sicepat_reference_number`)

    const [sicepatRefdata, setSicepat] = useDocument(sicepatReff)
    console.log(sicepatRefdata?.data())
    const [key, setKey] = useState("");
    const [keyPickup, setKeyPickup] = useState("");
    const [url, setUrl] = useState('');
    const [urlPickup, setUrlPickup] = useState("");
    // const [username, setUsername] = useState('')
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [now, setNow] = useState('')
    const handleClickSave = async () => {
        await APIDoc.set(
            {
                sicepat: {
                    key: key,
                    url: url,
                    // username: username,
                    keyPickup: keyPickup,
                    urlPickup: urlPickup
                }
            },
            { merge: true }
        );



        enqueueSnackbar("Setting Berhasil Diperbarui", { variant: "success" });
    };
    const handleClickSaveSicepatReff = async () => {
        await sicepatReff.set(
            {
                start: start,
                now: now,
                end: end
            },
            { merge: true }
        );



        enqueueSnackbar("Setting Berhasil Diperbarui", { variant: "success" });
    };
    //   const handleClickTest = () => {
    //     const result = sendQontak();
    //     console.log(result);
    //   };

    //   const sendQontak = async () => {
    //     await axios
    //       .post(
    //         `https://console.zenziva.net/wareguler/api/sendWA/`,
    //         {
    //           // userkey: userkey,
    //           // passkey: apikey,
    //           to: "+628121075203",
    //           message: `Test mengirimkan pesan via Zenziva`,
    //         },
    //         {
    //           headers: {
    //             "Content-Type": "application/json",
    //           },
    //         }
    //       )
    //       .then(
    //         (response) => {
    //           return response;
    //         },
    //         (error) => {
    //           console.log(error);
    //           return error;
    //         }
    //       );
    //   };
    useEffect(() => {
        if (setting) {
            setKey(setting.data()?.sicepat?.key);
            setUrl(setting.data()?.sicepat?.url);
            setKeyPickup(setting.data()?.sicepat?.keyPickup);
            setUrlPickup(setting.data()?.sicepat?.urlPickup);
        }
    }, [setting]);
    useEffect(() => {
        if (sicepatRefdata) {
            setStart(sicepatRefdata.data()?.start);
            setNow(sicepatRefdata.data()?.now);
            setEnd(sicepatRefdata.data()?.end)
        }
    }, [sicepatRefdata]);

    //   const getSelectedKurir = () => {
    //     let kurirList = [];
    //     url.map((kurir) => {
    //       kurirList.push({
    //         label: kurirOptions.filter((k) => k.value === kurir)[0].label,
    //         value: kurir,
    //       });
    //     });
    //     return kurirList;
    //   };

    //filterSelectedOptions not working as excepected, so we remove options dynamically
    //   const getUnurl = () => {
    //     let kurirList = [];
    //     kurirOptions.map((kurir) => {
    //       if (
    //         url.find((i) => {
    //           return kurir.value === i;
    //         })
    //       ) {
    //         //do nothing
    //       } else {
    //         //push to array
    //         kurirList.push(kurir);
    //       }
    //     });
    //     return kurirList;
    //   };

    return (
        <>
            {settingLoading || setSicepat ? <p>Loading...</p> : null}
            {setting && (
                <div>
                    <Typography className={classes.btm}>
                        Koneksi dan Pengaturan API untuk{" "}
                        <a
                            target="_blank"
                            href="https://jne.com/"
                            rel="noreferrer"
                            className={classes.link}
                        >
                            Sicepat
                        </a>
                    </Typography>
                    <form className={classes.formWrap} noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="key"
                                    value={key}
                                    onChange={(e) => setKey(e.target.value)}
                                    label="Key"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="keyPickup"
                                    value={keyPickup}
                                    onChange={(e) => setKeyPickup(e.target.value)}
                                    label="Key Pickup"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="url"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                    label="URL"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="urlPickup"
                                    value={urlPickup}
                                    onChange={(e) => setUrlPickup(e.target.value)}
                                    label="URL Pickup"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Button
                                    disabled={key ? false : true}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleClickSave()}
                                    fullWidth
                                >
                                    Save
                                </Button>
                            </Grid>

                        </Grid>
                    </form>

                </div>
            )}

            {
                sicepatRefdata && <>
                    <Typography className={classes.btm} style={{ marginTop: 36 }}>
                        Sicepat Reference Number
                    </Typography>
                    <form className={classes.formWrap} noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="start"
                                    value={start}
                                    onChange={(e) => setStart(e.target.value)}
                                    label="Start"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="now"
                                    value={now}
                                    onChange={(e) => setNow(e.target.value)}
                                    label="Now"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="end"
                                    value={end}
                                    onChange={(e) => setEnd(e.target.value)}
                                    label="End"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={6}>
                                <Button
                                    disabled={!start || !end || !now}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleClickSaveSicepatReff()}
                                    fullWidth
                                >
                                    Save
                                </Button>
                            </Grid>

                        </Grid>
                    </form></>
            }
        </>
    );
}
