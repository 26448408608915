import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    paperWrap: {
        marginTop: 35,
        marginBottom: 20,
    },
    paper: {
        margin: 12,
        padding: theme.spacing(2),
        height: '100%',
        overflowY: 'auto',
    },
    pageWrap: {
        padding: '25px',
        paddingBottom: 5,
        borderRadius: 8,
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
    grid: {
        maxHeight: 350,
        marginBottom: 40,
    },
    // pageTitle: {
    //     paddingTop: 15,
    //     marginLeft: 12,
    // },
    margin: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    fab1: {
        display: 'flex',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(6)
    },
    notifRow: {
        marginBottom: 10,
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
        paddingBottom: 5,
    },
    notifLabel: {
        color: theme.palette.textColor.lessTextColor,
        fontSize: 12,
    },
    notifValue: {
        color: theme.palette.textColor.primeTextColor,
    },
    noOrderMsg: {
        height: 40,
        margin: '19%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: theme.palette.textColor.primeTextColor,
        fontSize: 15,
        padding: '0 10px',
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

}))


export default useStyles;