import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// material-ui
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    firestore,
    storage,
    FieldValue,
} from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import useStyles from "./styles";
import PageLoading from "../../../components/loading/pageLoading";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useData } from "../../../components/DataProvider";

function AddSlider({ dialog: { mode, open, data, id }, handleClose }) {
    const { enqueueSnackbar } = useSnackbar();

    const { treeDataKategori, brandList, peoductList } = useData();

    const classes = useStyles();

    const { company_id } = useData();

    const [form, setForm] = useState({
        target_type: "",
        url: "",
        target_id: "",
        target_nama: "",
        sorting_number: ""
    });
    console.log(data);
    const [error, setError] = useState({
        target_type: "",
        url: "",
        target_id: "",
        target_nama: "",
    });
    useEffect(() => {
        const defaultData = {
            target_type: "",
            url: "",
            target_id: "",
            target_nama: "",
        };

        const defaultError = {
            target_type: "",
            url: "",
            target_id: "",
            target_nama: "",
        };
        if (mode === "Tambah") {
            setForm(defaultData);
            setError(defaultError);
        } else if (mode === "Edit") {
            setForm(data);
            setError(defaultError);
        }
    }, [mode, data]);

    const [isSubmitting, setSubmitting] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value;
        if (e.target.type === "number") {
            value = parseInt(value);
        }
        console.log(e.target.type);

        setForm({
            ...form,
            [e.target.name]: value,
        });

        setError({
            ...error,
            [e.target.name]: "",
        });
    };

    const validate = () => {
        const newError = {};

        if (!form.target_type) {
            newError.target_type = "Target Klik slider wajib diisi";
        }
        if (!form.url) {
            newError.url = "url slider wajib diisi";
        }
        if (!form.target_id) {
            newError.target_id = "target id slider wajib diisi";
        }
        // if (!form.target_nama) {
        //     newError.target_nama = 'target nama slider wajib diisi';
        // }

        return newError;
    };

    const handleSimpan = async (e) => {
        e.preventDefault();

        const findErros = validate();
        if (Object.values(findErros).some((err) => err !== "")) {
            setError(findErros);
        } else {
            setSubmitting(true);
            try {
                const sliderCol = firestore.collection(`slider`);

                if (mode === "Tambah") {
                    await sliderCol.add({
                        ...form,
                        target_id: form.target_id.id || form.target_id,
                        target_nama: form.target_id.nama || form.target_nama || "",
                        created_at: FieldValue.serverTimestamp(),
                        updated_at: FieldValue.serverTimestamp(),
                    });
                    enqueueSnackbar("Slider Berhasil Ditambahkan", {
                        variant: "success",
                    });
                } else if (mode === "Edit") {
                    console.log("here", mode, id);
                    await sliderCol.doc(id).set(
                        {
                            ...form,
                            target_id: form.target_id.id || form.target_id,
                            target_nama: form.target_id.nama || form.target_nama || "",
                            sorting_number: parseInt(form.sorting_number),
                            updated_at: FieldValue.serverTimestamp(),
                        },
                        {
                            merge: true,
                        }
                    );
                    enqueueSnackbar("Slider Berhasil Diperbarui", { variant: "success" });
                }

                handleClose();
            } catch (e) {
                setError(e.message);
            }
            setSubmitting(false);
        }
    };
    console.log('form', form, form?.id);

    const handleUploadFile = async (e) => {
        const file = e.target.files[0];

        if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
            setError((error) => ({
                ...error,
                url: `Tipe file tidak didukung: ${file.type}`,
            }));
        } else if (file.size >= 512000) {
            setError((error) => ({
                ...error,
                url: `Ukuran file terlalu besar > 500KB`,
            }));
        } else {
            const reader = new FileReader();

            reader.onabort = () => {
                setError((error) => ({
                    ...error,
                    url: `Proses pembacaan file dibatalkan`,
                }));
            };

            reader.onerror = () => {
                setError((error) => ({
                    ...error,
                    url: "File tidak bisa dibaca",
                }));
            };

            reader.onload = async () => {
                setError((error) => ({
                    ...error,
                    url: "",
                }));
                setSubmitting(true);
                try {
                    const sliderStorageRef = storage.ref(`slider`);

                    const urlExt = file.name.substring(file.name.lastIndexOf("."));

                    const urlRef = sliderStorageRef.child(
                        `slider_${form?.sorting_number}_${Date.now()}${urlExt}`
                    );

                    const urlSnapshot = await urlRef.putString(reader.result, "data_url");

                    const urlUrl = await urlSnapshot.ref.getDownloadURL();

                    setForm((currentForm) => ({
                        ...currentForm,
                        url: urlUrl,
                    }));

                    // setSomethingChange(true);
                } catch (e) {
                    setError((error) => ({
                        ...error,
                        url: e.message,
                    }));
                }

                setSubmitting(false);
            };

            reader.readAsDataURL(file);
        }
    };

    const kategCol = firestore.collection(`categories`);

    const [kategData, loadingKateg] = useCollectionData(kategCol, {
        idField: "id",
    });

    const kateg2 = kategData?.filter((katego) => {
        return katego.level === 2;
    });

    const kateg3 = kategData?.filter((kategor) => {
        return kategor.level === 3;
    });

    console.log(peoductList);

    const target = [
        "kategori1",
        "kategori2",
        "kategori3",
        "brand",
        "promo",
        "produk",
        "webview",
    ];

    if (loadingKateg) {
        return <PageLoading />;
    }

    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
            fullWidth
        >
            {mode === "Edit" ? (
                <DialogTitle>Edit Slider</DialogTitle>
            ) : (
                <DialogTitle>Tambah Slider Baru</DialogTitle>
            )}
            <DialogContent dividers>
                <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Autocomplete
                        id="target_type"
                        name="target_type"
                        margin="normal"
                        required
                        value={form?.target_type ?? []}
                        onChange={(event, value) => {
                            handleChange({ target: { name: "target_type", value } });
                        }}
                        disabled={isSubmitting}
                        options={target}
                        renderInput={(params) => (
                            <TextField
                                helperText={error?.target_type}
                                error={error?.target_type ? true : false}
                                {...params}
                                label="Tipe Target"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>

                {
                    form.target_type === "kategori1" &&
                    <Grid item xs={12}>
                        <Autocomplete
                            id="target_id"
                            name="target_id"
                            margin="normal"
                            required
                            value={form.target_id}
                            onChange={(event, value) => {
                                handleChange({ target: { name: "target_id", value } });
                            }}
                            disabled={isSubmitting}
                            options={treeDataKategori}
                            getOptionLabel={(option) => option?.nama}
                            renderInput={(params) => (
                                <TextField
                                    helperText={error?.target_id}
                                    error={error?.target_id ? true : false}
                                    {...params}
                                    label="Target ID"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                }
                {
                    form.target_type === "kategori2" &&
                    <Grid item xs={12}>
                        <Autocomplete
                            id="target_id"
                            name="target_id"
                            margin="normal"
                            required
                            value={form.target_id}
                            onChange={(event, value) => {
                                handleChange({ target: { name: "target_id", value } });
                            }}
                            disabled={isSubmitting}
                            options={kateg2}
                            getOptionLabel={(option) => option?.nama}
                            renderInput={(params) => (
                                <TextField
                                    helperText={error.target_id}
                                    error={error.target_id ? true : false}
                                    {...params}
                                    label="Target ID"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                }
                {
                    form.target_type === "kategori3" &&
                    <Grid item xs={12}>
                        <Autocomplete
                            id="target_id"
                            name="target_id"
                            margin="normal"
                            required
                            value={form.target_id}
                            onChange={(event, value) => {
                                handleChange({ target: { name: "target_id", value } });
                            }}
                            disabled={isSubmitting}
                            options={kateg3}
                            getOptionLabel={(option) => option?.nama}
                            renderInput={(params) => (
                                <TextField
                                    helperText={error.target_id}
                                    error={error.target_id ? true : false}
                                    {...params}
                                    label="Target ID"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                }
                {
                    form.target_type === "brand" &&
                    <Grid item xs={12}>
                        <Autocomplete
                            id="target_id"
                            name="target_id"
                            margin="normal"
                            required
                            value={form.target_id}
                            onChange={(event, value) => {
                                handleChange({ target: { name: "target_id", value } });
                            }}
                            disabled={isSubmitting}
                            options={brandList}
                            getOptionLabel={(option) => option?.nama}
                            renderInput={(params) => (
                                <TextField
                                    helperText={error.target_id}
                                    error={error.target_id ? true : false}
                                    {...params}
                                    label="Target ID"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                }
                {
                    form.target_type === "produk" &&
                    <Grid item xs={12}>
                        <Autocomplete
                            id="target_id"
                            name="target_id"
                            margin="normal"
                            required
                            value={form?.target_id || ''}
                            onChange={(event, value) => {
                                handleChange({ target: { name: "target_id", value } });
                            }}
                            disabled={isSubmitting}
                            options={peoductList}
                            getOptionLabel={(option) => option?.nama}
                            renderInput={(params) => (
                                <TextField
                                    helperText={error?.target_id}
                                    error={error?.target_id ? true : false}
                                    {...params}
                                    label=" Target ID"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                }
                {
                    form.target_type === "promo" &&
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="target_id"
                            name="target_id"
                            label="Target ID"
                            value={form.target_id || ""}
                            onChange={handleChange}
                            error={error.target_id ? true : false}
                            helperText={error.target_id}
                            fullWidth
                            multiline
                            variant="outlined"
                        />
                    </Grid>
                }
                {
                    form.target_type === "webview" &&
                    <>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="off"
                                disabled={isSubmitting}
                                id="target_id"
                                name="target_id"
                                label="Target ID"
                                value={form.target_id || ""}
                                onChange={handleChange}
                                error={error.target_id ? true : false}
                                helperText={error.target_id}
                                fullWidth
                                multiline
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="off"
                                disabled={isSubmitting}
                                id="target_nama"
                                name="target_nama"
                                label="Target Nama"
                                value={form.target_nama || ""}
                                onChange={handleChange}
                                error={error.target_nama ? true : false}
                                helperText={error.target_nama}
                                fullWidth
                                multiline
                                variant="outlined"
                            />
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <TextField
                        autoComplete="off"
                        disabled={isSubmitting}
                        type="number"
                        id="sort"
                        name="sorting_number"
                        label="Sort"
                        value={form.sorting_number || ""}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel className={classes.thumbnailLabel}>
                        Thumbnail Slider
                    </InputLabel>
                    <div className={classes.uploadFotoKursus}>
                        {form.url && (
                            <img
                                src={form.url}
                                className={classes.previewFotoKursus}
                                alt=""
                            />
                        )}
                        <input
                            className={classes.hideInputFile}
                            type="file"
                            name="url"
                            id="upload-url-slider"
                            accept="image/jpeg,image/png,image/jpg"
                            onChange={handleUploadFile}
                        />
                        <label htmlFor="upload-url-slider">
                            <Button
                                disabled={isSubmitting}
                                name="url"
                                variant="outlined"
                                component="span"
                                endIcon={<PermMediaIcon/>}
                            >
                                Pilih Gambar
                            </Button>
                        </label>
                        {error.url && <Typography color="error">{error.url}</Typography>}
                    </div>
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isSubmitting} onClick={handleClose}>
                    Batal
                </Button>
                <Button disabled={isSubmitting} onClick={handleSimpan} color="primary">
                    Simpan
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddSlider.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default AddSlider;
