import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { firestore, FieldValue, auth } from '../../../components/FirebaseProvider';
import { useSnackbar } from 'notistack';
// import { useData } from '../../../components/DataProvider';
import Autocomplete from '@material-ui/lab/Autocomplete';

function AddSuperadmin({ open, handleClose }) {

    // const { company_id } = useData();

    const [form, setForm] = useState({
        email: '',
        password: '',
        superadmin_code: ''

    });


    const [error, setError] = useState({
        email: '',
        password: '',
        superadmin_code: ''

    });

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
        // console.log(form)

        setError({
            ...error,
            [e.target.name]: ''
        })
    }


    console.log('form', form)

    const validate = async () => {
        const newError = { ...error };

        if (!form.email) {
            newError.email = 'Email harus diisi';
        }

        if (!form.superadmin_code) {
            newError.superadmin_code = 'superadmin code harus diisi';
        }

        if (!form.password) {
            newError.password = 'Password harus diisi';
        }
        if (form.password?.length < 8) {
            newError.password = 'Password harus minimal 8 karakter';
        }



        return newError;
    }
    // console.log(form.password?.length)

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                await auth.createUserWithEmailAndPassword(form.email, form.password);
                await firestore.collection(`superadmin`).add({
                    email: form.email,
                    password: form.password,
                    superadmin_code: form.superadmin_code,
                    created_at: FieldValue.serverTimestamp(),
                    updated_at: FieldValue.serverTimestamp(),
                    // superadmin_id: user.uid
                });


                handleClose()
                enqueueSnackbar('Superadmin Berhasil Ditambahkan', { variant: "success" });
            } catch (e) {
                const newError = {};

                switch (e.code) {

                    case 'auth/email-already-in-use':
                        newError.email = 'Email sudah terdaftar';
                        break;
                    case 'auth/invalid-email':
                        newError.email = 'Email tidak valid';
                        break;
                    case 'auth/weak-password':
                        newError.password = 'Password lemah'
                        break;
                    case 'auth/operation-not-allowed':
                        newError.email = "Metode email dan password tidak didukung";
                        break;
                    default:
                        newError.email = 'Terjadi kesalahan silahkan cobalagi';
                        break;
                }


                setError(newError);
            }

            setSubmitting(false)
        }
    }


    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Tambah Super Admin</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="email"
                            name="email"
                            label="Email"
                            onChange={handleChange}
                            error={error.email ? true : false}
                            helperText={error.email}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="password"
                            name="password"
                            label="Password"
                            onChange={handleChange}
                            error={error.password ? true : false}
                            helperText={error.password}
                            fullWidth
                            multiline
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="superadmin_code"
                            name="superadmin_code"
                            label="Superadmin Code"
                            onChange={handleChange}
                            error={error.superadmin_code ? true : false}
                            helperText={error.superadmin_code}
                            fullWidth
                            multiline
                            variant="outlined"
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                >Batal</Button>
                <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    color="primary"
                >
                    Buat
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddSuperadmin.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default AddSuperadmin;
