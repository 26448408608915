import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Table from './table';
import Edit from './edit';
import useStyles from './styles';

function Product() {

  const classes = useStyles();
  return (
    <div className={classes.pageWrap}>

      <Switch>
        <Route path="/product/edit/:productId" component={Edit} />
        <Route path="/product" component={Table} />
        <Redirect to="/product" />
      </Switch>

    </div>

  );
}

export default Product;
