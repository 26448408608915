import React, { useEffect, useState } from 'react';
import TabPanel from '../../../components/TabPanel';
import {  firestore } from '../../../components/FirebaseProvider';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import Container from '@material-ui/core/Container';
// lab
import ExcelRead from './uploadPickup';
// core
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// ico
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default function Pickup({ value, uid }) {
    const classes = useStyles();
    // const col = firestore.collection(`settings/shipping/availables/sicepat/coverage_pickup`);
    // const [snapshot, loading] = useCollectionData(col, { idField: 'id' });

    // useEffect(() => {
    //     if (snapshot && !snapshot.empty) {
    //         setTable(table => {
    //             return {
    //                 ...table,
    //                 data: snapshot?.docs?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(doc => {
    //                     return {
    //                         uid: doc.id,
    //                         ...doc.data()
    //                     }
    //                 })
    //             }
    //         })
    //     } else {
    //         setTable(table => {
    //             return {
    //                 ...table,
    //                 data: []
    //             }
    //         })
    //     }
    // }, [snapshot]);

    //initial state
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);

    //loading initial data
    useEffect(() => {
        const fetchData = async () => {
            await firestore.collection('settings/shipping/coverage_pickup')
                .orderBy('province_name', 'asc') //order using firestore timestamp
                .limit(5) //change limit value as your need
                .onSnapshot(function (querySnapshot) {
                    var items = [];
                    querySnapshot.forEach(function (doc) {
                        items.push({ key: doc.id, ...doc.data() });
                    });
                    setList(items);
                })
        };
        fetchData();
    }, []);

    //next button function
    const showNext = ({ item }) => {
        if (list.length === 0) {
            //use this to show hide buttons if there is no records
        } else {
            const fetchNextData = async () => {
                await firestore.collection('settings/shipping/coverage_pickup')
                    .orderBy('province_name', 'asc') //order using firestore timestamp
                    .limit(5) //change limit value as your need
                    .startAfter(item.province_name) //we pass props item's first province_name timestamp to do start after you can change as per your wish
                    .onSnapshot(function (querySnapshot) {
                        const items = [];
                        querySnapshot.forEach(function (doc) {
                            items.push({ key: doc.id, ...doc.data() });
                        });
                        setList(items);
                        setPage(page + 1) //in case you like to show current page number you can use this
                    })
            };
            fetchNextData();
        }
    };

    //previous button function
    const showPrevious = ({ item }) => {
            const fetchPreviousData = async () => {
                await firestore.collection('settings/shipping/coverage_pickup')
                    .orderBy('province_name', 'asc')
                    .endBefore(item.province_name) //this is important when we go back
                    .limitToLast(5) //this is important when we go back
                    .onSnapshot(function (querySnapshot) {
                        const items = [];
                        querySnapshot.forEach(function (doc) {
                            items.push({ key: doc.id, ...doc.data() });
                        });
                        setList(items);
                        setPage(page - 1)
                    })
            };
        fetchPreviousData();
    };
    // console.log(snapshot?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))



    // if (loading) {
    //     return <PageLoading />
    // }

    console.log('DATA_LIST =>' , list);

    return <TabPanel value={value} index="pickup">
        {/* <Typography className={classes.pageSubTitle} component="h1" variant="h5">Komisi</Typography> */}
        <div className={classes.root}>
            <Container className={classes.tableCon} maxWidth="lg">
                <div className={classes.tableWrap}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead style={{ backgroundColor: '#fae0be' }}>
                                <TableRow>
                                    <TableCell>Provinsi</TableCell>
                                    <TableCell >Kota/Kab</TableCell>
                                    <TableCell >Kecamatan</TableCell>
                                    <TableCell >Desa</TableCell>
                                    <TableCell >Kode Pos</TableCell>
                                    <TableCell >Kode Sicepat</TableCell>
                                    <TableCell >Coverage Pickup</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((row) => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.province_name}
                                        </TableCell>
                                        <TableCell >{row.city_name}</TableCell>
                                        <TableCell >{row.district_name}</TableCell>
                                        <TableCell >{row.subdistrict_name}</TableCell>
                                        <TableCell >{row.postal_code}</TableCell>
                                        <TableCell >{row.kode_sicepat}</TableCell>
                                        <TableCell >{row.coverage_pickup}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <div className={classes.pagination}>
                        {
                            //show previous button only when we have items
                            //pass first item to showPrevious function 
                            page === 1 ? '' :
                                <Button onClick={() => showPrevious({ item: list[0] })}><ChevronLeftIcon />Previous</Button>
                        }

                        {
                            //show next button only when we have items
                            //pass last item to showNext function 
                            list.length < 5 ? '' :
                                <Button onClick={() => showNext({ item: list[list.length - 1] })}>Next<ChevronRightIcon /></Button>
                        }
                    </div>
                </div>
                <ExcelRead />
                {/* <ExcelCod /> */}
            </Container>







        </div>

    </TabPanel>
}