import React, { useEffect, useState } from "react";
import TabPanel from "../../../components/TabPanel";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";
import PageLoading from "../../../components/loading/pageLoading";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import { useSnackbar } from "notistack";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useData } from "../../../components/DataProvider";
import TextField from "@material-ui/core/TextField";

export default function Vdocipher({ value }) {
  const { company_id } = useData();
  const settDoc = firestore.doc(`/settings/vdocipher`);
  const [settSnapshot, settLoading] = useDocument(settDoc);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [form, setForm] = useState({
    api_secret: "",
    videoid: "",
    expirydays: 2,
  });

  const [error, setError] = useState({});

  useEffect(() => {
    if (settSnapshot) {
      const data = settSnapshot.data();
      setForm({
        ...data,
      });
    }
  }, [settSnapshot]);

  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    setSubmitting(true);
    try {
      await settDoc.set(
        {
          ...form,
        },
        { merge: true }
      );
      enqueueSnackbar("Perubahan berhasil disimpan", { variant: "success" });
    } catch (e) {
      setError(e.message);
      enqueueSnackbar(`Perubahan gagal disimpan,${e.message}`, {
        variant: "error",
      });
    }
    setSubmitting(false);
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") {
      value = parseInt(value);
    }
    // console.log(value)
    setForm({
      ...form,
      [e.target.name]: value,
    });
  };

  if (settLoading) {
    return <PageLoading />;
  }

  // console.log(formKomisi)
  return (
    <TabPanel value={value} index="vdocipher">
      {/* <Typography className={classes.pageSubTitle} component="h1" variant="h5">Komisi</Typography> */}
      <Grid container>
        <Grid direction="column" lg={8}>
          <TextField
            className={classes.grid}
            autoComplete="off"
            disabled={isSubmitting}
            id="api_secret"
            name="api_secret"
            label="API Secret"
            value={form.api_secret || ""}
            onChange={handleChange}
            type="text"
            variant="outlined"
            fullWidth
          />
          {/* <TextField
            className={classes.grid}
            autoComplete="off"
            disabled={isSubmitting}
            id="videoid"
            name="videoid"
            label="Video ID"
            value={form.videoid || ""}
            onChange={handleChange}
            type="text"
            variant="outlined"
            fullWidth
          />
          <TextField
            className={classes.grid}
            autoComplete="off"
            disabled={isSubmitting}
            id="expirydays"
            name="expirydays"
            label="Expiry Days"
            value={form.expirydays || ""}
            onChange={handleChange}
            type="number"
            variant="outlined"
            fullWidth
          /> */}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={1}>
        <Button
          className={classes.btnSave}
          disabled={isSubmitting}
          onClick={handleSubmit}
          size="large"
          color="primary"
          variant="contained"
          fullWidth
        >
          {" "}
          Simpan{" "}
        </Button>
      </Grid>
    </TabPanel>
  );
}
