import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';

import Button from '@material-ui/core/Button';
import useStyles from './styles';
import { currency } from '../../../utils/formatter';
import { functions } from '../../../components/FirebaseProvider';


function CekKredit({ dialog: { id, open }, handleClose }) {
    const classes = useStyles();

    const [tracking, setTracking] = useState([]);

    useEffect(() => {
        const getTotalTransaksiSeller = async () => {
            const transaksi = functions.httpsCallable("statistic-getTotalKomisi");
            const res = await transaksi({ user_id: id });
            //console.log(res)

            setTracking(res?.data);
        };

        getTotalTransaksiSeller();
    }, [id]);

    // console.log(tracking)

    return <Dialog

        open={open}
        onClose={handleClose}
        fullWidth
    >
        <DialogTitle>Data Komisi</DialogTitle>
        <DialogContent dividers>
            <div className={classes.jp1}>
                <Typography>Total Komisi : {currency(tracking?.[0]?.total_komisi)}</Typography>
                <Typography>Total Pencairan (dalam proses) : {currency(tracking?.[0]?.total_pencairan_processing)}</Typography>
                <Typography>Total Pencairan Sukses : {currency(tracking?.[0]?.total_pencairan_success)}</Typography>
            </div>

        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose}
            >Kembali</Button>
        </DialogActions>
    </Dialog>
}
CekKredit.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}
export default CekKredit;