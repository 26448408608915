import React, { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TabPanel from "../../../components/TabPanel";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
// import RajaongkirSetting from "./shipping/rajaongkir";
// import ShipperSetting from "./shipping/shipper";
import { useData } from "../../../components/DataProvider";
import JneAPI from "./shipping/jneAPI";
import SicepatAPI from "./shipping/sicepatAPI";

export default function Shipping({ value }) {
  const classes = useStyles();

  const [selected, setSelected] = useState("jne");
  const { company_id } = useData();

  const shippingSetting = firestore.doc(
    `settings/shipping`
  );
  const [setting] = useDocument(shippingSetting);

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const selectedSender = () => {
    switch (selected) {
      case "jne":
        return <JneAPI user={company_id} />;
        break;
      case "sicepat":
        return <SicepatAPI user={company_id} />;
        break;
      default:
        return "";
        break;
    }
  };

  useEffect(() => {
    if (setting) {
      setSelected(setting.data()?.use_available);
    }
  }, [setting]);

  return (
    <>
      <TabPanel value={value} index="shipping">
        <Typography>
          Shipping adalah layanan terkait perhitungan ongkir dan / atau proses
          pengiriman barang.
        </Typography>
        <br />
        {setting && (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <Typography>
                  Pilih layanan  :
                </Typography>
                <RadioGroup
                  aria-label="shipping"
                  name="shipping"
                  value={selected}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="jne"
                    control={<Radio />}
                    label="JNE"
                  />
                  <FormControlLabel
                    value="sicepat"
                    control={<Radio />}
                    label="Sicepat"
                  />
                  <FormControlLabel
                    value="lainnya..."
                    disabled
                    control={<Radio />}
                    label="(Lainnya...)"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <div>{selectedSender()}</div>
            </Grid>
          </Grid>
        )}
      </TabPanel>
    </>
  );
}
