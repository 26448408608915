import React from "react";
import TabPanel from "../../../components/TabPanel";

export default function Autoresponder({ value }) {
  return (
    <TabPanel value={value} index="autoresponder">
      <p>Autoresponder integration setting</p>
    </TabPanel>
  );
}
