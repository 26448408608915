import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useData } from "../../../../components/DataProvider";

export default function WoowaSetting(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { company_id } = useData();
  const woowaSetting = firestore.doc(
    `settings/sender/availables/woowa`
  );

  const [setting, settingLoading] = useDocument(woowaSetting);

  const [key, setKey] = useState("");
  const [newordermessage, setNeworderpmessage] = useState("");
  const [otpmessage, setOtpmessage] = useState("");
  const [paymentreceivedmessage, setPaymentreceivedmessage] = useState("");

  const handleClickSave = async () => {
    await woowaSetting.set(
      {
        key,
        newordermessage,
        otpmessage,
        paymentreceivedmessage,
      },
      { merge: true }
    );

    props.ChangeUseDefaultSender("woowa");

    enqueueSnackbar("Setting Berhasil Diperbarui", { variant: "success" });
  };
  const handleClickTest = () => {
    const result = sendWoowa();
    console.log(result);
  };

  const sendWoowa = async () => {
    await axios
      .post(
        `https://console.zenziva.net/wareguler/api/sendWA/`,
        {
          // userkey: userkey,
          // passkey: apikey,
          to: "+628121075203",
          message: `Test mengirimkan pesan via Zenziva`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(
        (response) => {
          return response;
        },
        (error) => {
          console.log(error);
          return error;
        }
      );
  };
  useEffect(() => {
    if (setting) {
      setKey(setting.data()?.key);
      setOtpmessage(setting.data()?.otpmessage);
      setNeworderpmessage(setting.data()?.newordermessage);
      setPaymentreceivedmessage(setting.data()?.paymentreceivedmessage);
    }
  }, [setting]);

  return (
    <>
      {settingLoading && <p>Loading...</p>}
      {setting && (
        <div>
            <Typography className={classes.btm}>
            Koneksi dan Pengaturan API untuk{" "}
              <a
                target="_blank"
                href="https://woo-wa.com/?ref=802"
                rel="noreferrer"
                className={classes.link}
              >
                Woowa
              </a>
            </Typography>
            <form className={classes.formWrap} noValidate autoComplete="off">
              <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="key"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                  label="Key"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="otpmessage"
                  value={otpmessage}
                  onChange={(e) => setOtpmessage(e.target.value)}
                  label="OTP Message Template"
                  multiline
                  variant="outlined"
                  rows={4}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="newordermessage"
                  value={newordermessage}
                  onChange={(e) => setNeworderpmessage(e.target.value)}
                  label="New Order Message Template"
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="paymentreceivedmessage"
                  value={paymentreceivedmessage}
                  onChange={(e) => setPaymentreceivedmessage(e.target.value)}
                  label="Payment Received Message Template"
                  multiline
                  rows={10}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={key ? false : true}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleClickSave()}
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={true} //{userkey && apikey ? false : true}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleClickTest()}
                  fullWidth
                >
                  Test Sending
                </Button>
              </Grid>
              </Grid>
            </form>
        </div>
      )}
    </>
  );
}
