/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Fab from "@material-ui/core/Fab";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PricingCondition from "./pricingCondition";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";
import useStyles from "./styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
//import TabPanel from "../../../components/TabPanel";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { useData } from "../../../components/DataProvider";
import DeleteIcon from "@material-ui/icons/Delete";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function PricingConditionsList() {
  const classes = useStyles();
  const { company_id } = useData();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const pricingConditions = firestore.collection(
    `pricing_conditions/`
  );

  const [pc, setPc] = useState([]);
  const [
    pricingConditionsList
  ] = useCollection(pricingConditions);

  const [opendialog, setOpendialog] = useState({
    mode: "Tambah",
    open: false,
  });

  const [inputForm, setInputform] = useState({
    condition: "",
  });

  const handleDialogClose = () => {
    setOpendialog({ ...opendialog, open: false });
    setInputform({ ...inputForm, procedure: "" });
  };

  const handleDialogAddPc = () => {
    pricingConditions.doc(inputForm.condition).set({ access_sequence: [] });
    handleDialogClose();
  };

  useEffect(() => {
    let ppList = [];
    if (pricingConditionsList) {
      pricingConditionsList.docs.map((doc, index) => {
        ppList.push(doc.id);
      });
      setPc(ppList);
    }
  }, [pricingConditionsList]);

  return (
    <>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          {pricingConditionsList &&
            pc.map((i, index) => {
              return <Tab label={i} {...a11yProps({ index })} />;
            })}
        </Tabs>

        {pricingConditionsList &&
          pc.map((i, index) => {
            return (
              <TabPanel value={value} index={index}>
                <PricingCondition name={i} />
                <Button
                  onClick={async () => {
                    if (
                      window.confirm(
                        `Apakah Anda Yakin Ingin menghapus seluruh data Pricing Condition ${i} - AccessSequence & Records ?`
                      )
                    ) {
                      await pricingConditions.doc(i).delete();
                      // enqueueSnackbar("Pricing Condition berhasil dihapus", {
                      //   variant: "success",
                      // });
                    }
                  }}
                  startIcon={<DeleteIcon />}
                >
                  Hapus {i}
                </Button>
              </TabPanel>
            );
          })}
      </div>

      <div className={classes.content}></div>
      <Fab
        color="secondary"
        className={classes.fabKursus}
        onClick={(e) => {
          setInputform({
            procedure: "",
          });
          setOpendialog({
            mode: "Add",
            open: true,
          });
        }}
      >
        <PlaylistAdd />
      </Fab>
      <Dialog
        open={opendialog.open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="ppname"
            value={inputForm.condition}
            onChange={(e) =>
              setInputform({ ...inputForm, condition: e.target.value })
            }
            label="Pricing Condition Name"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>

          <Button onClick={handleDialogAddPc} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PricingConditionsList;
