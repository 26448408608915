import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { functions, firestore } from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import { useDocument } from "react-firebase-hooks/firestore";
import { useData } from "../../../components/DataProvider";
import Autocomplete from "@material-ui/lab/Autocomplete";

function AddMitra({ open, handleClose }) {
  const { levelSetting } = useData();
  const [form, setForm] = useState({
    email: "",
    nama: "",
    wa: "",
  });
  const [error, setError] = useState({
    email: "",
    wa: "",
    nama: "",
  });
  // console.log("DATA FROM=>",form);
  // const [level, setlevel] = useState(null)
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  const [hideAktif, setHideAktif] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    // console.log(form)
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  // level_mitra
  // const levelAvailable = dataMitraCurrent?.mitra_level + 1;
  let deptLevel = [];
  // let test = []
  for (var i = 1; i <= levelSetting?.depth; i++) {
    // deptLevel.push(i.toString());
    deptLevel.push({ level: levelSetting?.custom_name?.[i], no: i });
    // setLevelMitra(i)
  }

  console.log(form?.level?.no);
  const validate = async () => {
    const newError = { ...error };
    if (!form.email) {
      newError.email = "Email harus diisi";
    }
    if (!form.nama) {
      newError.password = "Nama harus diisi";
    }
    if (!form.wa) {
      newError.wa = "Whatsapp harus diisi";
    }
    return newError;
  };
  // console.log(form.password?.length)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();
    // console.log(findError)
    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        const dataPost = {
          email: form.email,
          nama: form.nama,
          wa: form.wa,
          api_key: "JheUr74#2|hfaFkkrhA2%93@49",
        };
        const CreateMitra = functions.httpsCallable("user-addUser");
        const response = await CreateMitra(dataPost);
        const res = response?.data;
        handleClose();
        enqueueSnackbar(res.message, { variant: "success" });
        // console.log("=>",dataPost);
      } catch (e) {
        const newError = {};
        switch (e.code) {
          case "auth/email-already-in-use":
            newError.email = "Email sudah terdaftar";
            break;
          case "auth/invalid-email":
            newError.email = "Email tidak valid";
            break;
          case "auth/weak-password":
            newError.password = "Password lemah";
            break;
          case "auth/operation-not-allowed":
            newError.email = "Metode email dan password tidak didukung";
            break;
          default:
            newError.email = "Terjadi kesalahan silahkan cobalagi";
            break;
        }
        setError(newError);
      }
      setSubmitting(false);
    }
  };

  const handleChangeAktif = () => {
    setHideAktif(!hideAktif);
    // console.log("Sekarang=>",hideAktif);
  };

  return (
    <Dialog
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Tambah Penonton</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="email"
              name="email"
              label="Email"
              onChange={handleChange}
              error={error.email ? true : false}
              helperText={error.email}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="nama"
              name="nama"
              label="Nama Lengkap"
              onChange={handleChange}
              error={error.nama ? true : false}
              helperText={error.nama}
              fullWidth
              multiline
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="wa"
              name="wa"
              label="Whatsapp"
              onChange={handleChange}
              error={error.wa ? true : false}
              helperText={error.wa}
              fullWidth
              multiline
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose}>
          Batal
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
          Buat
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddMitra.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddMitra;
