// Nibrasplay Dev
let AnalyticsEmbed = "https://datastudio.google.com/embed/reporting/efd24e34-9672-4513-9130-3aa5e812742e/page/Gg3";

// Nibrasplay Prod
if (process.env.REACT_APP_ENV === "NIBRAS_PROD") {
    AnalyticsEmbed = "https://datastudio.google.com/embed/reporting/d655edf9-ace2-44e9-a14a-77baab33d860/page/Gg3"
}

// Kulaku Prod
else if (process.env.REACT_APP_ENV === "KULAKU_PROD") {
    AnalyticsEmbed = "https://datastudio.google.com/embed/reporting/c2fd919a-6b80-4459-941c-9eb73aee61de/page/Gg3";
}

// Kulaku Dev
else if (process.env.REACT_APP_ENV === "KULAKU_DEV") {
    AnalyticsEmbed = "https://datastudio.google.com/embed/reporting/0f8767f6-303b-4070-851b-82c2ff3ed3a0/page/Gg3";
}

// Reform Prod
else if (process.env.REACT_APP_ENV === "REFORM_PROD") {
    AnalyticsEmbed = "https://datastudio.google.com/embed/reporting/efd24e34-9672-4513-9130-3aa5e812746e/page/Gg3";
}

// Reform Dev
// else if (process.env.REACT_APP_ENV === "REFORM_DEV") {
//     AnalyticsEmbed = "";
// }

export default AnalyticsEmbed;