import React, { useState } from 'react';
import AddIcon from "@material-ui/icons/Add";


import { useCollectionData } from 'react-firebase-hooks/firestore';
import PageLoading from '../../../components/loading/pageLoading';
import Paper from '@material-ui/core/Paper';
// import { unixToDate } from '../../../utils/dateFormatter';
import useStyles from './styles';
// import IconButton from '@material-ui/core/IconButton';
import { firestore } from '../../../components/FirebaseProvider';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddDialog from './addCategories';
// import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

// import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Container from '@material-ui/core/Container';

import { arrayToTree } from "performant-array-to-tree";
import { useData } from '../../../components/DataProvider';


// import { Helmet } from 'react-helmet';

function Categories({ form }) {

    const classes = useStyles();
    const { company_id } = useData();

    const categCol = firestore.collection(`categories`);




    // const [snapshot, loading] = useCollection(categCol);
    // const [produkItems, setProdukItems] = useState([]);


    const [dataKategori, loadingKategori] = useCollectionData(categCol, { idField: 'id' });





    const [dialog, setDialog] = useState({
        mode: 'Tambah',
        open: false,
        data: {},
        uid: {},
        parent: null,
        level: null
    });





    if (loadingKategori) {
        return <PageLoading />
    }
    const treeData = arrayToTree(dataKategori, {
        idField: "id",
        parentId: "parent",
        childrenField: "sub",
        dataField: null
    })
    console.log(treeData);



    // console.log(table);


    return <>
    <Container maxWidth="lg" >
            <Paper className={classes.paper}>
                <div className={classes.pageWrap}>
                    <Typography component="h1" variant="h6" className={classes.pageTitle}>Daftar Kategori</Typography>
                    <div className={classes.listWrap}>
                        <List
                            component="nav"
                            className={classes.nav}
                            aria-labelledby="nested-list-subheader">
                            {treeData.map((level1) => {
                                return (
                                    <React.Fragment key={level1.id}>
                                        <ListItem button className={classes.listTopLevel}>
                                            <ListItemIcon className={classes.listIcon}>
                                                <ChevronRightIcon />
                                            </ListItemIcon>
                                            <ListItemText className={classes.levelText} primary={level1.nama} />
                                            <Button className={classes.addChild} onClick={(e) => {
                                                setDialog({ mode: 'Tambah', open: true, data: {}, parent: level1.id, level: 2 });

                                            }} ><AddIcon /> <span>Tambah Sub Kategori di {level1.nama} </span>
                                                {/* <span>di {level1.nama}</span> */}</Button>
                                            <div className={classes.actionBtn}>
                                                <div className={classes.actionBtnItem}>
                                                    <EditIcon className={classes.tree} onClick={(event) => {
                                                        setDialog(dialog => ({
                                                            ...dialog,
                                                            mode: 'Edit',
                                                            data: level1,
                                                            id: level1.id,
                                                            open: true,
                                                        }))
                                                    }
                                                    } />
                                                    <Typography className={classes.actionBtnLabel}>Edit</Typography>
                                                </div>
                                                <div className={classes.actionBtnItem}>
                                                    <DeleteIcon onClick={async () => {

                                                        if (window.confirm(`Apakah Anda Yakin Ingin menghapus ${level1.nama}?, menghapus ini akan menghapus children dan grandchildren juga`)) {
                                                            await firestore.doc(`categories/${level1.id}`).delete();

                                                        }

                                                    }} />
                                                    <Typography className={classes.actionBtnLabel}>Hapus</Typography>
                                                </div>
                                            </div>
                                        </ListItem>
                                        {level1.sub.length > 0 && (
                                            <List component="div" disablePadding className={classes.listLevel1}>
                                                {level1.sub.map((level2) => {
                                                    return (
                                                        <React.Fragment key={level2.id}>
                                                            <ListItem button className={classes.nested}>
                                                                <ListItemIcon className={classes.listIcon}>
                                                                    <ChevronRightIcon />
                                                                </ListItemIcon>
                                                                <ListItemText className={classes.levelText} primary={level2.nama} />
                                                                <Button className={classes.addChild2} onClick={(e) => {
                                                                    setDialog({ mode: 'Tambah', open: true, data: {}, parent: level2.id, level: 3 });
                                                                }}>
                                                                    <AddIcon /> <span>Tambah Sub Kategori di {level2.nama} </span>
                                                                    {/* <span>di {level2.nama}</span> */}
                                                                </Button>
                                                                <div className={classes.actionBtn}>
                                                                    <div className={classes.actionBtnItem}>
                                                                        <EditIcon className={classes.tree} onClick={(event) => {
                                                                            setDialog(dialog => ({
                                                                                ...dialog,
                                                                                mode: 'Edit',
                                                                                data: level2,
                                                                                id: level2.id,
                                                                                open: true,
                                                                            }))
                                                                        }
                                                                        } />
                                                                        <Typography className={classes.actionBtnLabel}>Edit</Typography>
                                                                    </div>
                                                                    <div className={classes.actionBtnItem}>
                                                                        <DeleteIcon onClick={async () => {

                                                                            if (window.confirm(`Apakah Anda Yakin Ingin menghapus ${level2.nama}?, menghapus ini akan menghapus children juga`)) {
                                                                                await firestore.doc(`categories/${level2.id}`).delete();

                                                                            }

                                                                        }} />
                                                                        <Typography className={classes.actionBtnLabel}>Hapus</Typography>
                                                                    </div>
                                                                </div>

                                                            </ListItem>
                                                            {level2.sub.length > 0 && (
                                                                <List component="div" disablePadding className={classes.listLevel2}>
                                                                    {level2.sub.map((level3) => {
                                                                        return (
                                                                            <React.Fragment key={level3.id}>
                                                                                <ListItem button className={classes.nested2}>
                                                                                    <ListItemIcon className={classes.listIcon}>
                                                                                        <ChevronRightIcon />
                                                                                    </ListItemIcon>
                                                                                    <ListItemText primary={level3.nama} />
                                                                                    <div className={classes.actionBtn2}>
                                                                                        <div className={classes.actionBtnItem}>
                                                                                            <EditIcon className={classes.tree} onClick={(event) => {
                                                                                                setDialog(dialog => ({
                                                                                                    ...dialog,
                                                                                                    mode: 'Edit',
                                                                                                    data: level3,
                                                                                                    id: level3.id,
                                                                                                    open: true,
                                                                                                }))
                                                                                            }
                                                                                            } />
                                                                                            <Typography className={classes.actionBtnLabel}>Edit</Typography>
                                                                                        </div>
                                                                                        <div className={classes.actionBtnItem}>
                                                                                            <DeleteIcon onClick={async () => {

                                                                                                if (window.confirm(`Apakah Anda Yakin Ingin menghapus ${level3.nama}?`)) {
                                                                                                    await firestore.doc(`categories/${level3.id}`).delete();

                                                                                                }

                                                                                            }} />
                                                                                            <Typography className={classes.actionBtnLabel}>Delete</Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                </ListItem>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}

                                                                </List>
                                                            )}

                                                            {/* <ListItem button className={classes.addChild}>
                                                            <ListItemIcon>
                                                                <AddIcon />
                                                            </ListItemIcon>
                                                            <ListItemText style={{borderBottom: 'solid 1px #ddd'}} onClick={(e) => {
                                                                setDialog({ mode: 'Tambah', open: true, data: {}, parent: level2.id, level: 3 });

                                                            }}
                                                                primary={`Tambah Children di ${level2.nama}`}
                                                            />
                                                        </ListItem> */}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </List>
                                        )}
                                        {/* <ListItem button className={classes.addChild2}>
                                        <ListItemIcon >
                                            <AddIcon />
                                        </ListItemIcon>
                                        <ListItemText style={{borderBottom: 'solid 1px #ddd'}} onClick={(e) => {
                                            setDialog({ mode: 'Tambah', open: true, data: {}, parent: level1.id, level: 2 });

                                        }} primary={`Tambah Children di ${level1.nama}`} />
                                    </ListItem> */}
                                    </React.Fragment>
                                );
                            })}
                            <ListItem button className={classes.addMore}>
                                <ListItemIcon className={classes.addMoreIcon}>
                                    <AddIcon />
                                </ListItemIcon>
                                <ListItemText onClick={(e) => {
                                    setDialog({ mode: 'Tambah', open: true, data: {}, parent: null, level: 1 });

                                }} primary="Tambah Kategori Parent / Level 1" />
                            </ListItem>
                        </List>
                    </div>
                    {/* {
                    dataKategori.map((kategoriData) => {
                        return <div key={kategoriData.id}>
                            <TreeView
                                className={classes.root}
                                defaultExpanded={['1', '2']}
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                            >
                                <TreeItem nodeId="1" label={kategoriData.nama ? kategoriData.nama : kategoriData.parent.nama} icon={}  >

                                    <TreeItem nodeId="2" label={kategoriData.level_dua} icon={<EditIcon onClick={(event) => {
                                        setDialogDua(dialogDua => ({
                                            ...dialogDua,
                                            mode: 'Edit',
                                            data: kategoriData,
                                            id: kategoriData.id,
                                            open: true
                                        }))
                                    }
                                    } />} >
                                        <TreeItem nodeId="3" label={kategoriData.level_tiga} icon={<EditIcon onClick={(event) => {
                                            setDialogTiga(dialogTiga => ({
                                                ...dialogTiga,
                                                mode: 'Edit',
                                                data: kategoriData,
                                                id: kategoriData.id,
                                                open: true
                                            }))
                                        }
                                        } />} />
                                    </TreeItem>


                                </TreeItem>
                            </TreeView> */}
                    {/* <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        > */}
                    {/* </div>
                    })
                } */}
                    {/* <MaterialTable
                    icons={tableIcons}
                    title="Daftar Kategori "
                    columns={table.columns}
                    data={table.data}

                    editable={{
                        onRowDelete: async (oldData) => {
                            await firestore.doc(`categories/${oldData.id}`).delete();
                        }
                    }}

                    actions={[
                        {
                            icon: () => <IconButton >
                                <EditIcon />
                            </IconButton>,
                            tooltip: 'Edit Kategori',

                            onClick: (event, { tableData, ...oldData }) => {

                                setDialog(dialog => ({
                                    ...dialog,
                                    mode: 'Edit',
                                    data: oldData,
                                    id: oldData.id,
                                    open: true
                                }))
                            }

                        }

                    ]}

                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Tidak Ada Data',
                            addTooltip: 'Tambah',
                            editTooltip: 'Edit',
                            deleteTooltip: 'Delete',
                            editRow: {
                                deleteText: 'Anda Yakin Akan Menghapus Data Ini ?',
                                cancelTooltip: 'Cancel',
                                saveTooltip: 'Ya'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Search',
                            searchPlaceholder: 'Search Data'
                        },
                        header: {
                            actions: 'Actions'
                        },
                        pagination: {
                            labelRowsSelect: 'Line',
                            labelDisplayedRows: ' {from}-{to} From {count} Line',
                            firstTooltip: 'First Page',
                            previousTooltip: 'Prev Page',
                            nextTooltip: 'Next Page',
                            lastTooltip: 'Last Page'
                        }
                    }}

                /> */}
                </div>
            </Paper>
        </Container>
        <Grid className={classes.fabKursus}>

            <AddDialog
                kategori={dataKategori}
                dialog={dialog}
                handleClose={() => {
                    setDialog({ mode: 'Tambah', open: false, data: {}, level: null, parent: null });
                }}
            />


        </Grid>

    </>
}

export default Categories;
