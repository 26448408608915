import React, { useState } from "react";
import ExcelJS from "exceljs";
import UploadIcon from '@material-ui/icons/CloudUpload';
import { Fab, LinearProgress, Typography } from "@material-ui/core";
import { firestore, } from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import useStyles from "./styles";

const mapColumnToField = [
    '',
    "kode_jne",
    'coverage_cod',


]




function ExcelCodJNE() {

    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar();


    const [isSubmitting, setSubmitting] = useState(false);
    //const [dirHandle, setDirHandle] = useState();
    // console.log(isSubmitting)
    const pickerOpts = {
        types: [
            {
                description: 'Text Files',
                accept: {
                    'text/plain': ['.xlsx']
                }
            },
        ],
        excludeAcceptAllOption: true,
        multiple: false
    };
    const handleClick = async () => {

        try {
            // open file picker
            const [file] = await window.showOpenFilePicker(pickerOpts);

            // get file contents
            const fileData = await file.getFile();
            console.log(fileData)
            setSubmitting(true);


            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(fileData);

            //console.log(workbook)

            const worksheet = workbook.getWorksheet('Sheet1');

            //console.log(worksheet)

            const totalRows = worksheet.rowCount;
            const totalColumn = worksheet.columnCount;
            let rawData = [];
            for (let i = 5; i <= totalRows; i++) {
                const row = worksheet.getRow(i);

                //console.log("row-" + i, row);
                //console.log("row-" + i + "-column-total", row.columnCount, worksheet.columnCount);
                let rowData = {};
                for (let j = 1; j <= totalColumn; j++) {

                    const cell = row.getCell(j);
                    //console.log("cell-" + j, cell.value);
                    if (cell.value)
                        rowData[mapColumnToField[j]] = cell?.value;
                }
                if (Object.keys(rowData).length > 0) {
                    rawData.push(rowData);
                }
            }

            console.log(rawData)

            const mapData = await Promise.all(rawData.map(async (data) => {

                return {
                    coverage_cod: data.coverage_cod,
                    kode_jne: data.kode_jne,
                }

            }))
            for (let data of mapData) {

                const { thumbnail, ...restData } = data;
                // console.log(data?.kode_sicepat);
                if (data.kode_jne && data.coverage_cod) {
                    await firestore.collection('settings/shipping/jne_coverage_cod').doc(`${data?.kode_jne}`).set(restData);
                }




            }


            enqueueSnackbar('Coverage COD JNE berhasil di update', { variant: "success" });
            setSubmitting(false);


        } catch (e) {
            console.log(e.message)
            // handleClose();
            enqueueSnackbar(`Coverage COD JNE gagal di update,${e.message}`, { variant: "error" });
            setSubmitting(false);
            return <Typography>{e.message}</Typography>
        }

    }


    if (isSubmitting) {
        return <LinearProgress />
    }

    return <>

        <form id="testForm">
            <Fab onClick={handleClick} className={classes.upload} variant='extended' color="secondary">
                <UploadIcon className={classes.uploadIcon} /> <span>Upload File</span>
            </Fab>
        </form>
        {/* <Typography color="error">Upload file hanya bisa di lakukan di Chrome</Typography> */}


    </>
}


export default ExcelCodJNE;


