/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useData } from "../../../../components/DataProvider";

export default function JneAPI({ user }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { company_id } = useData();
    //   const kurirOptions = [
    //     { label: "JNE", value: "jne" },
    //     { label: "JNT", value: "jnt" },
    //     { label: "SiCepat", value: "sicepat" },
    //     { label: "PT.Pos", value: "pos" },
    //     { label: "TIKI", value: "tiki" },
    //     { label: "PCP", value: "pcp" },
    //     { label: "ESL", value: "esl" },
    //     { label: "RPX", value: "rpx" },
    //   ];

    const APIDoc = firestore.doc(
        `settings/shipping/availables/directapi`
    );

    //   const shippingSetting = firestore.doc(
    //     `settings/shipping/`
    //   );

    //   const alamatSetting = firestore.doc(`settings/alamat/`);

    const [setting, settingLoading] = useDocument(APIDoc);

    const [key, setKey] = useState("");
    const [url, setUrl] = useState('');
    const [username, setUsername] = useState('')

    const handleClickSave = async () => {
        await APIDoc.set(
            {
                jne: {
                    key: key,
                    url: url,
                    username: username,
                }
            },
            { merge: true }
        );



        enqueueSnackbar("Setting Berhasil Diperbarui", { variant: "success" });
    };
    //   const handleClickTest = () => {
    //     const result = sendQontak();
    //     console.log(result);
    //   };

    //   const sendQontak = async () => {
    //     await axios
    //       .post(
    //         `https://console.zenziva.net/wareguler/api/sendWA/`,
    //         {
    //           // userkey: userkey,
    //           // passkey: apikey,
    //           to: "+628121075203",
    //           message: `Test mengirimkan pesan via Zenziva`,
    //         },
    //         {
    //           headers: {
    //             "Content-Type": "application/json",
    //           },
    //         }
    //       )
    //       .then(
    //         (response) => {
    //           return response;
    //         },
    //         (error) => {
    //           console.log(error);
    //           return error;
    //         }
    //       );
    //   };
    useEffect(() => {
        if (setting) {
            setKey(setting.data()?.jne?.key);
            setUrl(setting.data()?.jne?.url);
            setUsername(setting.data()?.jne?.username)
        }
    }, [setting]);

    //   const getSelectedKurir = () => {
    //     let kurirList = [];
    //     url.map((kurir) => {
    //       kurirList.push({
    //         label: kurirOptions.filter((k) => k.value === kurir)[0].label,
    //         value: kurir,
    //       });
    //     });
    //     return kurirList;
    //   };

    //filterSelectedOptions not working as excepected, so we remove options dynamically
    //   const getUnurl = () => {
    //     let kurirList = [];
    //     kurirOptions.map((kurir) => {
    //       if (
    //         url.find((i) => {
    //           return kurir.value === i;
    //         })
    //       ) {
    //         //do nothing
    //       } else {
    //         //push to array
    //         kurirList.push(kurir);
    //       }
    //     });
    //     return kurirList;
    //   };

    return (
        <>
            {settingLoading && <p>Loading...</p>}
            {setting && (
                <div>
                    <Typography className={classes.btm}>
                        Koneksi dan Pengaturan API untuk{" "}
                        <a
                            target="_blank"
                            href="https://jne.com/"
                            rel="noreferrer"
                            className={classes.link}
                        >
                            JNE
                        </a>
                    </Typography>
                    <form className={classes.formWrap} noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="key"
                                    value={key}
                                    onChange={(e) => setKey(e.target.value)}
                                    label="Key"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="url"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                    label="URL"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    label="Username"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={getUnurl()}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Kurir" />
                  )}
                  value={getSelectedKurir()}
                  onChange={(event, value, reason) => {
                    let newOptions = [];
                    value.map((opsi) => {
                      newOptions.push(opsi.value);
                      setUrl(newOptions);
                    });
                  }}
                />
              </Grid> */}
                            <Grid item xs={6}>
                                <Button
                                    disabled={key ? false : true}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleClickSave()}
                                    fullWidth
                                >
                                    Save
                                </Button>
                            </Grid>
                            {/* <Grid item xs={6}>
                <Button
                  disabled={true} //{userkey && apikey ? false : true}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleClickTest()}
                  fullWidth
                >
                  Test Sending
                </Button>
              </Grid> */}
                        </Grid>
                    </form>
                </div>
            )}
        </>
    );
}
