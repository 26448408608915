import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Route, Redirect } from 'react-router-dom';
import DataProvider from '../DataProvider';
import { firestore, useFirebase } from '../FirebaseProvider';
import AppLoading from '../loading/appLoading';

function PrivateRoute({ component: Component, ...restProps }) {
    const { user } = useFirebase();
    const nibrasIdCol = firestore.collection(`superadmin`)
    const [data, loadData] = useCollectionData(nibrasIdCol, { idField: 'id' })
    let findNibrasId = data?.find(nibras => {
        return nibras?.superadmin_id === user?.uid
    })
    console.log('find', findNibrasId)
    if (loadData) {
        return <AppLoading />
    }

    return <Route
        {...restProps}

        render={props => {

            return user && findNibrasId ?
                <DataProvider>
                    <Component  {...props} />
                </DataProvider>
                :
                user ?
                    <Redirect to={{
                        pathname: "/validate",
                        state: {
                            from: props.location
                        }
                    }} />
                    :
                    <Redirect to={{
                        pathname: "/login",
                        state: {
                            from: props.location
                        }
                    }} />

        }}
    />
}

export default PrivateRoute;