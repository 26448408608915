import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paperWrap: {
        marginTop: 35,
        marginBottom: 25,
    },
    pageWrap: {
        padding: '0 30px',
        paddingBottom: theme.spacing(6),
        // boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        borderRadius: 8,
    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        '@media (max-width: 1280px)': {
            padding: '16px 30px',
            margin: '12px 0 40px'
        }
    },
    pageTitle: {
        paddingTop: 15,
    },
    sliderWrap: {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    sliderBox: {
        width: '49%',
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        borderRadius: 8,
        padding: 20,
    },
    fab1: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(6)
    },

    hideInputFile: {
        display: 'none'
    },
    uploadFotoKursus: {
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    previewFotoKursus: {
        width: '100%',
        height: 'auto'
    },
    iconRight: {
        marginLeft: theme.spacing(1)
    },
    sliderRow: {
        height: 45,
        paddingBottom: 15,
        marginBottom: 15,
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
    },
    slideLabel: {
        color: theme.palette.textColor.lessTextColor,
        fontSize: 12,
    },
    slideValue: {
        color: theme.palette.textColor.primeTextColor,
    },
    padding: {
        marginTop: 20
    },
    imgWrap: {
        width: '100%',
        height: 160,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000',
        overflow: 'hidden',
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        '& img': {
            width: '100%',
            height: 'auto',
        }
    },
    margin: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    noOrderMsg: {
        width: 200,
        height: 40,
        marginTop: '13%',
        marginBottom: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: theme.palette.textColor.primeTextColor,
        fontSize: 15,
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

}));

export default useStyles;
