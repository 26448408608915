import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    pageWrap: {
        borderRadius: 8,
        paddingBottom: 20,
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        margin: "12px 0 80px",
    },
    paperWrap: {
        marginTop: 12,
        marginBottom: 40,
    },
    pageTitle: {
        paddingTop: 15,
        marginLeft: 20,
    },
    tabWrap: {
        borderBottom: "solid 1px " + theme.palette.textColor.gray2,
    },
    content: {
        padding: theme.spacing(2),
    },
    fab: {
        marginLeft: theme.spacing(2),
    },
    fab1: {
        display: "flex",
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(6),
    },
    tableCon: {
        marginTop: 35,
        marginBottom: 25,
    },
    tableWrap: {
        borderRadius: 8,
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        marginBottom: 20,
    },
}));

export default useStyles;
