import React, { useEffect, useState } from 'react';
import TabPanel from '../../../components/TabPanel';
import {  firestore } from '../../../components/FirebaseProvider';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import Container from '@material-ui/core/Container';
// core
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// lab
import ExcelCod from './uploadCod';
// ico
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default function Cod({ value, uid }) {
    const classes = useStyles();

    // const col = firestore.collection(`settings/shipping/availables/sicepat/coverage_pickup`);

    // const [snapshot, loading] = useCollectionData(col, { idField: 'id' });

    
    
    // useEffect(() => {
    //     if (snapshot && !snapshot.empty) {
    //         setTable(table => {
    //             return {
    //                 ...table,
    //                 data: snapshot?.docs?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(doc => {
    //                     return {
    //                         uid: doc.id,
    //                         ...doc.data()
    //                     }
    //                 })
    //             }
    //         })
    //     } else {
    //         setTable(table => {
    //             return {
    //                 ...table,
    //                 data: []
    //             }
    //         })
    //     }
    // }, [snapshot]);

    //initial state
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);

    //loading initial data
    useEffect(() => {
        const fetchData = async () => {
            await firestore.collection('settings/shipping/sicepat_coverage_cod')
                .orderBy('kode_sicepat', 'asc') //order using firestore timestamp
                .limit(5) //change limit value as your need
                .onSnapshot(function (querySnapshot) {
                    var items = [];
                    querySnapshot.forEach(function (doc) {
                        items.push({ key: doc.id, ...doc.data() });
                    });
                    setList(items);
                })
        };
        fetchData();
    }, []);
    //next button function
    const showNext = ({ item }) => {
        if (list.length === 0) {
            //use this to show hide buttons if there is no records
        } else {
            const fetchNextData = async () => {
                await firestore.collection('settings/shipping/sicepat_coverage_cod')
                    .orderBy('kode_sicepat', 'asc') //order using firestore timestamp
                    .limit(5) //change limit value as your need
                    .startAfter(item.kode_sicepat) //we pass props item's first kode_sicepat timestamp to do start after you can change as per your wish
                    .onSnapshot(function (querySnapshot) {
                        const items = [];
                        querySnapshot.forEach(function (doc) {
                            items.push({ key: doc.id, ...doc.data() });
                        });
                        setList(items);
                        setPage(page + 1) //in case you like to show current page number you can use this
                    })
            };
            fetchNextData();
        }
    };
    //previous button function
    const showPrevious = ({ item }) => {
        const fetchPreviousData = async () => {
            await firestore.collection('settings/shipping/sicepat_coverage_cod')
                .orderBy('kode_sicepat', 'asc')
                .endBefore(item.kode_sicepat) //this is important when we go back
                .limitToLast(5) //this is important when we go back
                .onSnapshot(function (querySnapshot) {
                    const items = [];
                    querySnapshot.forEach(function (doc) {
                        items.push({ key: doc.id, ...doc.data() });
                    });
                    setList(items);
                    setPage(page - 1)
                })
        };
        fetchPreviousData();
    };
    // console.log(snapshot?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))



    // if (loading) {
    //     return <PageLoading />
    // }

    return <TabPanel value={value} index="cod_sicepat">
        {/* <Typography className={classes.pageSubTitle} component="h1" variant="h5">Komisi</Typography> */}
        <div className={classes.root}>



            <Container className={classes.tableCon} maxWidth="lg">
                <div className={classes.tableWrap}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead style={{ backgroundColor: '#fae0be' }}>
                                <TableRow>
                                    <TableCell >Kode Sicepat</TableCell>
                                    <TableCell >Coverage COD</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((row) => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.kode_sicepat}
                                        </TableCell>
                                        <TableCell >{row.coverage_cod}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <div className={classes.pagination}>
                        {
                            //show previous button only when we have items
                            //pass first item to showPrevious function 
                            page === 1 ? '' :
                                <Button onClick={() => showPrevious({ item: list[0] })}><ChevronLeftIcon />Previous</Button>
                        }

                        {
                            //show next button only when we have items
                            //pass last item to showNext function 
                            list.length < 5 ? '' :
                                <Button onClick={() => showNext({ item: list[list.length - 1] })}>Next<ChevronRightIcon /></Button>
                        }
                    </div>

                </div>
                {/* <ExcelRead /> */}
                <ExcelCod />
            </Container>







        </div>

    </TabPanel>
}