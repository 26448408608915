import React, { useState } from "react";
import ExcelJS from "exceljs";
import UploadIcon from "@material-ui/icons/CloudUpload";
import { Fab, LinearProgress, Typography } from "@material-ui/core";
import { FieldValue, firestore } from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import useStyles from "./styles";

const mapColumnToField = [
    "",
    "product",
    "type",
    "amount",
    "amount_type",
    "start_at",
    "end_at",
    "qty_relation",
    "sign_operator",
    "statistical",
];

function ExcelPromoProduct() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);
    //const [dirHandle, setDirHandle] = useState();
    // console.log(isSubmitting)
    const pickerOpts = {
        types: [
            {
                description: "Text Files",
                accept: {
                    "text/plain": [".xlsx"],
                },
            },
        ],
        excludeAcceptAllOption: true,
        multiple: false,
    };
    // console.log(new Date())
    const handleClick = async () => {
        try {
            // open file picker
            const [file] = await window.showOpenFilePicker(pickerOpts);

            // get file contents
            const fileData = await file.getFile();
            // console.log(fileData);
            setSubmitting(true);

            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(fileData);

            //console.log(workbook)

            const worksheet = workbook.getWorksheet("PromoProduk");

            //console.log(worksheet)

            const totalRows = worksheet.rowCount;
            const totalColumn = worksheet.columnCount;
            let rawData = [];
            for (let i = 2; i <= totalRows; i++) {
                const row = worksheet.getRow(i);
                // console.log("row", row);
                //console.log("row-" + i, row);
                //console.log("row-" + i + "-column-total", row.columnCount, worksheet.columnCount);
                let rowData = {};
                for (let j = 1; j <= totalColumn; j++) {
                    const cell = row.getCell(j);
                    //console.log("cell-" + j, cell.value);
                    if (cell.value) rowData[mapColumnToField[j]] = cell?.value;
                }
                if (Object.keys(rowData).length > 0) {
                    rawData.push(rowData);
                }
            }

            // console.log(rawData?.[0])

            const mapData = await Promise.all(
                rawData.map(async (data) => {
                    // console.log(new Date(data.start_at))
                    return {
                        type: data.type || '',
                        product: data.product || '',
                        amount: data.amount || '',
                        amount_type: data.amount_type || '',
                        end_at: new Date(data.end_at) || '',
                        qty_relation: data.qty_relation === 'true' ? true : false,
                        sign_operator: data.sign_operator || '-',
                        start_at: new Date(data.start_at) || '',
                        statistical: data.statistical === 'true' ? true : false,
                    };
                })
            );
            // console.log(mapData)
            for (let data of mapData) {
                const { thumbnail, ...restData } = data;
                // console.log(data?.kode_sicepat);
                if (
                    data.type &&
                    data.product
                ) {
                    await firestore.collection("pricing_conditions/promo/records").doc(`${data.type}|${data.product}`).set(restData);
                }
            }

            enqueueSnackbar("Promo Produk Berhasil Di Update", {
                variant: "success",
            });
            setSubmitting(false);
        } catch (e) {
            console.log(e.message);
            // handleClose();
            enqueueSnackbar(`Promo Produk gagal Di Update,${e.message}`, {
                variant: "error",
            });
            setSubmitting(false);
            return <Typography>{e.message}</Typography>;
        }
    };

    if (isSubmitting) {
        return <LinearProgress />;
    }

    return (
        <>
            <form id="testForm">
                <Fab
                    style={{ marginLeft: 16 }}
                    onClick={handleClick}
                    className={classes.upload}
                    variant="extended"
                    color="secondary"
                >
                    <UploadIcon className={classes.uploadIcon} /> <span>Upload File</span>
                </Fab>
            </form>
            {/* <Typography color="error">Upload file hanya bisa di lakukan di Chrome</Typography> */}
        </>
    );
}

export default ExcelPromoProduct;
