import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paperWrap: {
    marginTop: 35,
    marginBottom: 25,
  },
  pageWrap: {
    // padding: '0 30px',
    paddingBottom: 20,
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    borderRadius: 8,
  },
  tabPanel: {
    display: "flex",
  },
  pageTitle: {
    paddingTop: 15,
    marginLeft: 20,
  },
  tabWrap: {
    borderBottom: "solid 1px " + theme.palette.textColor.gray2,
  },
  content: {
    // padding: theme.spacing(2),
  },
  formWrap: {
    width: "100%",
  },
  alamatRow: {
    width: "100%",
    height: 60,
    marginBottom: 30,
  },
  autocomplete: {
    marginRight: theme.spacing(2),
    paddingRight: 15,
  },
  pickupRow: {
    margin: "20px 0",
    "@media(min-width:320px)": {
      width: 300,
    },
    "@media(min-width:600px)": {
      width: 500,
    },
  },
  btnSave: {
    marginTop: 50,
  },
  pickupTitle: {
    marginTop: 20,
    marginBottom: 10,
    color: theme.palette.textColor.lessTextColor,
    fontWeight: "bold",
  },
  pickupDesc: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
  },
  descLabel: {
    color: theme.palette.textColor.gray8,
    width: 100,
    fontSize: 13,
  },
  descValue: {
    color: theme.palette.textColor.primeTextColor,
    fontSize: 15,
    width: "60%",
    wordBreak: "break-word",
  },
  mapLabel: {
    color: theme.palette.textColor.gray8,
    fontSize: 13,
  },
  mapBox: {
    height: "40vh",
    width: "100%",
  },
  rateid: {
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  grid: {
    marginBottom: theme.spacing(1.5),
    marginRight: theme.spacing(1),
  },
  listWrap: {
    // border: 'solid 1px #ddd',
    borderRadius: 8,
    margin: "20px 0",
  },
  nav: {
    "& div:first-child": {
      borderRadius: "8px 8px 0 0",
    },
  },
  fabKursus: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  addKategori: {
    marginLeft: theme.spacing(2),
  },
  tableStyles: {},
  root: {
    display: "flex",
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  tree: {
    display: "flex",
    marginRight: 7,
  },
  hideInputFile: {
    display: "none",
  },
  uploadIconCateg: {
    textAlign: "center",
    padding: theme.spacing(3),
  },
  iconRight: {
    paddingLeft: 5,
  },
  previewIconCateg: {
    width: "100%",
    height: "auto",
    marginBottom: 20,
  },
  listIcon: {
    minWidth: 30,
  },
  listTopLevel: {
    backgroundColor: theme.palette.primary.lessMain,
    borderBottom: "solid 1px " + theme.palette.textColor.white,
  },
  listLevel1: {
    backgroundColor: theme.palette.primary.lighter,
  },
  listLevel2: {
    backgroundColor: theme.palette.primary.evenLighter,
  },
  nested: {
    paddingLeft: "40px !important",
    backgroundColor: theme.palette.primary.lighter,
    borderBottom: "solid 1px " + theme.palette.textColor.white,
  },
  nested2: {
    paddingLeft: "65px !important",
    borderBottom: "solid 1px " + theme.palette.textColor.white,
  },
  addChild: {
    backgroundColor: "#fff",
    border: "solid 1px " + theme.palette.textColor.gray9,
    textTransform: "capitalize",
    marginLeft: 10,
    color: theme.palette.textColor.gray,
  },
  addChild2: {
    backgroundColor: "#fff",
    border: "solid 1px " + theme.palette.textColor.gray9,
    textTransform: "capitalize",
    marginLeft: 10,
    color: theme.palette.textColor.gray,
  },
  levelText: {
    maxWidth: 200,
    flex: "unset",
  },
  actionBtn: {
    display: "flex",
    width: 180,
    alignItems: "right",
    //position: "absolute",
    top: 15,
    right: 15,
    color: theme.palette.textColor.blue,
  },
  actionBtn2: {
    display: "flex",
    width: 180,
    color: theme.palette.textColor.blue,
  },
  actionBtnItem: {
    display: "flex",
    alignItems: "left",
    margin: "0 10px",
    color: "#4f5e90",
  },
  addMore: {
    backgroundColor: "#ffcdee",
    borderRadius: "0 0 8px 8px",
  },
  addMoreIcon: {
    minWidth: 30,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default useStyles;
