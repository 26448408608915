import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useData } from "../../../../components/DataProvider";

export default function QontakSetting(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // const { company_id } = useData();

  const qontakSetting = firestore.doc(
    `settings/sender/availables/qontak`
  );

  const [setting, settingLoading] = useDocument(qontakSetting);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [granttype, setGranttype] = useState("");
  const [clientid, setClientid] = useState("");
  const [clientsecret, setClientsecret] = useState("");
  const [channelintegrationid, setChannelintegrationid] = useState("");
  const [messagetemplateid, setMessagetemplateid] = useState("");

  const handleClickSave = async () => {
    await qontakSetting.set(
      {
        username: username,
        password: password,
        granttype: granttype,
        client_id: clientid,
        client_secret: clientsecret,
        channel_integration_id: channelintegrationid,
        messagetemplateid: messagetemplateid,
      },
      { merge: true }
    );

    props.ChangeUseDefaultSender("qontak");

    enqueueSnackbar("Setting Berhasil Diperbarui", { variant: "success" });
  };
  const handleClickTest = () => {
    const result = sendQontak();
    console.log(result);
  };

  const sendQontak = async () => {
    await axios
      .post(
        `https://console.zenziva.net/wareguler/api/sendWA/`,
        {
          // userkey: userkey,
          // passkey: apikey,
          to: "+628121075203",
          message: `Test mengirimkan pesan via Zenziva`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(
        (response) => {
          return response;
        },
        (error) => {
          console.log(error);
          return error;
        }
      );
  };
  useEffect(() => {
    if (setting) {
      setUsername(setting.data()?.username);
      setPassword(setting.data()?.password);
      setGranttype(setting.data()?.granttype);
      setClientid(setting.data()?.client_id);
      setClientsecret(setting.data()?.client_secret);
      setChannelintegrationid(setting.data()?.channel_integration_id);
      setMessagetemplateid(setting.data()?.messagetemplateid);
    }
  }, [setting]);

  return (
    <>
      {settingLoading && <p>Loading...</p>}
      {setting && (
        <div>
          <Typography className={classes.btm}>
            Koneksi dan Pengaturan API untuk{" "}
            <a
              target="_blank"
              href="https://www.qontak.com/"
              rel="noreferrer"
              className={classes.link}
            >
              Qontak.id
            </a>
          </Typography>
          <form className={classes.formWrap} noValidate autoComplete="off">
            <Grid container spacing="3">
              <Grid item xs={12}>
                <TextField
                  required
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  label="Username"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label="Password"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="granttype"
                  value={granttype}
                  onChange={(e) => setGranttype(e.target.value)}
                  label="Grant Type"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="clientid"
                  value={clientid}
                  onChange={(e) => setClientid(e.target.value)}
                  label="Client Id"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="clientsecret"
                  value={clientsecret}
                  onChange={(e) => setClientsecret(e.target.value)}
                  label="Client Secret"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="channelintegrationid"
                  value={channelintegrationid}
                  onChange={(e) => setChannelintegrationid(e.target.value)}
                  label="Channel Integration Id"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="messagetemplateid"
                  value={messagetemplateid}
                  onChange={(e) => setMessagetemplateid(e.target.value)}
                  label="Message Template Id"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={
                    username &&
                      password &&
                      granttype &&
                      clientid &&
                      clientsecret &&
                      channelintegrationid &&
                      messagetemplateid
                      ? false
                      : true
                  }
                  variant="contained"
                  color="secondary"
                  onClick={() => handleClickSave()}
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  disabled={true} //{userkey && apikey ? false : true}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleClickTest()}
                >
                  Test Sending
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </>
  );
}
