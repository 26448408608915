import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore, functions } from "../../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import { useData } from "../../../../components/DataProvider";
import { customAlphabet } from "nanoid/non-secure";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import PageLoading from "../../../../components/loading/pageLoading";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default function MootaSetting(props) {
  let base_url;

  const nanoid = customAlphabet(
    "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
    10
  );

  const [baseUrl, setBaseUrl] = useState("");

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { company_id } = useData();
  const mootaSetting = firestore.doc(
    `settings/payment/availables/moota`
  );

  const [setting, settingLoading] = useDocument(mootaSetting);

  const [personalToken, setPersonalToken] = useState("");
  const [secretToken, setSecretToken] = useState("");
  const [randomMin, setRandomMin] = useState(0);
  const [randomMax, setRandomMax] = useState(999);
  const [bankList, setBankList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sign, setSign] = useState("-");

  const [opendialog, setOpendialog] = useState({
    mode: "Tambah",
    open: false,
  });
  const [inputForm, setInputform] = useState({
    index: 0,
    bank: "",
    noRek: "",
    namaRek: "",
  });

  const handleClickSave = async () => {
    await mootaSetting.set(
      {
        personaltoken: personalToken,
        secrettoken: secretToken,
        randomMin,
        randomMax,
        sign,
        bankList,
      },
      { merge: true }
    );

    props.ChangeUseDefaultPayment("moota");
    enqueueSnackbar("Setting Berhasil Diperbarui", { variant: "success" });
  };
  const handleClickTest = () => {
    //const result = sendZenziva();
    //console.log(result);
  };

  const handleDialogClose = () => {
    setOpendialog({ ...opendialog, open: false });
    setInputform({ ...inputForm, key_value: [] });
  };

  const handleDialogEditBank = async (index) => {
    let newArray = [];
    bankList.map((bnk, i) => {
      //apply changed value & other unchanged value to new array
      if (inputForm.index === i) {
        newArray.push({
          bank: inputForm.bank,
          noRek: inputForm.noRek,
          namaRek: inputForm.namaRek,
        });
      } else {
        newArray.push({
          bank: bnk.bank,
          noRek: bnk.noRek,
          namaRek: bnk.namaRek,
        });
      }
    });
    mootaSetting.set(
      {
        bankList: newArray,
      },
      { merge: true }
    );
    setOpendialog({ ...opendialog, open: false });
    enqueueSnackbar("Bank berhasil dirubah", {
      variant: "success",
    });
  };

  const handleDialogDeleteBank = async (index) => {
    let newArray = bankList.filter((i, y) => y !== index);
    // console.log(index);
    // console.log(newArray);
    mootaSetting.set(
      {
        bankList: newArray,
      },
      { merge: true }
    );
    setOpendialog({ ...opendialog, open: false });
    enqueueSnackbar("Daftar bank berhasil dihapus", {
      variant: "success",
    });
  };

  const handleDialogAddBank = async () => {
    let newArray = bankList.slice();
    newArray.push({
      bank: inputForm.bank,
      noRek: inputForm.noRek,
      namaRek: inputForm.namaRek,
    });
    //console.log(newArray);
    mootaSetting.set(
      {
        bankList: newArray,
      },
      { merge: true }
    );
    setOpendialog({ ...opendialog, open: false });
    enqueueSnackbar("Daftar Bank berhasil ditambah", {
      variant: "success",
    });
  };

  const getMootaBank = async () => {
    setIsLoading(true);
    let statusSync = true;
    const response = await axios
      .get(`https://app.moota.co/api/v2/bank`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${personalToken}`,
        },
      })
      .then(
        (response) => {
          return response;
        },
        (error) => {
          console.log(error);
          enqueueSnackbar("Synchronisasi Gagal, Double Cek Token Anda!", {
            variant: "error",
          });
          statusSync = false;
          setIsLoading(false);
          return error;
        }
      );

    const mootaBankList = response?.data?.data;

    let newArray = bankList.slice();
    mootaBankList?.map((bank) => {
      newArray.push({
        bank: bank?.label,
        noRek: bank?.account_number,
        namaRek: bank?.atas_nama,
      });
    });
    //console.log(newArray);
    mootaSetting.set(
      {
        bankList: newArray,
      },
      { merge: true }
    );
    setIsLoading(false);
    if (statusSync) {
      enqueueSnackbar("Synchronisasi Data Bank Berhasil", {
        variant: "success",
      });
    }
  };

  useEffect(() => {
    if (setting) {
      setPersonalToken(
        setting.data()?.personaltoken ? setting.data()?.personaltoken : ""
      );
      setRandomMin(setting.data()?.randomMin ? setting.data()?.randomMin : 0);
      setRandomMax(setting.data()?.randomMax ? setting.data()?.randomMax : 999);
      setBankList(setting.data()?.bankList ? setting.data()?.bankList : []);
      setSign(setting.data()?.sign ? setting.data()?.sign : "-");
      if (setting.data()?.secrettoken) {
        setSecretToken(setting.data()?.secrettoken);
      } else {
        setSecretToken(nanoid());
      }
    }
  }, [setting]);

  useEffect(() => {
    async function getEnv() {
      const env = functions.httpsCallable("auth-getEnv");
      const response = await env();

      if (response?.data === "dev") {
        setBaseUrl(
          "https://asia-southeast2-reform-dev-e4be1.cloudfunctions.net"
        );
      } else {
        setBaseUrl("https://asia-southeast2-reform.cloudfunctions.net");
      }

      return "";
    }

    const env = getEnv();
  }, []);

  return (
    <>
      {settingLoading && <PageLoading />}
      {setting && (
        <div>
            <Typography className={classes.btm}>
              Koneksi dan Pengaturan API Untuk{" "}
              <a className={classes.link} target="_blank" href="https://www.moota.co/" rel="noreferrer">
                Moota
              </a>
            </Typography>
            <form className={classes.formWrap} noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="personaltoken"
                  value={personalToken}
                  onChange={(e) => setPersonalToken(e.target.value)}
                  label="Personal Token"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <p>Moota Webhook :</p>
                <p>{`${baseUrl}/notifEndpoint-moota/?company=${company_id}`}</p>
                <p>Secret Token :</p>
                <p>{secretToken}</p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="randomMin"
                  value={randomMin}
                  onChange={(e) => setRandomMin(e.target.value)}
                  label="Kode Unik Min"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="randomMax"
                  value={randomMax}
                  onChange={(e) => setRandomMax(e.target.value)}
                  label="Kode Unik Max"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="legend">Operasi kode unik:</FormLabel>
                <RadioGroup
                  aria-label="sign"
                  name="sign"
                  value={sign}
                  onChange={(e) => setSign(e.target.value)}
                >
                  <FormControlLabel
                    value="+"
                    control={<Radio />}
                    label="Jumlahkan"
                  />
                  <FormControlLabel
                    value="-"
                    control={<Radio />}
                    label="Kurangi"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <p>Daftar Bank :</p>
                {bankList.map((i, index) => {
                  return (
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src="/static/images/bca.jpg" />
                      </ListItemAvatar>
                      <ListItemText
                        primary={i.bank}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {i.noRek} - {i.namaRek}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <EditIcon
                        onClick={() => {
                          setInputform({
                            index,
                            bank: bankList[index].bank,
                            noRek: bankList[index].noRek,
                            namaRek: bankList[index].namaRek,
                          });
                          setOpendialog({
                            ...opendialog,
                            mode: "Edit",
                            open: true,
                          });
                        }}
                      />
                      <DeleteIcon
                        onClick={() => handleDialogDeleteBank(index)}
                      />
                    </ListItem>
                  );
                })}
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={personalToken ? false : true}
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    setOpendialog({
                      ...opendialog,
                      mode: "Add",
                      open: true,
                    })
                  }
                  fullWidth
                >
                  Tambah Bank
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={personalToken && !isLoading ? false : true}
                  variant="contained"
                  color="secondary"
                  onClick={() => getMootaBank()}
                  fullWidth
                >
                  {isLoading ? "Loading..." : "Get Bank Moota"}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={personalToken ? false : true}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleClickSave()}
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={true} //{userkey && apikey ? false : true}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleClickTest()}
                  fullWidth
                >
                  Test Sending
                </Button>
              </Grid>
            </Grid>
            </form>
        </div>
      )}
      <Dialog
        open={opendialog.open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {opendialog.mode === "Edit" ? "Edit" : "Add"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Daftar Bank</DialogContentText>

          <TextField
            margin="dense"
            id="bank"
            value={inputForm.bank}
            onChange={(e) =>
              setInputform({ ...inputForm, bank: e.target.value })
            }
            label="Bank"
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            id="noRek"
            value={inputForm.noRek}
            onChange={(e) =>
              setInputform({ ...inputForm, noRek: e.target.value })
            }
            label="Nomor Rekening"
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            id="namaRek"
            value={inputForm.namaRek}
            onChange={(e) =>
              setInputform({ ...inputForm, namaRek: e.target.value })
            }
            label="Atas Nama Rekening"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          {opendialog.mode === "Edit" ? (
            <Button onClick={handleDialogEditBank} color="primary">
              Edit
            </Button>
          ) : (
            <Button onClick={handleDialogAddBank} color="primary">
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
